import { Fragment, useState, useEffect } from "react";
import { useAppDispatch } from "common/hooks";
import { setCurPage } from "common/features/PageSetupSlice";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function PageFaqs({ locate }: { locate?: string }) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const title = `${process.env.REACT_APP_BRAND_NAME}-FAQs`;
    document.title = title;
    dispatch(setCurPage("faq"));
    if (locate) {
      const divFirst = document.getElementById(locate);
      if (divFirst) {
        divFirst.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  //console.log("home loaded")
  return (
    <Fragment>
      <div className="bg-grayer faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 id="faq" className="fs28 text-center">
                FAQs
              </h3>
              <div className="accordion" id="faq">
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqOne">
                        1. What is EzGoodie?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          EzGoodie is an innovative platform that brings
                          together the world of shopping, savings, and rewards.
                          It offers a unique opportunity for members to earn
                          cashback on their purchases and build a referral
                          network to maximize their income potential!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqTwo">
                        2. How does EzGoodie work?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          EzGoodie offers cashback rewards to members when they
                          make purchases from our partner merchants. Members can
                          also refer to others to join the platform and earn
                          commissions on their purchases and referrals'
                          purchases up to five levels deep. It's a win-win
                          scenario where both merchants and members can benefit
                          from the platform.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqThree">
                        3. Is there a fee to join EzGoodie?
                      </span>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          No, there are absolutely no fees to join EzGoodie as a
                          member. Membership is completely free, allowing you to
                          enjoy the benefits of the platform without any
                          financial barriers.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqFour">
                        4. How do I earn cashback?
                      </span>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          To earn cashback, simply become a member of EzGoodie
                          and shop through our partner merchants. When you make
                          purchases using your unique member QR code, you'll
                          receive cashback rewards directly into your account.
                          The more you shop, the more you earn!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqFive">
                        5. How do I earn commissions through referrals?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          By referring others to join EzGoodie, you can earn
                          commissions on their purchases and the purchases made
                          by their referrals, up to five levels deep. When your
                          network shops, you earn. It's a simple way to grow
                          your income while helping others discover the benefits
                          of the platform!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqsix">
                        6. What is the MerchRep program?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          The MerchRep program allows members to invite local
                          businesses and merchants to join the EzGoodie
                          platform. When a merchant joins through your
                          invitation, you'll earn commissions on every purchase
                          made by platform members at that merchant. It's an
                          excellent opportunity to make additional income while
                          supporting local businesses!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqsever">
                        7. Is there a cost to be a MerchRep?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          No, being a MerchRep is free. There are no upfront
                          costs for merchants to join the platform through your
                          invitation. This means merchants can benefit from
                          increased visibility and sales without any financial
                          risk!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq8">
                        8. is there any qualifications required to become a
                        MerchRep?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Becoming a MerchRep (Merchant Representative) is
                          straightforward, and there are no specific
                          qualifications or prerequisites. We welcome
                          enthusiastic individuals who are eager to introduce
                          potential partner merchants to our platform. If you
                          have a network of businesses or contacts in the retail
                          or service industry, you're well-positioned to become
                          a MerchRep.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq9">
                        9. What is MerchRep Role and Responsibilities?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          As a MerchRep, your primary role is to connect with
                          potential partner merchants and invite them to join
                          our platform. Here's an overview of your
                          responsibilities:
                          <br />
                          <strong>Identify Potential Merchants:</strong> Use
                          your network or outreach skills to identify businesses
                          that could benefit from our platform. These can be
                          local restaurants, shops, service providers, or any
                          other type of business. <br />
                          <strong>Extend Invitations:</strong> Reach out to
                          these potential partner merchants and extend an
                          invitation to join our platform. You can provide them
                          with information about the benefits of joining, such
                          as increased exposure and access to a broader customer
                          base. <br />
                          <strong>Assist with Application:</strong> Help
                          potential partner merchants with the application
                          process if needed. You can guide them through the
                          steps and address any questions or concerns they may
                          have. <br />
                          <strong>Support and Information:</strong> Provide
                          information about our platform, its features, and how
                          it works. Be available to address any inquiries and
                          offer support throughout the on boarding process.{" "}
                          <br />
                          <strong>Enjoy the Benefits:</strong> Once a merchant
                          you've invited joins our platform, your job is largely
                          complete. You can now enjoy the benefits of having
                          them as a partner merchant, including any referral
                          rewards or commissions. <br />
                          <strong>No Transaction Management:</strong> You don't
                          need to handle any transaction aspects after the
                          merchant joins. Our platform takes care of the payment
                          and commission processes seamlessly. <br />
                          In summary, becoming a MerchRep is open to anyone
                          interested in helping businesses join our platform.
                          Your role is pivotal in expanding our network of
                          partner merchants, and you'll be rewarded for your
                          efforts once they come on board.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq10">
                        10. Where does the cashback and team bonus come from?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          The cashback and team bonus rewards are generated from
                          the commissions earned through our partnerships with
                          merchants. A portion of each transaction's value is
                          allocated towards these rewards, creating a symbiotic
                          relationship between members, shoppers, and merchants.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq11">
                        11. How do I join as a merchant partner?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          To become a merchant partner, simply click on the "Be
                          Our Partner" icon in the merchant information section.
                          Fill out the required information, and our team will
                          get in touch with you to discuss the collaboration
                          details and benefits for your business.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq12">
                        12. What is the potential income calculator?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          The potential income calculator is a tool that allows
                          you to estimate the income you could potentially earn
                          through the EzGoodie platform. It's based on assumed
                          information and provides an illustrative overview of
                          the earning possibilities within the platform.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq13">
                        13. Can I join as both a member and a merchant partner?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Absolutely! You can join as a member to enjoy cashback
                          rewards and refer others to earn commissions.
                          Additionally, if you own a business, you can become a
                          merchant partner to increase your sales and reward
                          loyal customers.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq14">
                        14. How do I get started?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Getting started is easy! Simply visit our website and
                          complete the registration process to become a member.
                          Once registered, you can start shopping, earning
                          cashback, and referring others to build your income
                          network. If you're a business owner, you can also
                          explore the benefits of becoming a merchant partner.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq15">
                        15. Can I join as both a member and a merchant partner?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Absolutely! You can join as a member to enjoy cashback
                          rewards and refer others to earn commissions.
                          Additionally, if you own a business, you can become a
                          merchant partner to increase your sales and reward
                          loyal customers.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq16">
                        16. Is EzGoodie a multi-level marketing (MLM) program?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          EzGoodie is not a traditional MLM program. While it
                          does employ a people-to-people format, it operates
                          quite differently. Our platform is centered around
                          shopping, savings, and rewards without any overpriced
                          products, inventory requirements, or complicated
                          compensation structures. At EzGoodie, we prioritize
                          simplicity and the enjoyment of platform benefits,
                          setting us apart from traditional MLMs.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq17">
                        17. Is there a limit to the number of referrals I can
                        have?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          There is no specific limit to the number of referrals
                          you can have. You can build a network as large as
                          you'd like, with commissions available up to five
                          levels deep. The more active and engaged your
                          referrals are, the more rewards you can earn.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq18">
                        18. How do I contact customer support?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          If you have any questions or need assistance, you can
                          reach out to our customer support team through the
                          contact information provided on our website. We're
                          here to help and ensure you have a seamless experience
                          with EzGoodie.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq19">
                        19. What if I forget my password?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          If you forget your password, you can use the "Forgot
                          Password" option on the login page. An email will be
                          sent to your registered email address with
                          instructions on how to reset your password securely.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq20">
                        20. Is my personal information safe?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Yes, your privacy and security are of utmost
                          importance to us. We have implemented advanced
                          security measures to protect your personal information
                          and transactions. Your data is encrypted and stored
                          securely, ensuring a safe and secure experience on our
                          platform.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq21">
                        21. Can I use EzGoodie internationally?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Currently, EzGoodie is available in specific regions.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq22">
                        22. How does the commission system work in EzGoodie?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          At EzGoodie, we value your loyalty and believe in
                          rewarding it with a higher commission rate. Your
                          commission rate is directly linked to your spending
                          with our partner merchants. As you spend more, your
                          commission rate naturally increases, providing you
                          with greater rewards.
                          <br />
                          We take pride in offering transparent and fair earning
                          opportunities, without rigid monthly quotas.
                          Importantly, you'll receive level one and level two
                          commissions regardless of your purchases in a specific
                          month.
                          <br />
                          Our commission system employs commission grades, each
                          linked to various spending ranges. For example, when
                          your monthly spending within our partner merchants
                          falls between $200 and $300, you qualify for
                          Commission Grade 3. Your commission earnings rely on
                          both your personal spending and that of your team
                          members and their referrals, spanning up to five
                          levels.
                          <br />
                          By gradually increasing your personal spending and
                          expanding your network, you unlock higher earning
                          potential in this flexible and rewarding system.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq23">
                        23. I noticed that my commission rate changed during the
                        month. Why did this happen?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          The commission rates can change during the month based
                          on your monthly consumption. If your consumption
                          increases, your commission rate may also go up.
                          However, it's important to know that the commission
                          rates for Levels 3 to 5 are reset to zero at the
                          beginning of each calendar month.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq24">
                        24. Why should members choose partnered merchants for
                        their shopping needs over other options?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Our platform's partnered merchants offer a unique
                          advantage that goes beyond regular shopping. By
                          choosing to shop with them, you're not only getting
                          high-quality products and services, but you're also
                          opening the door to a world of savings and rewards. As
                          you shop with our partnered merchants, your spending
                          contributes to your commission rate for team bonuses.
                          The more you spend, the higher your commission rate
                          grows, enabling you to earn even more from your
                          referrals and their purchases. This creates a win-win
                          situation where your shopping benefits your wallet
                          while helping you maximize your earning potential
                          through the team bonus system.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq25">
                        25. How can I identify the platform's partnered
                        merchants?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Identifying our partnered merchants is easy! You can
                          visit our website and navigate to the "Merchant List"
                          page, where you'll find a comprehensive list of our
                          partnered merchants. This list is accessible to all
                          visitors, even before becoming a member. It includes
                          various categories such as groceries, dining, fashion,
                          and more. Each partnered merchant's information,
                          offerings, and exclusive deals are displayed, helping
                          you make informed choices while enjoying special
                          benefits.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq26">
                        26. Can I withdraw my cash-back and team bonus, or do I
                        have to spend it within the platform?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          You have the freedom to choose how to use your earned
                          cash-back and team bonus. While you can certainly use
                          them for discounts and savings on future platform
                          purchases, we also offer the option to withdraw these
                          earnings as real cash. This gives you the flexibility
                          to enjoy your rewards in the way that best suits your
                          needs, whether it's for shopping, saving, or
                          withdrawing. Your rewards, your choice!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq27">
                        27. If my consumption for the current month reaches a
                        certain level of commission qualification, but I later
                        return or cancel a transaction after the end of the
                        month, will it affect my commission rate?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          If your consumption remains within the same commission
                          qualification range, it will not affect your
                          commission rate. However, it may be affected if your
                          consumption falls to a lower level of the commission
                          qualification range.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq28">
                        28. If my consumption for the current month reaches a
                        certain level of commission qualification, but I later
                        return or cancel a transaction after the end of the
                        month, will it affect my commission rate?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          If your consumption remains within the same commission
                          qualification range, it will not affect your
                          commission rate. However, it may be affected if your
                          consumption falls to a lower level of the commission
                          qualification range.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq29">
                        29. Will changes in my team members' consumption after
                        the end of the month affect the commission I earned?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Yes, any changes in transactions made by your team
                          members can impact on the commission you have earned.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq30">
                        30. How do I withdraw the funds available in my account?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Withdrawing the funds available in your account is a
                          straightforward process. Once you've accumulated a
                          certain amount of cash-back and team bonus, you can
                          initiate a withdrawal request. Simply navigate to your
                          account settings and locate the "Withdraw Funds"
                          option. Follow the provided instructions to choose
                          your preferred withdrawal method, such as bank
                          transfer or electronic payment. Keep in mind that
                          there might be a minimum withdrawal threshold to
                          ensure efficiency and cost-effectiveness. Our platform
                          is committed to making sure you can easily access your
                          earned rewards, and our support team is available to
                          assist you every step of the way.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq31">
                        31. Does the platform take care of the purchasing
                        transactions with the merchants? Do you handle delivery
                        and other services?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          The platform serves as a facilitator for connecting
                          members with our partnered merchants, offering
                          exclusive deals, discounts, and cash-back rewards.
                          When you make a purchase through our platform, you are
                          redirected to the merchant's website to complete the
                          transaction directly with them. The platform does not
                          handle the actual purchasing transactions, delivery,
                          or other services. These aspects are managed by the
                          respective merchants. Our focus is to provide you with
                          a seamless shopping experience, a wide range of
                          benefits, and the opportunity to earn rewards, while
                          merchants handle the products, services, and customer
                          support.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq32">
                        32. If I have any issues regarding the products or
                        services, should I come to the platform for a solution?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          While we're here to support and guide you, it's
                          important to reach out to the specific merchant
                          directly for any issues related to their products or
                          services. Our platform connects you with a diverse
                          network of merchants, each responsible for their own
                          products, customer support, and services. If you
                          encounter any concerns or questions about a purchase,
                          delivery, or product quality, we recommend reaching
                          out to the respective merchant's customer support.
                          However, if you have general inquiries about using our
                          platform or need assistance with your account, our
                          support team is ready to help. Your satisfaction is
                          our priority, and we're here to ensure your shopping
                          experience is smooth and enjoyable.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq33">
                        33. Do I get cash-back on the tax or shipping portion,
                        or just the amount of the product or service itself?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Your cash-back rewards are typically calculated based
                          on the purchase amount of the product or service
                          itself. Taxes and shipping fees are usually excluded
                          from the cash-back calculation. Keep in mind that
                          specific terms may vary depending on the merchant and
                          the offers available. We recommend reviewing the
                          details of each merchant's cash-back offer to
                          understand how it applies to your purchases. Our goal
                          is to provide you with transparent and accurate
                          information about your rewards so that you can make
                          informed decisions while shopping with our partnered
                          merchants.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq34">
                        34. If the products or services I buy come with a
                        discount, do I get cash-back on the regular price or
                        discount price?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Your cash-back rewards are typically calculated based
                          on the actual purchase amount, which may be the
                          discounted price if the product or service is on sale.
                          This means you can enjoy both the benefits of a
                          discount and the cash-back rewards on the final price
                          you pay. The cash-back percentage is applied to the
                          amount you spend, whether it's the regular price or a
                          discounted price. It's another way we aim to maximize
                          your savings while shopping with our partnered
                          merchants. As always, we recommend reviewing the
                          specific terms and conditions of each cash-back offer
                          for clarity on how rewards are calculated for
                          discounted items.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq35">
                        35. What happens if I pay part of it by coupon and the
                        rest by cash? Is the cashback counted after the coupon
                        price?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          Yes. If you use a coupon to lower the purchase price,
                          your cash-back rewards will be calculated based on the
                          final amount you paid after applying the coupon. This
                          means you still get cash back on the actual amount you
                          spent, even if part of the payment was made using a
                          coupon. We're committed to ensuring you receive
                          rewards on your eligible purchases, helping you save
                          more while enjoying your shopping experience.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq36">
                        36. As a merchant, do I need to buy extra equipment to
                        scan or record the transactions?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          No, as a merchant partnering with EzGoodie, you don't
                          need to purchase any extra equipment or systems to
                          scan or record transactions. Merchants can
                          conveniently use any digital equipment with scanning
                          capabilities, such as a cell phone or tablet connected
                          to the internet. This allows for easy integration with
                          our platform, enabling you to record transactions and
                          provide cashback and rewards to our members. There's
                          no need for any specialized equipment – just use what
                          you already have to enhance the shopping experience
                          for both you and our valued members.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq37">
                        37. My friend didn't receive my email invitation, and
                        they're also not getting the verification code. What
                        should we do?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          We understand that issues with email invitations and
                          verification codes can be frustrating. If your friend
                          didn't receive your email invitation and is also
                          experiencing difficulties receiving the verification
                          code, follow these steps to troubleshoot:
                          <br />
                          <strong>Check Junk or Spam Folder:</strong> First, ask
                          your friend to check their email's junk or spam folder
                          for both the invitation email and the verification
                          code email. Sometimes, legitimate emails can be
                          filtered as spam.
                          <br />
                          <strong>Correct Email Address:</strong> Double-check
                          that you entered your friend's email address correctly
                          when sending the invitation and verify that the email
                          address provided during registration is accurate. Even
                          a small typo can prevent delivery.
                          <br />
                          <strong>Resend the Invitation: </strong>You can try
                          resending the invitation from your member dashboard,
                          ensuring that you have the correct email address. Ask
                          your friend to add your email address to their
                          contacts or safe sender list.
                          <br />
                          <strong>Resend Verification Code:</strong> If the
                          verification code is not received, there should be an
                          option to request a new code during the registration
                          process. Make sure your friend clicks on the
                          appropriate link to resend the code.
                          <br />
                          <strong>Whitelist Our Domain:</strong> If your friend
                          is using email filters or spam protection, they can
                          whitelist our email domain to ensure that future
                          emails and verification codes reach their inbox.
                          <br />
                          <strong>Contact Support: </strong>If the issues
                          persist, please contact our support team for further
                          assistance. We'll investigate the matter and help
                          ensure your friend receives both the invitation and
                          the verification code.
                          <br />
                          Remember that email delivery can sometimes be affected
                          by the recipient's email settings. By following these
                          steps and working together, you can resolve these
                          issues and enjoy the benefits of our platform. We're
                          here to assist you throughout the process.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq38">
                        38. Can I register without a phone number or email?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          No, a valid email and phone number are required for
                          account verification and communication purposes during
                          registration. This ensures the security and
                          functionality of the platform.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq39">
                        39. Can I register multiple accounts with the same phone
                        number or email address?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          No, our system allows each phone number and email
                          address to be used only once for registration. This
                          policy helps ensure the integrity and security of our
                          platform.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq40">
                        40. When I send out an invitation to a friend, how will
                        I know if they have joined or not?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          You will receive an email notification when your
                          friend joins the platform using the invitation you
                          sent. This email will confirm their registration, and
                          you'll know they have successfully joined.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faq41">
                        41. If I send an invitation link to a friend, do I still
                        need to share my sponsorship information with them
                        separately?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          No, the invitation link you sent to your friend
                          already contains your sponsorship information. There's
                          no need to share it separately; they can use the link
                          to join with your sponsorship.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary our-links sticky-bottom nav-how-it-works">
        <ul className="nav align-items-center justify-content-center">
          <li className="nav-item fs20">
            Calculate how much income you could make with EzGoodie!
          </li>
          <li className="nav-item text-uppercase btn btn-secondary m-2 ms-3">
            calculator
          </li>
        </ul>
      </div>
    </Fragment>
  );
}
