import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "common/hooks";
import {
    fetchProducts,
    removeCategories,
    removeAllFilter,
} from "common/features/ShopSlice";
import { callApi } from "adapter/ApiService";


export default function FilterBar() {
    const dispatch = useAppDispatch();
    const { pagination, page, sidebar } = useAppSelector((state) => state.ShopReducer);
    const { categories } = sidebar;
    const [catCodeMapping, setCatCodeMapping] = useState({});
    const onHandleRemoveFilter = (code) => {
        dispatch(removeCategories(code));
        dispatch(fetchProducts(0));
    }

    const onHandleRemoveAllFilter = () => {
        dispatch(removeAllFilter());
        dispatch(fetchProducts(0));
    }

    useEffect(() => {
        const getCategories = async () => {
            const responseCode = await callApi("category", "get/subcat_code", { cat_code: "1" });
            setCatCodeMapping(responseCode.data);
        };
        getCategories();
    }, []);

    return (
        <>
            {categories && categories.length > 0 &&
                <div className="filter-bar">
                    <div className="fs16" onClick={() => onHandleRemoveAllFilter()}>Reset Filter</div>
                    {categories.map(code => {
                        const name = catCodeMapping[code] ?? "";
                        return <div className="fs16" onClick={() => onHandleRemoveFilter(code)} key={`cat-filter-item-${code}`}>{name}</div>
                    })}
                </div>
            }
        </>
    );
}
