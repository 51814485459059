import { useFormContext } from "react-hook-form";
export default function InputFirstName({ classes }: { classes?: string | null }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={`input-group ${classes}`}>
      <span className="input-group-text fs20">
        Group Name <label>*</label>
      </span>
      <input
        type="text"
        autoComplete="given-name"
        className="form-control fs20"
        {...register("group_name", {
          required: "Please enter a valid group name.",
        })}
      />
    </div>
  );
}
