import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  HomepageInitialState,
  RecentlyJoined,
  DealProp,
} from "@/interface/PropTypeCollection";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { ApiResponse, IObjectKeys } from "common/libraries/interface/PropTypeCollection";
import * as ApiService from "adapter/ApiService";

const initialState: HomepageInitialState = {
  recentlyJoined: { data: [], status: "idle" },
  topDeals: { data: [], status: "idle" },
};



export const getRecentlyJoined = createAsyncThunk(
  "homepage/getRecentlyJoined",
  async () => {
    try {
      const postData = { limit: 5 };
      const response: IObjectKeys = ApiService.callApi("merchant", "get/recent_joined", postData);
      return response;
      //return RecentlyJoinedJSON;
    } catch (error) {
      return { state: -1 };
    }
  }
);

export const getTopDeals = createAsyncThunk(
  "homepage/getTopDeals",
  async () => {
    try {
      const postData = { limit: 12 };
      const response: IObjectKeys = ApiService.callApi("merchant", "get/top_deals", postData);
      return response;
      //return TopDealsJSON;
    } catch (error) {
      return { state: -1 };
    }
  }
);


const HomepageSlice = createSlice({
  name: "Homepage",
  initialState,
  reducers: {
    updateRecentlyJoined: (state, action: PayloadAction<Array<RecentlyJoined>>) => {
      state.recentlyJoined.data = action.payload;
      state.recentlyJoined.status = "done";
    },
    updateTopDeals: (state, action: PayloadAction<Array<DealProp>>) => {
      state.topDeals.data = action.payload;
      state.topDeals.status = "done";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecentlyJoined.pending, (state, action) => {
        state.recentlyJoined.status = "pending";
      })
      .addCase(getRecentlyJoined.fulfilled, (state, action) => {
        const resp = action.payload;
        if (resp.state == 1) {
          state.recentlyJoined.data = resp.data;
        } else {
          //set error message
        }
        state.recentlyJoined.status = "done";
      })
      .addCase(getTopDeals.pending, (state, action) => {
        state.topDeals.status = "pending";
      })
      .addCase(getTopDeals.fulfilled, (state, action) => {
        const resp = action.payload;
        if (resp.state == 1) {
          state.topDeals.data = resp.data;
        } else {
          //set error message
        }
        state.topDeals.status = "done";
      });
  },
});

export default HomepageSlice.reducer;
export const { updateRecentlyJoined, updateTopDeals } = HomepageSlice.actions;
