import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "common/hooks";
import { emailSignin, updateStatus } from "common/features/UserLoginSlice";

import {
  adminEmailSignin,
  updateAdminStatus,
} from "common/features/AdminSlice";

import { ShowFormError } from "widgets/ShowFormMsg";

export default function Email({ ownerType }: { ownerType?: string }) {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => {
    let retData: any;
    if (ownerType) {
      switch (ownerType) {
        case "memeber":
        case "merchant":
          retData = state.UserResult;
          break;

        case "admin":
          retData = state.AdminResult;
          break;

        default:
          retData = state.UserResult;
          break;
      }
    } else {
      retData = state.UserResult;
    }
    return retData;
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberme] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailErro, setEmailErro] = useState<boolean>(false);
  const [passwordErro, setPasswordErro] = useState<boolean>(false);
  const [formErro, setFormErro] = useState<boolean>(false);
  const { message, role, status } = userInfo;

  let updateUserStatus: any, userEmailSignin: any;

  switch (ownerType) {
    case "admin":
      updateUserStatus = updateAdminStatus;
      userEmailSignin = adminEmailSignin;
      break;

    default:
      updateUserStatus = updateStatus;
      userEmailSignin = emailSignin;
      break;
  }

  const onClicklogin = async (e) => {
    e.preventDefault();
    if (rememberMe) {
      window.localStorage.setItem(
        key,
        JSON.stringify({ val1: email, val2: password })
      );
    } else {
      window.localStorage.setItem(key, JSON.stringify({ val1: "", val2: "" }));
    }
    if (email == "" || password == "") {
      setFormErro(true);
    } else {
      setFormErro(false);
      if (/^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)) {
        setEmailErro(false);
      } else {
        setEmailErro(true);
        return;
      }
      dispatch(updateUserStatus({ status: "pending", message: "" }));
      dispatch(userEmailSignin({ email, password, role }));
    }
  };

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setFormErro(false);
    setEmailErro(false);
    dispatch(updateUserStatus({ status: "idle", message: "" }));
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setFormErro(false);
    setPasswordErro(false);
    dispatch(updateUserStatus({ status: "idle", message: "" }));
  };

  const key = `ezgoodie-${role}-email-password`;
  const onClickRememberMe = (e) => {
    const checked = e.currentTarget.checked;
    setRememberme(checked);
    if (checked) {
      window.localStorage.setItem(
        key,
        JSON.stringify({ val1: email, val2: password })
      );
    } else {
      window.localStorage.setItem(key, JSON.stringify({ val1: "", val2: "" }));
    }
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem(key);
    if (accessToken) {
      const { val1, val2 } = JSON.parse(accessToken);
      setEmail(val1);
      setPassword(val2);
    }
  }, []);

  return (
    <div id="withemail" className="forms">
      <div className="row justify-content-center">
        <h5 className="text-center mb-4 fs20">Sign in with email</h5>
        <div className="col-lg-6 col-md-6 col-sm-12">
          {formErro && (
            <ShowFormError message={"Please type email and password"} />
          )}
          {emailErro && (
            <ShowFormError message={"Please type correct format email"} />
          )}
          {passwordErro && (
            <ShowFormError message={"Please type correct password"} />
          )}
          {status == "failed" && (
            <ShowFormError title="Something wrong here!" message={[message]} />
          )}
          <div className="input-group">
            <span className="input-group-text fs20">
              Email <label>*</label>
            </span>
            <input
              type="email"
              value={email}
              onChange={(e) => onChangeEmail(e)}
              className="form-control fs20"
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text fs20">
              Password <label>*</label>
            </span>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => onChangePassword(e)}
              className="form-control ig-input-password fs20"
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="ig-password fs20"
            >
              <img
                alt="show password"
                src={
                  showPassword
                    ? process.env.REACT_APP_IMG_HOME_LINK +
                      "homepage/redicons/password-eye.png"
                    : process.env.REACT_APP_IMG_HOME_LINK +
                      "homepage/redicons/password-eye-slash.png"
                }
              />
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <div className="form-check">
              <input
                onChange={(e) => onClickRememberMe(e)}
                className="form-check-input fs20"
                type="checkbox"
                checked={rememberMe}
                id="tips"
              />
              <label className="form-check-labe fs20" htmlFor="tips">
                Remember me
              </label>
            </div>
            <Link to={`/user/forgot-password`} className="forgot fs20">
              Forgot Passwords?
            </Link>
          </div>

          <div className="d-grid mb-3 mt-5">
            <Link
              className="btn btn-primary"
              onClick={(e) => onClicklogin(e)}
              to="#"
            >
              SIGN IN
            </Link>
          </div>
          {(role == 1 || role == 2) && (
            <div className="signin-type">
              <p className="text-center create-account fs20">
                No Account yet?{" "}
                <Link
                  to={`/signup/${role == 1 ? "member" : "merchant"}`}
                  className="text-primary fs20"
                >
                  Create a New Account{" "}
                </Link>{" "}
                for Free
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
