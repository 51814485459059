export default function BenefitsBanner() {
  return (
    <section className="bg-dark-gray landing-page-features">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-3 col-sm-12 text-center mb-4">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "member-functional/bi-cash-coin.png"
              }
            />
            <h5 className="fs20">No registration fee</h5>
          </div>
          <div className="col-md-12 col-lg-3 col-sm-12 text-center mb-4">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "member-functional/mdi-cash-sync.png"
              }
            />
            <h5 className="fs20">No monthly fees/quotas</h5>
          </div>
          <div className="col-md-12 col-lg-3 col-sm-12 text-center mb-4">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "member-functional/no-risk-2.png"
              }
            />
            <h5 className="fs20">No risk</h5>
          </div>
          <div className="col-md-12 col-lg-3 col-sm-12 text-center mb-4">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "member-functional/no-risk-1.png"
              }
            />
            <h5 className="fs20">No hassles</h5>
          </div>
        </div>
      </div>
    </section>
  );
}
