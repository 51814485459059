import { useFormContext } from "react-hook-form";
import { InputProps } from "common/libraries/interface/PropTypeCollection";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
export default function InputId({
  classes,
  title = null,
  id = null,
  toolTipContent = null,
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={`input-group ${classes}`}>
      <span className="input-group-text fs20">
        {title ?? "Id"} <label>*</label>
        {toolTipContent && (
          <Tooltip title={toolTipContent} placement="right-start">
            <HelpOutlineIcon sx={{ color: "red" }} />
          </Tooltip>
        )}
      </span>
      <input
        type="text"
        autoComplete="given-name"
        className="form-control fs20"
        maxLength={50}
        {...register(id ?? "id", {
          required: `Please enter ${title ?? "Id"}`,
          pattern: {
            value: /^[A-Za-z0-9]*$/,
            message: `Please enter a valid ${
              title ?? "Id"
            }, only allow letter and number, no space between allowed`,
          },
        })}
      />
    </div>
  );
}
