import loader from 'common/assets/images/loader.svg';

export default function ShowPageLoader() {
    return (
        <div className="col-lg-12 col-md-12 col-sm-12 position-relative">
            <img
                src={loader}
                className="w-100 mobile"
                alt="banner"
            />
        </div>
    );
}