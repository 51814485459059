import { useRef, useState, useEffect } from "react";
import { useFormContext, useController } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { callApi } from "adapter/ApiService";
import FormattingPhoneInput from "components/inputs/FormattingPhoneInput";
import FormattingIdInput from "components/inputs/FormattingIdInput";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function InputSponsorId() {
  const params = useParams();
  const {
    memberSid,
    referCode,
    adminInviteMerchantCode,
    adminInviteMemberCode,
  } = params;

  const {
    clearErrors,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const sponsorType = getValues("sponsor_type");
  const PHONE_REGEX = /^\+1\d{10}$/;
  const MEMBER_REGEX = /^([0-9]{12})$/;

  const [sponsorCheck, setSponsorCheck] = useState(false);

  const phoneRules = {
    required:
      !sponsorCheck && sponsorType == "member_phone"
        ? "Oops! Looks like you forgot to input the phone information of your sponsor. Please provide the required details to complete the registration. If you don't have one, no worries! Simply click the checkbox to confirm. Happy joining!"
        : false,
  };
  if (!sponsorCheck && sponsorType == "member_phone") {
    phoneRules["pattern"] = {
      value: PHONE_REGEX,
      message: "Please enter a valid sponsor phone information.",
    };
  }

  const memberIdRules = {
    required:
      !sponsorCheck && sponsorType == "member_id"
        ? "Oops! Looks like you forgot to input the Id information of your sponsor. Please provide the required details to complete the registration. If you don't have one, no worries! Simply click the box to confirm. Happy joining!"
        : false,
  };

  if (!sponsorCheck && sponsorType == "member_id") {
    memberIdRules["pattern"] = {
      value: MEMBER_REGEX,
      message: "Please enter a valid sponsor id information.",
    };
  }

  const {
    field: { value, onChange },
  } = useController({
    name: "sponsor_phone",
    rules: phoneRules,
  });

  const sponsorPhoneRef = useRef(null);

  const onChangeSponsorId = (e) => {
    const val = e.target.value;
    setValue("sponsor_id", val.replace(/[^\d]+/g, ""));
  };

  const onChangeSponsorCheck = () => {
    setSponsorCheck(!sponsorCheck);
    clearErrors("sponsor_id");
    clearErrors("sponsor_phone");
  };

  const [referId, setReferId] = useState(null);
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    const getMemberId = async (memberSid) => {
      const response = await callApi("member", "get/member_id_by_sid", {
        sid: memberSid,
      });
      if (response.state == 1) {
        const memberId = response.data["member_id"];
        setReferId(response.data["member_id"]);
        setValue("sponsor_type", "member_id");
        setValue("sponsor_id", memberId);
      }
    };
    if (memberSid && memberSid.length > 0) {
      console.log("member-from-a-friend:slug >>", memberSid);
      getMemberId(memberSid);
    }
  }, [memberSid]);

  useEffect(() => {
    const getMemberIdByRefer = async (referCode) => {
      const response = await callApi("member", "get/refer_info_by_code", {
        refer_code: referCode,
      });
      if (response.state == 1) {
        const memberId = response.data["member_id"];
        setReferId(response.data["member_id"]);
        setValue("sponsor_type", "member_id");
        setValue("sponsor_id", memberId);
      }
    };
    if (referCode && referCode.length > 0) {
      console.log("member-from-a-friend:slug >>", referCode);
      getMemberIdByRefer(referCode);
    }
  }, [referCode]);

  const inlineStyle = referId ? { border: "none" } : {};
  return (
    <div
      className={`input-group ${
        adminInviteMerchantCode ===
          `${process.env.REACT_APP_ADMIN_INVITE_CODE}` ||
        adminInviteMemberCode === `${process.env.REACT_APP_ADMIN_INVITE_CODE}`
          ? "disabled-div"
          : ""
      }`}
      style={inlineStyle}
    >
      <div className="input-group" style={{ marginBottom: "5px" }}>
        <span className="input-group-text fs20">
          Sponsor{" "}
          <span
            style={{ width: "150px" }}
            className={`sponsor-container d-flex align-items-center justify-content-center`}
          >
            <select
              disabled={referId || sponsorCheck ? true : false}
              defaultValue={"member_id"}
              {...register("sponsor_type", {
                required: "Please select sponsor Id type",
              })}
              className="form-select sponsor-selection fs20"
            >
              <option value="member_id">- ID</option>
              <option value="member_phone">- Phone</option>
            </select>
            <HelpOutlineIcon
              onClick={() => setShowHelp(true)}
              sx={{ color: "red" }}
            />
          </span>
        </span>

        <Dialog
          sx={{
            "& .MuiDialog-paper": { width: "400px" },
          }}
          maxWidth="md"
          open={showHelp}
        >
          <IconButton
            id="modal-close"
            aria-label="close"
            onClick={() => setShowHelp(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            You can either enter your sponsor's ID number or Phone number here.
            If you don't have a sponsor (referrer), then just click the checkbox
            below.
          </DialogContent>
        </Dialog>

        {sponsorType === "member_id" ? (
          <input
            disabled={referId || sponsorCheck ? true : false}
            {...register("sponsor_id", {
              ...memberIdRules,
              onChange: (e) => onChangeSponsorId(e),
            })}
            type="text"
            maxLength={12}
            className="form-control ig-input-sponsor"
          />
        ) : (
          <FormattingPhoneInput
            disabled={referId || sponsorCheck ? true : false}
            value={value}
            onChange={onChange}
            ref={sponsorPhoneRef}
          />
        )}
      </div>

      {!!!referId && (
        <div className="p-2">
          <input
            className="form-check-input me-2"
            type="checkbox"
            id="sponsor_checkbox"
            {...register("sponsor_check", {
              required: false,
              onChange: () => onChangeSponsorCheck(),
            })}
          />
          <label className="form-check-label fs16" htmlFor="sponsor_checkbox">
            I have no sponsor information
          </label>
        </div>
      )}
    </div>
  );
}
