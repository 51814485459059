import React, { useState, useEffect, useRef } from "react";
import { auth } from "common/config/firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { phoneSignin, updateStatus } from "common/features/UserLoginSlice";
import { Link } from "react-router-dom";
import { callApi } from "adapter/ApiService";
import { ShowFormError, ShowFormOk } from "widgets/ShowFormMsg";
import FormattingPhoneInput from "components/inputs/FormattingPhoneInput";

export default function Phone() {
  const dispatch = useAppDispatch();
  const [number, setNumber] = useState("");
  const [validPhone, setValidPhone] = useState<boolean | null>(null);
  const [OTP, setOTP] = useState<string>("");
  const [otpError, setOtpError] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const userInfo = useAppSelector((state) => state.UserResult);
  const { message, role, status } = userInfo;

  const generateRecaptcha = () => {
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response, error) => {
          //console.log({ response, error });
          //signInWithPhoneNumber()
        },
      },
      auth
    );
  };

  useEffect(() => {
    generateRecaptcha();
  }, []);

  const formatNumber = (number: string) => {
    return number;
  };

  const sendOTP = async () => {
    const PHONE_REGEX = /^\+1\d{10}$/;
    if (PHONE_REGEX.test(formatNumber(number))) {
      setValidPhone(true);
    } else {
      setValidPhone(false);
    }

    if (validPhone) {
      let appVerifier = (window as any).recaptchaVerifier;
      //console.log(appVerifier);
      const inputPhone = formatNumber(number);
      const response = await callApi("user", "get/phone_exist", {
        phone: inputPhone,
      });
      if (!response || response.state !== 1) {
        dispatch(
          updateStatus({
            status: "failed",
            message: `There is no phone number ${inputPhone} in our system, please check and try again`,
          })
        );
        return;
      }
      if (inputPhone && appVerifier) {
        dispatch(updateStatus({ status: "pending", message: `` }));
        signInWithPhoneNumber(auth, inputPhone, appVerifier)
          .then((confirmationResult) => {
            dispatch(updateStatus({ status: "idle", message: `` }));
            (window as any).confirmationResult = confirmationResult;
            setShowOTPInput(true);
            setOtpError(false);
            //console.log(confirmationResult);
          })
          .catch((err) => console.error(err));
      }
    }
  };

  const onChangeSetOTPCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOTP(e.target.value);
  };

  const onClicklogin = (e) => {
    e.preventDefault();
    dispatch(updateStatus({ status: "pending", message: `` }));
    try {
      if (OTP && OTP.length === 6) {
        setOtpError(false);
        dispatch(phoneSignin({ OTP, phone: formatNumber(number), role }));
      } else {
        setOtpError(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const key = `ezgoodie-${role}-phone`;
  const onClickRememberMe = (e) => {
    const checked = e.currentTarget.checked;
    if (checked) {
      window.localStorage.setItem(key, number);
    } else {
      window.localStorage.setItem(key, "");
    }
  };

  const onClickShowOtpInput = (e) => {
    e.preventDefault();
    setShowOTPInput(true);
  };

  const onClickResendOtp = (e) => {
    e.preventDefault();
    sendOTP();
  };

  const onBlurPhone = () => {
    const PHONE_REGEX = /^\+1\d{10}$/;
    if (PHONE_REGEX.test(formatNumber(number))) {
      setValidPhone(true);
    } else {
      setValidPhone(false);
    }
  };

  const onChangePhone = (val) => {
    setNumber(val);
    if (!validPhone) {
      setValidPhone(true);
    }
    if (status !== "idle") {
      dispatch(updateStatus({ status: "idle", message: "" }));
    }
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem(key);
    if (accessToken) {
      setNumber(accessToken);
    }
  }, []);

  const phoneRef = useRef<HTMLInputElement | null>(null);

  return (
    <div id="withphone" className="forms">
      <div id="recaptcha-container"></div>
      <div className="row justify-content-center">
        <h5 className="text-center mb-4 fs20">Sign in with phone number</h5>
        <div className="col-lg-7 col-md-7 col-sm-12">
          {otpError && (
            <ShowFormError message="Please type correct verified code" />
          )}
          {validPhone === false && (
            <ShowFormError message="Please type correct phone numnber" />
          )}
          {status == "failed" && <ShowFormError message={message} />}
          {!showOTPInput && (
            <>
              {/*<a className="text-right" href="#" onClick={(e) => onClickShowOtpInput(e)}>I have verified code already</a> */}
              <div className="phonenumber d-flex align-items-end justify-content-center fs20 mb-3">
                <div className="input-group mb-0">
                  <span className="input-group-text fs20">
                    Phone Number <label>*</label>
                  </span>
                  <FormattingPhoneInput
                    value={number}
                    onChange={onChangePhone}
                    ref={phoneRef}
                  />
                  {/*<input type="text" value={number} onChange={(e) => setNumber(e.target.value)} className="form-control" /> */}
                </div>
                <div
                  onClick={() => sendOTP()}
                  className="icon ms-1 mb-2 mb-md-0"
                >
                  <img
                    alt="send OTP"
                    src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/redicons/send-otp.png`}
                  />
                </div>
              </div>
            </>
          )}
          {showOTPInput && (
            <div className="primary-box">
              <p>
                We have sent you a One Time Password
                <br />
                to your phone number: {number}.
              </p>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  Enter Verification Code<label>*</label> :{" "}
                </span>
                <input
                  type="text"
                  value={OTP}
                  maxLength={6}
                  onChange={(e) => {
                    onChangeSetOTPCode(e);
                  }}
                  className="form-control"
                />
              </div>
              <div className="d-grid mb-3">
                <a
                  className="btn btn-primary"
                  href="#"
                  onClick={(e) => onClicklogin(e)}
                >
                  SIGN IN
                </a>
              </div>
              <div className="signin-type">
                <p className="text-center mb-1">
                  Haven't received the code?{" "}
                  <a
                    href="#"
                    onClick={(e) => onClickResendOtp(e)}
                    className="text-primary"
                  >
                    Request a new Code
                  </a>
                </p>
                <p className="text-center create-account">
                  <Link
                    to={`/signup/${role === 1 ? "member" : "merchant"}`}
                    className="text-primary fw700"
                  >
                    Create a New Account{" "}
                  </Link>
                  for Free
                </p>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between">
            <div className="form-check">
              <input
                onChange={(e) => onClickRememberMe(e)}
                className="form-check-input fs20"
                type="checkbox"
                value=""
                id="tips"
              />
              <label className="form-check-label fs20" htmlFor="tips">
                Remember me
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
