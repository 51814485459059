import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import InputEmail from "../../inputs/InputEmail";
import FormErrors from "../FormErrors";
import { useAppDispatch, useAppSelector } from "common/hooks";
import type { RootState } from 'common/store';
import { reSendEmailLink, updateEmail, updateStatus, updateStep } from "common/features/UserSignupSlice";
import { UserSignupState, IObjectKeys } from "common/libraries/interface/PropTypeCollection";
import { callApi } from "adapter/ApiService";


export default function ReEditEmail() {
    const dispatch = useAppDispatch();
    const userSignup = useAppSelector((state: RootState) => state.UserSignup);
    const { sid, status, uid, email } = userSignup;

    const methods = useForm({ defaultValues: { email: email } });
    const {
        handleSubmit,
        setError,
        trigger,
        getValues,
        formState: { errors, touchedFields, isSubmitSuccessful },

    } = methods;

    const curEmail = getValues("email");
    const onSubmit = async () => {
        //when form is autofilled
        trigger();
        let errorCount = 0;

        if (!touchedFields.email) {
            const response = await callApi("user", "get/email_exist", {
                email: curEmail,
                sid
            });
            if (response.state == 1 && !errors['email']) {
                errorCount++;
                setError("email", {
                    message: "Email is already used by another account.",
                });
            }
        }

        if (sid && curEmail && curEmail.length && Object.keys(errors).length === 0 && errorCount == 0) {
            const response = await callApi("user", "update/account_email", { email: curEmail, uid });
            if (response.state === 1) {
                dispatch(reSendEmailLink({ sid, email: curEmail }));
            } else {
                setError("email", {
                    message: "Update Email wrong!",
                });
            }
            //console.log(values);
        }
    };
    const onClickBack = (e) => {
        e.preventDefault();
        dispatch(updateStep(2));
        dispatch(updateStatus("pending"));
    }
    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(onSubmit)}>
                <div className="forms">
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <FormErrors />
                        </div>
                    </div>
                    <h5>Edit Email</h5>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                            <div className="">
                                <InputEmail verify />
                            </div>
                            {curEmail != email && <div className="member-type mt-4">
                                <div className="d-grid mb-4">
                                    <button className={`btn btn-primary`} type="submit">
                                        Save Change
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <p><Link to="#" className="text-primary" onClick={(e) => onClickBack(e)}>Take me back to Email confirmation</Link></p>
                </div>
            </form>
        </FormProvider>

    );
}
