import { useRef, useState } from "react";
import { useFormContext, Controller, useController } from "react-hook-form";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { InputProps } from "common/libraries/interface/PropTypeCollection";
import { NumericFormat } from "react-number-format";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";

export default function InputAmount({
  classes = "",
  onBlur,
  disabled = false,
  max = null,
  required = true,
  infor = null,
  title = null,
  id = null,
  fontSize = "fs20",
  toolTipShow = false,
  wideLabel = false,
}: InputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [showHelp, setShowHelp] = useState(false);

  const componentId = `${id ?? "amount"}`;
  const componentTitle = `${title ?? "Amount"}`;

  const checkIsAllowed = (values) => {
    const { floatValue } = values;
    if (max && floatValue) {
      return floatValue <= max;
    } else {
      return true;
    }
  };

  return (
    <div className={`input-group align-items-center ${classes}`}>
      <span
        className={`input-group-text ${fontSize} ${
          wideLabel ? "wide-label" : ""
        } `}
      >
        <span dangerouslySetInnerHTML={{ __html: componentTitle }}></span>
        <label>*</label>
      </span>

      <Controller
        control={control}
        name={componentId}
        render={({ field: { onChange, name, value } }) => (
          <NumericFormat
            disabled={disabled ? true : false}
            className="form-control"
            decimalScale={2}
            prefix={"$"}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            isAllowed={(values) => checkIsAllowed(values)}
          />
        )}
      />

      {/* toolTipShow && (
        <HelpOutlineIcon
          onClick={() => setShowHelp(true)}
          sx={{ color: "red" }}
        />
      ) */}
      {toolTipShow && (
        <Tooltip title={infor} placement="bottom">
          <HelpOutlineIcon sx={{ color: "red" }} />
        </Tooltip>
      )}
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "400px" } }}
        maxWidth="sm"
        open={showHelp}
      >
        <IconButton
          id="modal-close"
          aria-label="close"
          onClick={() => setShowHelp(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{infor}</DialogContent>
      </Dialog>
    </div>
  );
}
