import React, { useEffect } from "react";

import { getTopDeals } from "common/features/HomeSlice";
import { useAppSelector, useAppDispatch } from "common/hooks";
import { DealProp } from "common/libraries/interface/PropTypeCollection";
import Deal from "widgets/Deal";
import CarouselSlider from "components/common/CarouselSlider";

export default function TopDeals() {
  const { data } = useAppSelector((state) => state.HomepageReducer.topDeals);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data.length == 0) {
      dispatch(getTopDeals());
    }
  }, [data]);

  if (data && data.length > 0) {
    return (
      <div className="bg-white hot-deal">
        <div className="container">
          <div
            className="text-center pt-2 pb-2 mb-3 text-white"
            style={{ backgroundColor: "#d95c29" }}
          >
            <div className="fs32">
              Discover our wide array of partner merchants and saving today
            </div>
          </div>
          <h3 className="">
            HOT DEALS & DISCOUNT COUPONS + EXTRA CASHBACK OFFERS
            <p>
              Today's hot deals handpicked by our Team. Hurry up before they run
              out!
            </p>
          </h3>
          <CarouselSlider
            desktopNum={4}
            tabletNum={2}
            mobileNum={1}
            infinite={true}
          >
            {data?.map((el: DealProp) => (
              <Deal key={el.id} details={el} />
            ))}
          </CarouselSlider>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
