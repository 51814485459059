import { useFormContext } from "react-hook-form";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useState } from "react";
import {
  InputProps,
} from "common/libraries/interface/PropTypeCollection";

export default function InputConfirmPassword({ classes, enableBlur = true, required = true }: InputProps) {
  const {
    register,
    setFocus,
    trigger,
    getValues,
    formState: { errors, dirtyFields },
  } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);
  const password = getValues("password");

  const onBlurConfirmPassword = () => {
    if (enableBlur) {
      if (dirtyFields['password'] && !errors['password']) {
        trigger("confirm_password");
      }
    }
  }

  return (
    <div className={`input-group ${classes}`}>
      <span className="input-group-text fs20">
        Confirm Password <label>*</label>
      </span>
      <input
        type={showPassword ? "text" : "password"}
        className="form-control ig-input-password fs20"
        {...register("confirm_password", {
          required: required ? "Please confirm your password." : false,
          validate: {
            isValid: (v) => v === password || "Password does not match.",
          },
          onBlur: () => onBlurConfirmPassword(),
        })}
      />
      <span onClick={() => {
        setShowPassword((prevState) => !prevState);
        setFocus("confirm_password", { shouldSelect: true });
      }} className="ig-password fs20">
        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </span>
    </div>
  );
}
