import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonLink from "widgets/ButtonLinks";
import { useUserStatus } from "common/hooks/useUserStatus";

export default function MakingMoneyMadeEasy() {
  const navigate = useNavigate();
  const { user } = useUserStatus();

  return (
    <div className="container">
      <div className="row mt-3">
        <img
          src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/Images/home-beach.jpg`}
          alt=""
        />
        <div style={{ marginTop: "-60px" }} className="desktop text-end pe-5">
          <div className="col-sm-12">
            {!user && (
              <ButtonLink
                to={"/signup/member"}
                className="btn btn-primary mt-3"
              >
                JOIN FOR FREE
              </ButtonLink>
            )}
            <ButtonLink
              className="btn btn-secondary mt-3 ms-3"
              to="/page/member"
            >
              Explore More
            </ButtonLink>
          </div>
        </div>
        <div className="mobile">
          <div className="col-sm-12">
            {!user && (
              <ButtonLink
                to={"/signup/member"}
                className="btn btn-primary mt-3"
              >
                JOIN FOR FREE
              </ButtonLink>
            )}
            <ButtonLink
              className="btn btn-secondary mt-3 ms-3"
              to="/page/member"
            >
              Explore More
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
  /*
  return (
    <div
      className="section making-div"
      style={{
        backgroundImage: `url(${process.env.REACT_APP_IMG_HOME_LINK}homepage/Images/bg-beach.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}
    >
      <div className="container">
        <h2 className="">
          MAKING<span className="text-primary fw700"> MONEY </span>MADE
          <span className="text-primary fw700"> EASY</span>
        </h2>
        <div className="row gy-4">
          <div className="col-md-6 col-lg-4 col-sm-12 market">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "homepage/redicons/shop.png"
              }
              alt=""
            />
            <p>
              Shop as usual
              <br />
              Online or Offline
            </p>
          </div>
          <div className="col-md-6 col-lg-4 col-sm-12 market">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "homepage/redicons/nooverpriced.png"
              }
              alt=""
            />
            <p>
              Pay as <br />
              What you usual pay
            </p>
          </div>
          <div className="col-md-6 col-lg-4 col-sm-12 market">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "homepage/redicons/getrebate.png"
              }
              alt=""
            />
            <p>
              Stretch Your Dollar with <br /> Discounts and Rebates
            </p>
          </div>
          <div className="col-md-6 col-lg-4 col-sm-12 market">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "homepage/redicons/refermakemoney.png"
              }
              alt=""
            />
            <p>
              Earn What <br />
              You Unexpected
            </p>
          </div>
          <div className="col-md-6 col-lg-4 col-sm-12 market">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "homepage/redicons/riskfree.png"
              }
              alt=""
            />
            <p>
              Join Free,Thrive
              <br />
              With No Financial Risks
            </p>
          </div>
          <div className="col-md-6 col-lg-4 col-sm-12 market">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "homepage/redicons/networking.png"
              }
              alt=""
            />
            <p>
              Embrace a New <br />
              Era of Networking
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-7 col-md-7 col-sm-12 row d-flex align-items-center justify-content-center">
            <div className="fs24 text-white fw600 col-lg-10 col-md-12 col-sm-12">
              Empower your life with new possibilities
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <ButtonLink to={"/signup/member"} className="btn btn-primary mt-3">
              JOIN FOR FREE
            </ButtonLink>
            <ButtonLink
              className="btn btn-secondary mt-3 ms-3"
              to="/page/member"
            >
              Explore More
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
            */
}
