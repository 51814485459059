import React, { useState, useEffect, useMemo } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormErrors from "components/form/FormErrors";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import TblComissionChart from "./TblComissionChart";

export default function ComissionChart(props) {
  const {
    onClose,
    memberId,
    salesVolume = 0,
    member_grade = 0,
    open,
    ...other
  } = props;
  const [showExplain, setShowExplain] = useState(false);

  const methods = useForm();
  const {
    handleSubmit,
    setError,
    trigger,
    reset,
    getValues,
    setValue,
    formState: { errors, touchedFields, isSubmitSuccessful },
  } = methods;

  const handleEntering = () => {};

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = async () => {
    trigger();
    if (Object.keys(errors).length === 0) {
    }
  };

  let currentRateGrade: number = 0;
  if (!isNaN(salesVolume)) {
    if (salesVolume < 100) {
      currentRateGrade = 0;
    } else if (salesVolume >= 600) {
      currentRateGrade = 4;
    } else if (salesVolume >= 100 && salesVolume < 200) {
      currentRateGrade = 1;
    } else if (salesVolume >= 200 && salesVolume < 400) {
      currentRateGrade = 2;
    } else if (salesVolume >= 400 && salesVolume < 600) {
      currentRateGrade = 3;
    }
  }
  if (currentRateGrade < member_grade) {
    currentRateGrade = member_grade;
  }

  const hideExplain = () => {
    setShowExplain(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row d-flex align-items-center justify-content-center member-dashboard">
          <FormErrors />
          <div className="fs18 row d-flex align-items-center justify-content-center">
            <div className="sub-header mt-4">
              Commission Chart{" "}
              <IconButton
                aria-label="close"
                onClick={handleCancel}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <TblComissionChart currentRateGrade={currentRateGrade} />
            <div className="row d-flex pt-2">
              <div className="col-lg-10 col-md-10 col-sm-10">
                {`My current month's purchase amount is $${parseFloat(
                  salesVolume ?? 0
                ).toFixed(2)}`}
              </div>
              <div
                onClick={() => setShowExplain(true)}
                className="bg-warning col-lg-2 col-md-2 col-sm-2 text-center"
              >
                Explanation
              </div>
              <div className="col-lg-10 col-md-10 col-sm-10 d-flex pt-2">
                <div
                  style={{ width: "50px", margin: "3px" }}
                  className="bg-warning"
                ></div>
                <div className="ms-2">The current bonus rate entitled</div>
              </div>
            </div>
            {showExplain && (
              <div className="mt-4 col-12 position-relative">
                <img
                  onClick={hideExplain}
                  className="position-absolute top-0 end-0"
                  style={{ width: "25px", height: "25px" }}
                  src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/redicons/close-icon-fill.png`}
                />
                <div className="p-2 mt-2" style={{ background: "#D9D9CE" }}>
                  Purchase Amount is the amount you have spent with our partner
                  merchants up to the moment in the current month, the final
                  rates will be varied according to the change of the
                  sales/purchase volume; as a result the earning could be
                  different from the above chart.
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
