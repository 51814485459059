import { Outlet, Link } from "react-router-dom";
import ShopHeader from "components/shop/ShopHeader";
import ShopSideBar from "components/shop/ShopSideBar";
import ShopProducts from "components/shop/ShopProducts";
import ShopSlider from "components/shop/ShopSlider";
import { useAppDispatch } from "common/hooks";
import { setCurPage } from "common/features/PageSetupSlice";
import { useEffect } from "react";

export default function Shopping() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const title = `${process.env.REACT_APP_BRAND_NAME}-shopping`;
    document.title = title;
    dispatch(setCurPage("shop"));
  }, []);

  return (
    <>
      <ShopSlider />
      <section className="merchant-directory">
        <div className="container search-sort-container">
          <ShopHeader />
        </div>
        <div className="container mt-5">
          <div className="row g-4">
            <ShopSideBar />
            <ShopProducts />
          </div>
        </div>
      </section>
    </>
  );
}
