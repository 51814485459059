import { Link } from "react-router-dom";
export default function GetStarted() {
  return (
    <section className="bg-white py-5 process-simple">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-7 col-sm-12">
            {/*<h3 className="title-border-left pt-5 fs28">
              Cross Business Revenue:
            </h3>
  */}
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "merchant-functional/cross-business-title.png"
              }
              className="w-100"
            />
            <div className="description d-flex fs20">
              Leverage your existing customer base to drive unexpected revenue
              growth. As a business with a constant flow of customers, you have
              a unique opportunity to recruit new members into our platform. By
              introducing them to the benefits of joining and shopping with our
              partner businesses, you can generate revenue not only from your
              own sales but also from the purchases made by your recruited
              members.
              <br />
              <br />
              With each customer who enters your establishment, you have the
              chance to expand your reach and develop a thriving team. As these
              recruited members shop with our partner businesses, you earn
              profits even when they make purchases from your competitors. This
              innovative approach allows you to tap into a new and untapped
              revenue stream that is typically unavailable in traditional
              business models.
              <br />
              <br />
              Don't miss out on this unparalleled opportunity to increase your
              profits and broaden your customer base. Join us today and unlock
              the power of cross-business revenue generation, creating a
              mutually beneficial ecosystem where businesses and shoppers thrive
              together.
              <br />
            </div>
          </div>
          <div className="col-md-12 col-lg-5 col-sm-12">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "merchant-functional/usiness-people-video-call-meeting.jpg"
              }
              className="w-100"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
