import { useState, useEffect } from "react";
import { Outlet, Link, useParams } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { callApi } from "adapter/ApiService";
import { ProductProp } from "common/libraries/interface/PropTypeCollection";
import { Show404 } from "widgets/Errors";
import ShowContentLoader from "widgets/ShowContentLoader";
import { AnyCnameRecord } from "dns";
import { useAppSelector, useAppDispatch } from "common/hooks";
import { setCurPage } from "common/features/PageSetupSlice";
import DOMPurify from "dompurify";
import { Buffer } from "buffer";
import ResponsiveAppBar from "components/shop/ResponsiveBar";
import noLogo from "common/assets/images/caseno/merchant_logo.png";
import noCover from "common/assets/images/caseno/merchant_cover.jpeg";
import MerchantLogo from "widgets/MerchantLogo";
import MerchantCover from "widgets/MerchantCover";
import ReviewDisplay from "components/review/ReviewDisplay";

export default function ShoppingDetail() {
  const params = useParams();
  const { slug } = params;
  const [data, setData] = useState<any>(null);
  const [contErro, setContentErro] = useState<boolean | null>(null);
  const [category, setCategory] = useState<number | null>(null);
  const [similarBusinessList, setSimilarBusinessList] = useState<[] | null>(
    null
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    const title = `${process.env.REACT_APP_BRAND_NAME}-shopping-detail`;
    document.title = title;
    dispatch(setCurPage("shop_detail"));
  }, []);

  useEffect(() => {
    const getBusinessDetail = async () => {
      const data = {};
      if (slug) {
        data["store_tag"] = slug; //store_tag can be slug or merchant_id
        const response = await callApi("merchant", "get/business_detail", data);
        if (response && response.state === 1) {
          setData(response.data);
          setContentErro(false);
          const { cat_code } = response.data;
          setCategory(cat_code);
        } else {
          setContentErro(true);
          setData({});
        }
      } else {
        setContentErro(true);
        setData({});
      }
    };
    getBusinessDetail();
  }, [slug]);

  useEffect(() => {
    const getSimilarBusiness = async () => {
      const response = await callApi("merchant", "get/top_similar_business", {
        limit: 10,
        category,
      });
      if (response && response.state === 1) {
        setSimilarBusinessList(response.data);
      }
    };
    if (!category) {
      getSimilarBusiness();
    }
  }, [category]);

  if (contErro === true) {
    return <Show404 />;
  }
  if (!data) {
    return <ShowContentLoader />;
  }

  const {
    merchant_id,
    logo_uri,
    cover_uri,
    special_rebate_rate,
    special_contribute_rate,
    rebate_rate,
    contribute_rate,
    special_deal,
    cageory_desc,
    cat_slug,
    deal_short_desc,
    deal_desc,
    store_desc,
    center,
    lat,
    lng,
    business_name,
    address1,
    address2,
    city,
    prov,
    zip_code,
    addr,
    store_open_hours,
    store_close_hours,
    contact_title,
    contact_first_name,
    contact_last_name,
    contact_phone,
    website_url,
  } = data;

  //const dealHtml = deal_desc ? Buffer.from(deal_desc, 'base64').toString('ascii') : "";

  let editorDealState;
  if (deal_desc) {
    /* const ob = JSON.parse(Buffer.from(deal_desc, 'base64').toString('ascii'));
         const rawState = convertFromRaw(ob);
         editorDealState = EditorState.createWithContent(rawState);
         */
    editorDealState = Buffer.from(deal_desc, "base64").toString("ascii");
  }

  let editorStoreState;
  if (store_desc) {
    /*
        const ob = JSON.parse(Buffer.from(store_desc, 'base64').toString('ascii'));
        const rawState = convertFromRaw(ob);
        editorStoreState = EditorState.createWithContent(rawState);
        */
    editorStoreState = Buffer.from(store_desc, "base64").toString("ascii");
  }

  const zoom = 10;
  const renderMarkers = (map, maps) => {
    if (lat > 0) {
      let marker = new maps.Marker({
        position: { lat: parseFloat(lat), lng: parseFloat(lng) },
        map,
        title: business_name,
      });
      return marker;
    }
    return <></>;
  };
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const onClickLocate = (id) => {
    const divFirst = document.getElementById(id);
    if (divFirst) {
      divFirst.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="merchant-directory-details mt-3">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
              <div className="brand-image">
                <MerchantLogo logoUri={logo_uri} catSlug={cat_slug} />
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
              <div className="brand-name">
                <h3 className="f28">{business_name}</h3>
                <p className="f16">{cageory_desc}</p>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-3 col-sm-12">
              <div className="brand-address">
                <p className="f20">
                  Address:{address1},
                  {address2 && address2.length ? `#${address2}` : ""}
                  <br />
                  {city}, {prov} {zip_code}
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-9 col-sm-12">
              <div className="button">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => onClickLocate("store-contact")}
                >
                  CONTACT STORE
                </button>
              </div>
            </div>
          </div>
        </div>

        <ResponsiveAppBar onClickLocate={onClickLocate} />

        <div className="container">
          <div className="banner mt-1">
            <MerchantCover
              coverUri={cover_uri && cover_uri[0] ? cover_uri[0]["path"] : null}
              catSlug={cat_slug}
            />
          </div>
          <div className="cashback mt-1">
            <h6 className="fs28">Cashback & Commission</h6>
            <p className="fs20">
              {(special_rebate_rate > 0 ? special_rebate_rate : rebate_rate) *
                100}
              % Cashback +{" "}
              {(special_contribute_rate > 0
                ? special_contribute_rate
                : contribute_rate) * 100}
              % Commission{" "}
            </p>
            <p className="purchse fs20">On your purchase</p>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-md-12 col-sm-12">
              <div className="newcard" id="store-detail">
                <h5 className="fs20">Description:</h5>
                {editorStoreState && (
                  <div
                    dangerouslySetInnerHTML={{ __html: editorStoreState }}
                  ></div>
                )}
              </div>
              <div className="map text-center">
                <div style={{ height: "400px" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                    }}
                    defaultCenter={{
                      lat: parseFloat(center.lat),
                      lng: parseFloat(center.lng),
                    }}
                    defaultZoom={zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                      renderMarkers(map, maps)
                    }
                  ></GoogleMapReact>
                </div>
                {/*<button className="btn btn-secondary mt-4">Search for other freshco locations</button> */}
              </div>
            </div>
            <div className="col-12 col-lg-6 col-md-12 col-sm-12">
              <div className="newcard">
                <h6 className="fs20" id="store-contact">
                  CONTACT Information
                </h6>
                <h5 className="mt-5 fs20">Contact Person:</h5>
                <p className="fs20">{`${contact_title ?? ""} ${
                  contact_first_name ?? ""
                } ${contact_last_name ?? ""}`}</p>
                <h5 className="mt-5 fs20">Phone:</h5>
                <p className="fs20">{contact_phone}</p>
                {website_url && (
                  <>
                    <h5 className="mt-5 fs20">Web:</h5>
                    <p className="fs20">
                      <a href={website_url}>{website_url}</a>
                    </p>
                  </>
                )}

                {/*    
                                <h5 className="mt-5">Service options:</h5>
                                <p>In-store shopping · Delivery</p>
                                */}
                <h5 className="mt-5 fs20">Address:</h5>
                <p className="fs20">
                  {address1},{address2 && address2.length ? `#${address2}` : ""}
                  <br />
                  {city}, {prov} {zip_code}
                </p>

                {store_open_hours && (
                  <>
                    <h5 className="fs20">Hours:</h5>
                    <p className="fs20">
                      {store_open_hours.map((item, i) => (
                        <>
                          {`${weekdays[i]} ${store_open_hours[i]}-${store_close_hours[i]} `}
                          <br />
                        </>
                      ))}
                    </p>
                  </>
                )}

                <h5 className="mt-5 fs20" id="store-deal">
                  Deal Descrption:
                </h5>
                {editorDealState && (
                  <div
                    dangerouslySetInnerHTML={{ __html: editorDealState }}
                  ></div>
                )}
              </div>
            </div>
          </div>

          <ReviewDisplay merchantId={merchant_id} />

          {similarBusinessList && similarBusinessList.length > 0 && (
            <div className="container mb-5 relatedcompany">
              <h4 className="fs28">RELATED COMPANIES</h4>
              <div className="row g-2 row-cols-3 row-cols-sm-2 row-cols-md-5">
                {similarBusinessList?.map((el: any) => (
                  <Link key={el.id} to={`/shopping/${el.slug}`}>
                    <div className="col">
                      <img
                        src={
                          el.logo_uri
                            ? `${process.env.REACT_APP_S3_URL}${el.logo_uri}`
                            : noLogo
                        }
                        className="ads-brand"
                        alt={el.logo_alt}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
