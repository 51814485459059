import { Dispatch } from "redux";
import { useState, useEffect, useMemo } from "react";
import { auth } from "common/config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useAppSelector, useAppDispatch } from "common/hooks";
import { callApi } from "adapter/ApiService";
import { IObjectKeys } from "common/libraries/interface/PropTypeCollection";
import { updateUser } from "common/features/UserLoginSlice";
import { updateAdmin } from "common/features/AdminSlice";
import { updateCashier } from "common/features/CashierSlice";

export const useFirebaseUserStatus = () => {
  const dispatch = useAppDispatch();
  const [user, loading, error] = useAuthState(auth);
  const [userRole, setUserRole] = useState<number>(0);
  const getRole = async (user) => {
    const { uid } = user;
    const userRole = window.localStorage.getItem(
      `${process.env.REACT_APP_USER_ROLE_KEY}-${uid}`
    );
    if (userRole && userRole != "undefined") {
      setUserRole(parseInt(`${userRole}`));
    }
    /*
        const response: IObjectKeys = await callApi("user", "get/account_infor_by_authid", { auth_id: uid });
        if (response && response.state === 1) {
            setUserRole(parseInt(response.data.role));
        }
        */

    /*
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult) {
            setUserRole(parseInt(idTokenResult.claims.userRole));
        }
        */
  };
  useEffect(() => {
    if (user) {
      //dispatch(updateUser(user));
      getRole(user);
    }
  }, [user]);

  return { userRole, user, loading, error };
};

export const useAdminStatus = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState<number>(0);
  const adminInfo = useAppSelector((state) => state.AdminResult);

  useEffect(() => {
    const adminLoginStorage = window.localStorage.getItem(
      `${process.env.REACT_APP_ADMIN_LOGIN_KEY}`
    );
    if (
      adminLoginStorage &&
      adminLoginStorage.length > 0 &&
      adminLoginStorage != "undefined"
    ) {
      setUserRole(8);
      const adminParse = JSON.parse(adminLoginStorage);
      if (adminParse) {
        setUser(adminParse);
        //dispatch(updateAdmin(user));
      } else {
        setError(true);
      }
    }
    setLoading(false);
  }, [adminInfo]);

  return { userRole, user, loading, error };
};

export const useCashierStatus = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState<number>(0);
  const cashierInfo = useAppSelector((state) => state.CashierResult);

  useEffect(() => {
    const cashierLoginStorage = window.localStorage.getItem(
      `${process.env.REACT_APP_CASHIER_LOGIN_KEY}`
    );
    if (
      cashierLoginStorage &&
      cashierLoginStorage.length > 0 &&
      cashierLoginStorage != "undefined"
    ) {
      setUserRole(4);
      const cashierParse = JSON.parse(cashierLoginStorage);
      if (cashierParse) {
        setUser(cashierParse);
        // dispatch(updateCashier(user));
      } else {
        setError(true);
      }
    }
    setLoading(false);
  }, [cashierInfo]);

  return { userRole, user, loading, error };
};

export const useUserStatus = () => {
  const {
    userRole: firebaseUserRole,
    user: firebaseUser,
    loading: firebaseLoading,
    error: firebaseError,
  } = useFirebaseUserStatus();
  const {
    userRole: adminUserRole,
    user: adminUser,
    loading: adminLoading,
    error: adminError,
  } = useAdminStatus();
  const {
    userRole: cahsierUserRole,
    user: cashierUser,
    loading: cashierLoading,
    error: cashierError,
  } = useCashierStatus();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [userRole, setUserRole] = useState<number>(0);

  useEffect(() => {
    if (firebaseError || adminError || cashierError) {
      setError(true);
    } else {
      setError(false);
    }

    if (firebaseLoading || adminLoading || cashierLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    let role: number = 0;
    if (adminUser) {
      setUser(adminUser);
      role = 8;
    } else if (cashierUser) {
      setUser(cashierUser);
      role = 4;
    } else if (firebaseUser) {
      setUser(firebaseUser);
      role = firebaseUserRole;
    }
    setUserRole(role);
  }, [
    firebaseUserRole,
    firebaseUser,
    firebaseLoading,
    firebaseError,
    adminUserRole,
    adminUser,
    adminLoading,
    adminError,
    cahsierUserRole,
    cashierUser,
    cashierLoading,
    cashierError,
  ]);
  return { userRole, user, loading, error };
};
