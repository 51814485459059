import { useState, useEffect } from "react";
import { auth } from "common/config/firebase";
import { useAppSelector, useAppDispatch } from "common/hooks";
import {
  updateStatus,
  reSendEmailLink,
  updateUserInfo,
  updateStep,
  syncFirebaseAccountStatus,
} from "common/features/UserSignupSlice";
import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
  updatePassword,
  signOut,
  linkWithCredential,
  sendSignInLinkToEmail,
  EmailAuthProvider,
} from "firebase/auth";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import Helper from "common/libraries/Helper";
import { ShowFormErrorMsg } from "widgets/ShowFormMsg";
import { Show403 } from "widgets/Errors";
import { ModalLoader } from "widgets/ShowLoader";
import { FirebaseError } from "firebase/app";

export default function VerifyPhone() {
  const [user] = useAuthState(auth);
  const userSignup = useAppSelector((state) => state.UserSignup);
  const dispatch = useAppDispatch();
  const { phone, sid, email, status } = userSignup;
  const [OTP, setOTP] = useState<null | string>(null);
  const [otpSent, setOtpSent] = useState(false); //should be false
  const [errorMsg, setErroMsg] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let appVerifier = (window as any).recaptchaVerifier;
    if (!appVerifier) {
      generateRecaptcha();
    } else {
      // console.log(user);
    }
  }, []);

  const generateRecaptcha = () => {
    try {
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response, error) => {
            // setOtpSent(false);
            console.log({ response, error });
          },
        },
        auth
      );
    } catch (error) {
      setErrorCode("captha");
      setErroMsg(
        "Sorry, we are having issue to verify your phone, please refresh your page and try again!"
      );
      console.log("Generate captcha error >>");
      // dispatch(updateStatus('failed'));
    }
  };

  const sendOTP = async () => {
    let appVerifier = (window as any).recaptchaVerifier;
    setLoading(true);
    if (phone && appVerifier) {
      //console.log(appVerifier);
      try {
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          phone,
          appVerifier
        );
        setErroMsg(null);
        setErrorCode("");
        setOtpSent(true);
        (window as any).confirmationResult = confirmationResult;
        setLoading(false);
        // console.log(confirmationResult);
      } catch (err) {
        setLoading(false);
        setOtpSent(false);
        setErrorCode("send-otp");
        setErroMsg("Sorry, send verfied code failed, please try again!");
        console.log("Send OTP wrong >> ", err);
      }
    } else {
      setLoading(false);
      setOtpSent(false);
      setErrorCode("send-otp");
      setErroMsg("Sorry, send verfied code failed, please try again!");
      console.log("Send OTP wrong >> ", "");
    }
  };

  const onChangeOTPInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOTP(e.target.value);
  };

  const verifyOTP = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (OTP && OTP.length === 6) {
      let user;
      /*  Confirm OTP  */
      try {
        let confirmationResult = (window as any).confirmationResult;
        const result = await confirmationResult.confirm(OTP);
        //console.log(result);
        user = result?.user ?? null;
        dispatch(updateUserInfo(user));
      } catch (err) {
        setLoading(false);
        console.error(err);
        setErrorCode("otp-verify");
        setErroMsg(
          "Verify code failed, please check your verify code or request a new verify code to try again!"
        );
        return;
      }

      if (email && user && sid) {
        const { uid } = user;
        /* Link Email */
        try {
          const eCredential = EmailAuthProvider.credentialWithLink(
            email,
            window.location.href
          );
          const usercred = await linkWithCredential(user, eCredential);
        } catch (err: any) {
          setLoading(false);
          const { code } = err;
          if (code !== "auth/provider-already-linked") {
            //Resend email link
            const forwardUrl = `${process.env.REACT_APP_VERIFY_PHONE}?sid=${sid}&email=${email}`;
            const actionCodeSettings = {
              // URL you want to redirect back to. The domain (www.example.com) for this
              // URL must be in the authorized domains list in the Firebase Console.
              url: forwardUrl,
              // This must be true.
              handleCodeInApp: true,
            };
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            setErrorCode("link-expired");
            setErroMsg(
              `Sorry, Your verifying link has expired, we have sent out a new link to ${email}  please check your email and click the new link we just sent out to try and verify again!`
            );
            return;
          }
        }
        /*  Update the final password  */

        try {
          dispatch(syncFirebaseAccountStatus({ accStatus: 1 }));
          const { password, role } = userSignup;
          const encrptPwd = Helper.jsEncode(
            password,
            process.env.REACT_APP_ENCRYPT_KEY
          );
          await updatePassword(user, encrptPwd);
          setLoading(false);
          localStorage.removeItem(`${process.env.REACT_APP_SIGNUP_SID}`); //remove sid localstore, email can't be changed here anymore

          window.localStorage.setItem(
            `${process.env.REACT_APP_USER_ROLE_KEY}-${uid}`,
            `${role}`
          );
          setErroMsg(null);
        } catch (err) {
          setLoading(false);
          setErrorCode("password-sync");
          setErroMsg(
            "Sorry, we have an issue to setup your account, please try again later!"
          );
          console.log("update password wrong");
        }
      }
    }
  };

  const onClickResendOtp = (e) => {
    e.preventDefault();
    sendOTP();
  };

  const onClickEditNumner = (e) => {
    e.preventDefault();
    dispatch(updateStep(3.1));
  };

  return status == "failed" ? (
    <Show403 message={errorMsg} />
  ) : (
    <>
      <div className={`${status == "account_not_found" ? "disabled-div" : ""}`}>
        <div id="recaptcha-container"></div>
        {errorMsg && (
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <ShowFormErrorMsg message={errorMsg} />
            </div>
          </div>
        )}
        {errorCode !== "link-expired" && (
          <>
            <div className="row justify-content-center">
              <div className="col-8">
                <h3 className="">One more step and we are done!</h3>
                <p>
                  <span
                    className="text-primary fs20"
                    style={{ textTransform: "uppercase", fontWeight: "600" }}
                  >
                    verify phone : {phone}
                  </span>{" "}
                  Or{" "}
                  <a href="#" onClick={(e) => onClickEditNumner(e)}>
                    Update phone
                  </a>
                </p>
                {otpSent ? (
                  <p>We have sent a text with a code to {phone}</p>
                ) : (
                  <p className="text-center">
                    <Link
                      className="btn btn-primary"
                      to="#"
                      onClick={(e) => onClickResendOtp(e)}
                    >
                      Send me code
                    </Link>
                  </p>
                )}
              </div>
            </div>
            {otpSent && (
              <div className="row justify-content-center">
                <div className="col-5 text-center">
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      Enter verification code <label>*</label>
                    </span>
                    <input
                      type="text"
                      maxLength={6}
                      value={OTP ?? ""}
                      onChange={(e) => onChangeOTPInput(e)}
                      className="form-control"
                    />
                  </div>
                  {OTP && (
                    <div className="d-grid mb-4">
                      <Link
                        className="btn btn-primary"
                        to="#"
                        onClick={(e) => verifyOTP(e)}
                      >
                        VERIFY
                      </Link>
                    </div>
                  )}
                  <p className="text-center mt-5">
                    Haven't received the code?{" "}
                    <Link to="#" onClick={(e) => onClickResendOtp(e)}>
                      Request a new code
                    </Link>
                  </p>
                  <p className="text-center">
                    Still having issues? <Link to="#">Contact support</Link> for
                    help{" "}
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {loading && <ModalLoader />}
    </>
  );
}
