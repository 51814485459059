import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

export default function CheckTerms({ role = 1 }: { role?: number }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id="terms"
        {...register("terms", {
          required:
            "Please check the box to agree to our terms and conditions to proceed. Thanks!",
        })}
      />
      <label className="form-check-label fs16" htmlFor="terms">
        I acknowledge that I have read and agree to the{" "}
        <Link target="_blank" to={"/term-and-conditions"}>
          General Terms
        </Link>{" "}
        and{" "}
        <Link target="_blank" to={"/privacy-policy"}>
          Conditions and Privacy Policy
        </Link>
        {role == 2 && (
          <>
            {" "}
            and{" "}
            <Link target="_blank" to={"/merchant-agreement"}>
              Merchant Agreement
            </Link>{" "}
          </>
        )}
      </label>
    </div>
  );
}
