import InputConfirmPassword from "../../inputs/InputConfirmPassword";
import InputEmail from "../../inputs/InputEmail";
import InputFullName from "../../inputs/InputFullName";
import InputPassword from "../../inputs/InputPassword";
import InputPhone from "../../inputs/InputPhone";
import InputSponsorId from "../../inputs/InputSponsorId";
import SelectCountry from "../../inputs/SelectCountry";
import SelectTitle from "../../inputs/SelectTitle";
import CheckTerms from "../../inputs/CheckTerms";
import CheckNewsletter from "../../inputs/CheckNewsletter";
import SignUpFormSubmit from "./FormSubmit";
import FormErrors from "../FormErrors";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
export default function MemberIndividual() {
  return (
    <div className="forms">
      <FormErrors />
      <h5 className="fs20">Individual</h5>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 column">
          <SelectTitle />
          <InputFullName />
          <SelectCountry />
          <InputPhone verify sign_role={1} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 column">
          <InputEmail verify sign_role={1} />
          <InputPassword />
          <InputConfirmPassword />
          <InputSponsorId />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-12 column">
          <CheckTerms />
          <CheckNewsletter />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-12 column">
          <SignUpFormSubmit />
        </div>
      </div>
    </div>
  );
}
