import { forwardRef, useEffect, useState } from "react";
import { InputProps } from "common/libraries/interface/PropTypeCollection";

function format(value: string) {
  const raw = (`${value}` || "").replace(/\D/g, "").substring(0, 12);
  const len = raw.length;
  if (len === 0) {
    return raw;
  } else if (len < 5) {
    return `${raw}`;
  } else if (len < 9) {
    return `${raw.substring(0, 4)}-${raw.substring(4, 8)}`;
  } else {
    return `${raw.substring(0, 4)}-${raw.substring(4, 8)}-${raw.substring(
      8,
      12
    )}`;
  }
}

function toValue(formatted: string) {
  const raw = formatted.replace(/\D/g, "").substring(0, 12);
  return `${raw}`;
}

const FormattingIdInput = forwardRef<HTMLInputElement, InputProps>(
  ({ value, onChange, disabled, onBlur, ...other }, ref) => {
    const [isTouched, setIsTouched] = useState(false);
    const initValue = value ? format(value) : "";
    const [formatted, setFormatted] = useState(initValue);

    useEffect(() => {
      if (value) {
        setFormatted(() => format(value));
      }
    }, [value]);

    const handleChange = (e) => {
      setIsTouched(true);
      const f = format(e.target.value);
      setFormatted(f);
      if (onChange) {
        onChange(toValue(f));
      }
    };

    return (
      <input
        {...other}
        type="text"
        inputMode="text"
        autoComplete="member"
        className="form-control fs20"
        maxLength={15}
        ref={ref}
        value={formatted}
        onBlur={onBlur}
        onChange={(e) => handleChange(e)}
      />
    );
  }
);

FormattingIdInput.displayName = "FormattingIdInput";

export default FormattingIdInput;
