import React, { useState, useEffect, ReactNode } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useUserStatus } from "common/hooks";
import { MenuProp } from "common/libraries/interface/PropTypeCollection";
import DropDownMenu from "./DropdownMenu";
import ConfirmModal from "components/common/ConfirmModal";

interface AccountProp {
  navMode?: string;
  page?: string;
  user_role?: number;
  user_avatar?: string;
  user_name?: string;
}

export default function AccountMenu({
  navMode,
  page,
  user_avatar,
  user_name,
}: AccountProp) {
  const { userRole, user } = useUserStatus();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickSignout = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const onCloseUpModal = () => {
    setShowModal(false);
  };

  const onClickYes = () => {
    setShowModal(false);
    navigate("/user/logout");
  };

  const onClickNo = () => {
    setShowModal(false);
  };

  const stringToColor = (string: string) => {
    if (!string) {
      return "#CCCEC7";
    }
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    const splitName = name ? name.split(" ") : [];
    const childrenName = splitName.length
      ? splitName.length == 2
        ? `${splitName[0][0]}${splitName[1][0]}`
        : `${splitName[0][0]}${splitName[0][1]}`
      : "";
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: childrenName,
    };
  };

  const [menuList, setMenuList] = useState<Array<MenuProp> | []>([]);
  const [extMenuList, setExtMenuList] = useState<Array<MenuProp> | []>([]);
  const [accessLink, setAccessLink] = useState<ReactNode>(null);
  const [roleTitle, setRoleTitle] = useState<string>("");
  const [login, setLogin] = useState<boolean>(false);

  useEffect(() => {
    const loginCheck = user ? true : false;
    setLogin(loginCheck);
    let role = userRole ? userRole : 0;
    if (loginCheck) {
      const specialClass =
        page && page == "shopping-detail" ? "text-black" : "text-white";

      setAccessLink(
        <Link
          className={`nav-link ${specialClass}`}
          onClick={onClickSignout}
          to="#"
          role="button"
        >
          Log out{" "}
          <img
            alt="log out"
            src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/redicons/signout.png`}
            width="24"
          />
        </Link>
      );

      switch (role) {
        case 1:
          setMenuList([
            { link: "/member/page/dashboard", title: "Dashboard" },
            { link: "/member/page/profile/setting", title: "My Account" },
          ]);
          setRoleTitle("My Member profile");
          break;

        case 2:
          setMenuList([
            { link: "/merchant/page/dashboard", title: "Dashboard" },
            { link: "/merchant/page/profile/setting", title: "My Account" },
          ]);
          setRoleTitle("My Merchant profile");
          break;

        case 3:
          setRoleTitle("My profile");
          if (navMode) {
            switch (navMode) {
              case "merchant_dashboard":
                setMenuList([
                  { link: "/merchant/page/dashboard", title: "Dashboard" },
                  {
                    link: "/merchant/page/profile/setting",
                    title: "My Account",
                  },
                ]);
                break;

              case "member_dashboard":
                setMenuList([
                  { link: "/member/page/dashboard", title: "Dashboard" },
                  { link: "/member/page/profile/setting", title: "My Account" },
                ]);
                break;
            }
          } else {
            setMenuList([
              { link: "/merchant/page/dashboard", title: "Merchant Dashboard" },
              { link: "/member/page/dashboard", title: "Member Dashboard" },
            ]);
          }
          break;

        case 4:
          setRoleTitle("My Cashier profile");
          setMenuList([
            { link: "/cashier/page/dashboard", title: "Cashier Dashboard" },
          ]);
          break;

        case 8:
          setRoleTitle("My Admin profile");
          setMenuList([
            { link: "/admin/page/dashboard", title: "Admin Dashboard" },
          ]);
          break;
      }
    } else {
      let dropdownList: Array<MenuProp> = [
        { link: "/login/member", title: "Member" },
        { link: "/login/merchant", title: "Merchant" },
         { link: "/login/cashier", title: "Cashier" },
      ];
      if (navMode && navMode === "landing") {
        if (page && page === "member") {
          dropdownList = [{ link: "/login/member", title: "Member" }];
        }
        if (page && page === "merchant") {
          dropdownList = [
            { link: "/login/merchant", title: "Merchant" },
            { link: "/login/cashier", title: "Cashier" },
          ];
        }
      }
      setAccessLink(
        <DropDownMenu
          title="Log in"
          id="login"
          menu_list={dropdownList}
          page={page}
        >
          Log in{" "}
          <img
            alt="log  in"
            src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/redicons/signin.png`}
            width="24"
          />
        </DropDownMenu>
      );
    }
  }, [user, userRole]);

  return (
    <>
      <ConfirmModal
        showModal={showModal}
        title=""
        message="You want to logout your account now ?"
        handleNo={onClickNo}
        handleYes={onClickYes}
        handleClose={onCloseUpModal}
      />
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Typography style={{ color: "black" }} sx={{ minWidth: 100 }}>
          {accessLink}
        </Typography>
        {login && (
          <Tooltip title={`${roleTitle}`}>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              {user_avatar ? (
                <Avatar src={user_avatar} />
              ) : user_name ? (
                <Avatar {...stringAvatar(user_name)} />
              ) : (
                <Avatar />
              )}
            </IconButton>
          </Tooltip>
        )}
      </Box>

      {login && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {menuList.length > 0 &&
            menuList.map((item: MenuProp, i: number) => (
              <MenuItem key={`account-${i}`}>
                <span>
                  <Link to={item["link"]}>{item["title"]}</Link>
                </span>
              </MenuItem>
            ))}
          <Divider />
          <MenuItem>
            <Link onClick={onClickSignout} to="#">
              <span>Log out</span>
            </Link>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
