import { Fragment, useState, useEffect } from "react";
import CarouselSlider from "components/common/CarouselSlider";

export default function PageAboutus() {
  const data = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <Fragment>
      <div style={{ paddingTop: "2px" }} className="container">
        <div className="banner about-us-banner">
          <div className="row g-0">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <img
                src={`${process.env.REACT_APP_IMG_HOME_LINK}about-us/about-us-banner.jpg`}
                className="w-100 desktop"
                alt="home-banner"
              />
              <img
                src={`${process.env.REACT_APP_IMG_HOME_LINK}about-us/about-us-banner.jpg`}
                className="w-100 mobile"
                alt="home-mobile-banner"
              />
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-us-content">
                <h3 className="fs28">About Us</h3>
                <p className="fs20">
                  Welcome to EzGoodie! We're on a mission to create a platform
                  where shoppers and merchants thrive together through a
                  mutually beneficial relationship. Our goal is to transform
                  your shopping experience and drive prosperity for all.
                  <br />
                  With us, you'll enjoy exclusive discounts, cash rewards, and
                  exciting earning potential. But we go beyond just savings; we
                  empower you to create an income stream by inviting others to
                  join and collaborating with merchants. At EzGoodie, we believe
                  in a shopping journey that's rewarding, risk-free, and
                  community-driven. Join us today and embark on a path to
                  savings and success!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white our-mission-statement">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 className="fs28">Our Mission</h3>
              <p className="fs20">
                Our mission is to create a thriving ecosystem, to drive
                prosperity. by fostering a mutually beneficial relationship
                between shoppers and merchants. Shoppers enjoy discounted
                prices, cash rewards and earning potential, while merchants
                witness business expansion and higher customer engagement.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="fs28">Our Business Strategy:</div>
              <p className="fs20">
                At EzGoodie, our strategy is simple yet powerful: Empower,
                Innovate, Collaborate.
              </p>
              <div className="fs28">Empower:</div>
              <p className="fs20">
                {" "}
                We believe in empowering our members by offering them an
                opportunity to take control of their financial future. With cash
                rewards on purchases and the potential to build sustainable
                income through referrals, we give our members the tools they
                need to thrive.
              </p>
              <div className="fs28">Innovate:</div>
              <p className="fs20">
                Innovation is at the heart of what we do. We continuously
                explore new technologies and features to make saving effortless
                and enjoyable for our members. Our platform is designed to stay
                ahead of the curve, providing the best possible experience.
              </p>
              <div className="fs28">Collaborate:</div>
              <p className="fs20">
                We foster a mutually beneficial relationship with a diverse
                network of merchants. By partnering with them, we create a
                thriving ecosystem where our members enjoy exclusive deals while
                merchants witness business expansion and higher customer
                engagement.
              </p>
              <p className="fs20">
                Join us on this transparent, fair, and community-driven journey
                towards prosperity and rewarding shopping experiences for all.
              </p>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="bg-image pt-2">
                <img
                  alt="strategy"
                  src={`${process.env.REACT_APP_IMG_HOME_LINK}about-us/our-business-strategy.jpg`}
                  className="w-100 h-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="bg-image pt-2">
                <img
                  alt="strategy"
                  src={`${process.env.REACT_APP_IMG_HOME_LINK}about-us/networking.jpg`}
                  className="w-100 h-100"
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 pt-5">
              <div className="fs28">
                Problems with typical network marketing & our solution:
              </div>
              <p className="fs20">
                Transforming Network Marketing: We're changing the game. Leave
                behind the burdensome fees and product requirements of typical
                network marketing.
                <br />
                <br /> EzGoodie is free to join, no inventory hassle, and no
                pressuring others. Embrace a platform that prioritizes your
                rewards and makes earning commissions straightforward
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*
      <div className="bg-white our-team">
        <div className="container">
          <h3 className="fs28">Meet our Leadership Team</h3>
          <div className="row">
            <CarouselSlider desktopNum={3} tabletNum={2} mobileNum={1}>
              {data?.map((item, i) => (
                <div key={`card-profile-${i}`} className="card">
                  <div className="team-image text-center">
                    <img
                      alt="profile"
                      src={`${process.env.REACT_APP_IMG_HOME_LINK}/no-image/default-image.png`}
                    />
                  </div>
                  <div className="team-name text-center border-top border-bottom">
                    <h4 className="text-uppercase font-weight-bold">
                      Team Member Name
                    </h4>
                    <h6>Title</h6>
                  </div>
                  <div className="team-description">
                    <p className="fs20">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam ornare tempus aliquet. Pellentesque finibus, est et
                      iaculis suscipit, dolor nulla commodo dui, nec ultricies
                      arcu nisl tristique eros. Morbi eros est, pulvinar eget
                      ornare ac, ultrices
                    </p>
                  </div>
                </div>
              ))}
            </CarouselSlider>
          </div>
        </div>
      </div>
              */}
    </Fragment>
  );
}
