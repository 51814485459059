import { useState, useRef } from "react";
import { useFormContext, useController } from "react-hook-form";
import FormattingPhoneInput from "./FormattingPhoneInput";
import { callApi } from "adapter/ApiService";
import { getValue } from "@mui/system";
import { bool } from "aws-sdk/clients/signer";
import { InputProps } from "common/libraries/interface/PropTypeCollection";

const PHONE_REGEX = /^\+1\d{10}$/;
export default function InputPhone({
  verify = false,
  required = true,
  showPhoneOnly = false,
  sign_role = 1,
  classes = "",
  title = null,
}: InputProps) {
  const {
    trigger,
    getValues,
    setError,
    setFocus,
    formState: { dirtyFields, touchedFields, errors },
  } = useFormContext();

  const checkPhoneExist = async () => {
    const response = await callApi("user", "get/phone_exist", { phone: value });
    if (response.state === 1) {
      setError("phone", {
        type: "custom",
        message: "Phone number is already used by another account",
      });
      if (phoneRef.current) {
        // phoneRef.current.focus();
      }
    }
  };

  const checkPhone = async (phone) => {
    if (phone && phone.length > 0) {
      const check = PHONE_REGEX.test(phone);
      if (!check) {
        return "Please type valide phone number";
      }
      if (verify) {
        const response = await callApi("user", "get/phone_exist", { phone });
        //console.log(response);
        if (response.state === 1) {
          const { role } = response.data;
          if (role == 3 || role == sign_role) {
            return "Oops! Looks like that phone number is already registered by another member. Please try using a different one or contact our support for help. Thank you!";
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    return "Please enter phone number";
  };

  const {
    field: { value, onChange },
  } = useController({
    name: "phone",
    rules: {
      required: required ? "Please enter phone number." : false,
      validate: checkPhone,
      pattern: {
        value: PHONE_REGEX,
        message: "Please enter a valid phone number.",
      },
    },
  });

  const phoneRef = useRef<HTMLInputElement | null>(null);

  const onBlurPhone = async () => {
    if (verify) {
      const phone = getValues("phone");
      if (phone && phone.length > 0) {
        trigger("phone");
        setTimeout(() => {
          if (
            (dirtyFields["phone"] || touchedFields["phone"]) &&
            !errors["phone"]
          ) {
            checkPhoneExist();
          }
        }, 100);
      }
    }
  };

  return (
    <div className={`input-group ${classes}`}>
      <span className="input-group-text fs20">
        {`${title ?? "Phone Number"}`}
        <label>*</label>
      </span>
      <FormattingPhoneInput
        // onBlur={() => onBlurPhone()}
        disabled={showPhoneOnly ? true : false}
        value={value}
        onChange={onChange}
        ref={phoneRef}
      />
    </div>
  );
}
