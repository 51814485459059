import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch, useUserStatus } from "common/hooks";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import StarIcon from "@mui/icons-material/Star";
import styled from "@emotion/styled";
import BorderBox from "widgets/BorderBox";
import { callApi } from "adapter/ApiService";
import { Alert } from "widgets/ShowFormMsg";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

const MyBox = styled("div")({
  padding: 5,
  width: "100%",
  display: "flex",
  alignItems: "center",
});

const labels: { [index: string]: string } = {
  0.5: "Very Bad",
  1: "Bad",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function ReviewInput({ merchantId, onSubmit }) {
  const { profileData } = useAppSelector((state) => state.MemberResult);
  const [value, setValue] = useState<number | null>(2.5);
  const [hover, setHover] = useState(-1);
  const [comments, setComments] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [error, setError] = useState<boolean | null>(null);

  const onHandleSubmit = async () => {
    try {
      const postData = {
        merchant_id: merchantId,
        comments,
        title,
        rating: value,
      };
      if (profileData) {
        const { member_id } = profileData.profile_detail;
        postData["member_id"] = member_id;
      }
      const response = await callApi("review", "add/comment", postData);
      if (response.state === 1) {
        setError(null);
        onSubmit(response.data);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };

  const onHandleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setComments(event.target.value);
  };
  const onHandleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setTitle(event.target.value);
  };

  const handleVerify = () => {};

  return (
    <BorderBox>
      <GoogleReCaptchaProvider
        reCaptchaKey={`${process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}`}
      >
        <MyBox>
          <span className="fs20 pe-5 desktop">
            How do you think this store?
          </span>
          <Rating
            name="hover-feedback"
            value={value}
            precision={0.5}
            getLabelText={getLabelText}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
          {value !== null && (
            <Box sx={{ paddingLeft: "10px" }}>
              {labels[hover !== -1 ? hover : value]}
            </Box>
          )}
        </MyBox>
        <div className="row m-1">
          <div className="col-12">
            <TextField
              size="small"
              label="Headline"
              defaultValue="Add something important here"
              onChange={onHandleChangeTitle}
              value={title}
              sx={{ width: "100%" }}
              className="pb-3"
            />
            <TextField
              label="Your comments"
              multiline
              rows={4}
              defaultValue="Add review detail here"
              onChange={onHandleChangeInput}
              value={comments}
              sx={{ width: "100%" }}
            />
          </div>
          {comments && comments.length && title && title.length && (
            <div className="col-12 col-lg3 col-mg3 d-flex justify-content-center pb-1">
              <button
                onClick={onHandleSubmit}
                className="btn btn-primary mt-1"
                type="button"
              >
                Submit
              </button>
            </div>
          )}
          {error && (
            <Alert severity="warning">
              Sorry, your comment is not going into our system correctly, please
              try again later!
            </Alert>
          )}
        </div>
        <GoogleReCaptcha onVerify={handleVerify} />
      </GoogleReCaptchaProvider>
    </BorderBox>
  );
}
