import { useEffect, useState } from "react";
import MerchantBanner from "components/merchant/MerchantBanner";
import EnjoyBenefits from "components/merchant/EnjoyBenefits";
import MerchantAdv from "components/merchant/MerchantAdv";
import MerchantMerit from "components/merchant/MerchantMerit";

import BenefitsBanner from "components/merchant/BenefitsBanner";
import GetStarted from "components/merchant/GetStarted";
import { useAppSelector, useAppDispatch } from "common/hooks";
import { setCurPage } from "common/features/PageSetupSlice";
export default function Merchant() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const title = `${process.env.REACT_APP_BRAND_NAME}-merchant-landing`;
    document.title = title;
    dispatch(setCurPage("merchant"));
  }, []);

  return (
    <>
      {/*<MerchantBanner />*/}
      {/* <EnjoyBenefits /> */}
      <MerchantAdv />
      <BenefitsBanner />
      {/*<GetStarted /> */}
      <MerchantMerit />
    </>
  );
}
