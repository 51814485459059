import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  reSendEmailLink,
  updateStatus,
  updateStep,
} from "../../../common/features/UserSignupSlice";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { IObjectKeys } from "common/libraries/interface/PropTypeCollection";
import { Alert } from "widgets/ShowFormMsg";
export default function SignUpClickOnEmailLink() {
  const userSignup: IObjectKeys = useAppSelector((state) => state.UserSignup);
  const { sid, email, status } = userSignup;
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const onClickResendEmail = (e) => {
    e.preventDefault();
    dispatch(reSendEmailLink({ sid, email }));
  };

  const onClickReEditEmail = (e) => {
    e.preventDefault();
    dispatch(updateStep(2.1));
  };

  return (
    <div id="step-2" className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <h3 className="fs28">
            You are almost there, just two more steps to go.
          </h3>
          <div className="d-flex align-items-center justify-content-center">
            {status == "email_resend" && (
              <Alert severity="success">
                Email Sent. Please click the link in the Email to continue
              </Alert>
            )}
          </div>
          <h5 className="fs20">Verify your email</h5>
          <p className="fs20">
            We have sent an email to <label>{email}</label>
          </p>
          <p className="fs20">
            Just click on the link in that email to continue your signup.
            <br /> If you don't see it, you may need to check your spam folder
          </p>
          <p className="text-center mt-5 fs20">
            Still can't find the email?{" "}
            <a
              className="fs20 text-primary"
              href="#"
              onClick={(e) => onClickResendEmail(e)}
            >
              Resend email
            </a>
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-5 text-center">
          <Link
            className="fs20 text-primary"
            to="#"
            onClick={(e) => onClickReEditEmail(e)}
          >
            Go back and edit email
          </Link>
        </div>
      </div>
    </div>
  );
}
