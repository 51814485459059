import { createSlice } from "@reduxjs/toolkit";
import {
    PageState,
} from "@/interface/PropTypeCollection";

const initialState: PageState = {
    curPage: "home",
    navMenu: [],
    navMobileMenu: [],
};

const PageSetupSlice = createSlice({
    name: "PageSetup",
    initialState,
    reducers: {
        setCurPage: (state, action) => {
            state.curPage = action.payload;
        },
        setNavContent: (state, action) => {
            state.navMenu = action.payload;
        },
        setMobileNavContent: (state, action) => {
            state.navMobileMenu = action.payload;
        },
    },
});

export default PageSetupSlice.reducer;
export const { setCurPage, setNavContent, setMobileNavContent } = PageSetupSlice.actions;
