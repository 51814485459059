import { Fragment, useState, useEffect } from "react";
import { Show501 } from "widgets/Errors";
import { useAppDispatch } from "common/hooks";
import { setCurPage } from "common/features/PageSetupSlice";
import ShowPageLoader from "widgets/ShowPageLoader";
import { Buffer } from 'buffer';
import { callApi } from "adapter/ApiService";

export default function PageContent({ contentType }: { contentType: string }) {
    const [content, setContent] = useState("");
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurPage(""));
    }, [])

    useEffect(() => {
        const getContent = async () => {
            const response = await callApi("site", `get/${contentType}`, {});
            if (response.state === 1) {
                try {
                    const ori = Buffer.from(response.data, 'base64').toString('ascii');
                    if (ori) {
                        /* const ob = JSON.parse(ori);
                         const rawState = convertFromRaw(ob);
                         const editorRawState = EditorState.createWithContent(rawState);
                         setContent(editorRawState);
                         */
                        setContent(ori);
                    }
                } catch (e) {

                }

            }
        }
        getContent();

    }, [contentType]);

    if (Object.keys(content).length == 0) {
        return <ShowPageLoader />
    }

    return (<div className="bg-white">
        <div className="container">
            <div className="row">
                {/* content && <Editor
                    editorState={content}
                    toolbarHidden={true}
readOnly /> */}
                <div dangerouslySetInnerHTML={{ __html: content }}></div>



            </div>
        </div>
    </div>
    );
}