import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "adapter/ApiService";
import {
    IObjectKeys,
} from "@/interface/PropTypeCollection";

interface MemberSliceProp {
    profileData: IObjectKeys | null;
    status: 100 | 200 | 401 | 404 | 500; // ok| not authorised | content error | server error
    message: string;
}

const initialState: MemberSliceProp = {
    profileData: null,
    status: 100,
    message: "",
};

export const loadMemberDetail = createAsyncThunk(
    "MemberResult/load_member_detail",
    async (
        { uid }: { uid: string },
        { rejectWithValue, fulfillWithValue }
    ) => {
        try {
            const response: IObjectKeys = await callApi("member", "get/summary_by_authid", { auth_id: uid });
            if (response && response.state === 1) {
                const { data: { profile_detail: { role } } } = response;
                if (role == 3 || role == 1) {
                    return fulfillWithValue(response.data);
                } else {
                    return rejectWithValue({ state: 401, error: { message: `Sorry, you have no authority to visit here` } });
                }
            } else {
                return rejectWithValue({ state: 500, error: { message: `Sorry, there is wrong with the data` } });
            }
        } catch (error: any) {
            return rejectWithValue({ state: 500, error });
        }
    }
);


export const MemberSlice = createSlice({
    name: "MemberResult",
    initialState,
    reducers: {
        updateMemberData: (state, { payload: { memberUserData } }) => {
            state.profileData = memberUserData;
        },
        updateMemberType: (state, { payload }) => {
            if (state.profileData) {
                state.profileData.profile_detail.member_type = payload;
            }
        },
        updateMemberProfile: (state, { payload }) => {
            if (state.profileData) {
                state.profileData.profile_detail = { ...state.profileData.profile_detail, ...payload };
            }
        },

        updateMemberProfileComplete: (state, { payload }) => {
            if (state.profileData) {
                state.profileData.profile_detail.profile_cmp_percentage = payload;
            }
        },
        updateMemberEmail: (state, { payload }) => {
            if (state.profileData) {
                state.profileData.profile_detail.email = payload;
            }
        },
        updateMemberPhone: (state, { payload }) => {
            if (state.profileData) {
                state.profileData.profile_detail.phone = payload;
            }
        },

        updateStatus: (state, action) => {
            const { status, message } = action.payload;
            state.status = status;
            state.message = message;
        },
        updateLogoPath: (state, action) => {
            if (state.profileData) {
                state.profileData.profile_detail.logo_uri = action.payload;
            }
        },
        cleanMessage: (state) => {
            state.message = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadMemberDetail.rejected, (state, action) => {
                const resp: any = action.payload;
                const { error: { message } } = resp;
                state.status = resp.state;
                state.message = message
            })
            .addCase(loadMemberDetail.fulfilled, (state, { payload }) => {
                if (payload) {
                    state.profileData = payload;
                    state.status = 200;
                }
            })
            ;
    },
});

// Action creators are generated for each case reducer function
export const {
    cleanMessage,
    updateMemberData,
    updateMemberType,
    updateMemberProfile,
    updateMemberProfileComplete,
    updateStatus,
    updateLogoPath,
    updateMemberEmail,
    updateMemberPhone,
} = MemberSlice.actions;

export default MemberSlice.reducer;
