import { useFormContext } from "react-hook-form";
export default function SelectCountry({ classes }: { classes?: string | null }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className={`input-group ${classes ?? ''}`}>
      <span className="input-group-text fs20">
        Country <label>*</label>
      </span>
      <select
        {...register("country", { required: true })}
        className="form-select fs20"
      >
        <option value="CA">Canada</option>
        <option value="US">USA</option>
      </select>
    </div>
  );
}
