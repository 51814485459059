import React, { useState, useEffect, ReactNode } from "react";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { MenuProp } from "common/libraries/interface/PropTypeCollection";

interface Props {
    menu_list: Array<MenuProp>;
    id: string;
    title: string;
    page?: string;
    children: ReactNode;
}

const DropDownMenu = (props: Props) => {
    const { menu_list, id, title, page, children } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const specialClass = (page && page == "shopping-detail") ? 'text-black' : 'text-white';

    return (
        <>
            <Link
                onClick={handleClick}
                className={`nav-link nav-item ${specialClass}`}
                to="#"
                role="button"
            >
                {children}
            </Link>
            <Menu
                anchorEl={anchorEl}
                id={id}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {menu_list.map((item: MenuProp, i: number) => <MenuItem key={`${id}-menu-${i}`}>
                    <span>
                        <Link to={item['link']}>{item['title']}</Link>
                    </span>
                </MenuItem>)}
            </Menu>
        </>
    );
}

export default DropDownMenu;
