import { useFormContext } from "react-hook-form";
export default function SelectTitle({ classes }: { classes?: string | null }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className={`input-group ${classes ?? ''}`} >
      <span className="input-group-text fs20">Salutation</span>
      <select
        {...register("title", {
          required: true,
        })}
        className="form-select fs20"
      >
        <option value="mr">Mr</option>
        <option value="miss">Ms</option>
        <option value="mrs">Mrs</option>
        <option value="other">Other</option>
      </select>
    </ div>
  );
}
