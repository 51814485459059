export default function MerchantAdv() {
  return (
    <section className="bg-white py-5 my-4">
      <div className="container">
        <div className="text-white fs32 row d-flex  align-items-center justify-content-center mb-1">
          <div className="col-lg-9 col-md-9 col-sm-12 ">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "merchant-functional/boosting-business.jpg"
              }
              className="w-75"
            />
          </div>
        </div>
        <div className="row align-items-center ">
          <div className="col-md-12 col-lg-5 col-sm-12">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "merchant-functional/growth-with-people.jpg"
              }
              className="w-100"
            />
          </div>
          <div className="col-md-12 col-lg-7 col-sm-12 fs20">
            <p>
              In today's competitive business landscape, we recognize the
              diverse needs of merchants like you. EzGoodie is designed to
              support your business growth and success by connecting you with a
              wide audience of engaged shoppers. Our platform offers a range of
              benefits that go beyond attracting customers seeking discounts and
              rebates.
              <br />
              <br />
              <strong>By partnering with us you'll benefit from:</strong>
              <br /> 1. Increased Exposure:
              <br /> Showcase your products and services to a targeted audience
              actively seeking quality and value.
              <br /> 2. Customer Acquisition:
              <br /> Connect with a steady stream of potential customers who are
              exploring new businesses and products. <br />
              3. Enhanced Customer Engagement:
              <br /> Foster meaningful interactions with personalized offers,
              loyalty programs, and targeted promotions <br />
              4. Competitive Advantage:
              <br /> Stand out from the competition by offering exclusive deals
              and promotions through our platform. <br />
              5. Marketing Support: Let us handle marketing efforts on your
              behalf, raising awareness and driving customers to your business.
              <br /> In our system, you only pay for the result. No gain, No
              Pay. No Pay and Pray. It's a pay-for-performance model, where
              merchants only pay when they see tangible results.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
