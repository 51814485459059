import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    CategoriesProps,
    IObjectKeys,
} from "@/interface/PropTypeCollection";
import type { RootState } from "common/store";
import { callApi } from "adapter/ApiService";

interface CategorySliceProp {
    categories: Array<CategoriesProps>;
    loading: boolean;
    collapseCollections: string[];
}

const initialState: CategorySliceProp = {
    categories: [],
    loading: false,
    collapseCollections: [],
};

export const fetchCategory = createAsyncThunk(
    "category/fetchCategory",
    async (_, { getState }) => {
        try {
            const response = await callApi("category", "get/subcat", { cat_code: "1" });
            return response;
        } catch (error) {
            return { state: -1 };
        }
    }
);
const CategorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        toggleCollapse: (state, action) => {
            const { catCode } = action.payload;
            const index = state.collapseCollections.findIndex((a) => a === catCode);
            if (index === -1) {
                state.collapseCollections = [...state.collapseCollections, catCode];
            } else {
                const newCollection = state.collapseCollections.filter((a) => a !== catCode);
                state.collapseCollections = newCollection;
            }
        },
        addCategory: (state, action) => {
            const { level, parentCode, data } = action.payload;
            if (level === 0) {
                state.categories = [...state.categories, data];
            } else if (level === 1) {
                const parentNode = state.categories.find(
                    (el) => {
                        const checked = (parseInt(el.cat_code) === parentCode);
                        return checked;
                    }
                );
                if (parentNode) {
                    if (parentNode['children'] && parentNode['children'].length > 0) {
                        parentNode['children'] = [...parentNode['children'], data];
                    } else {
                        parentNode['children'] = [data];
                    }
                }
            } else if (level === 2) {
                const parentNode = state.categories.find(
                    (a) => {
                        const grandChild = a['children'].find((b) => parseInt(b.cat_code) === parentCode);
                        if (grandChild) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                );
                if (parentNode) {
                    const grandChild = parentNode['children'].find((b) => parseInt(b.cat_code) === parentCode);
                    if (grandChild['children'] && grandChild['children'].length > 0) {
                        grandChild['children'] = [...grandChild['children'], data];
                    } else {
                        grandChild['children'] = [data];
                    }
                }
            }
        },
        updateCategory: (state, action) => {
            const { level, catCode, data } = action.payload;
            if (level === 0) {
                const curNode = state.categories.find(
                    (el) => {
                        const checked = (parseInt(el.cat_code) === catCode);
                        return checked;
                    }
                );
                if (curNode) {
                    curNode['cat_slug'] = data['cat_slug'];
                    curNode['cat_name'] = data['cat_name'];
                }
            } else if (level === 1) {
                let curNode: any;
                const parentNode = state.categories.find(
                    (a) => {
                        const grandChild = a['children'].find((b) => parseInt(b.cat_code) === catCode);
                        if (grandChild) {
                            curNode = grandChild;
                            return true;
                        } else {
                            return false;
                        }
                    }
                );
                if (parentNode) {
                    curNode['cat_slug'] = data['cat_slug'];
                    curNode['cat_name'] = data['cat_name'];
                }
            } else if (level === 2) {
                let curNode: any;
                const parentNode = state.categories.find(
                    (a) => {
                        if (a['children'] && a['children'].length > 0) {
                            const grandChild = a['children'].find(
                                (b) => {
                                    if (b['children'] && b['children'].length > 0) {
                                        const grandGrandChild = b['children'].find((c) => parseInt(c.cat_code) === catCode);
                                        if (grandGrandChild) {
                                            curNode = grandGrandChild;
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        return false;
                                    }
                                });
                            if (grandChild) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                );
                if (parentNode) {
                    curNode['cat_slug'] = data['cat_slug'];
                    curNode['cat_name'] = data['cat_name'];
                }
            }
        },
        removeCategory: (state, action) => {
            const { level, catCode } = action.payload;
            if (level === 0) {
                const curNodeArr = state.categories.filter(
                    (el) => {
                        const checked = (parseInt(el.cat_code) !== catCode);
                        return checked;
                    }
                );
                state.categories = curNodeArr;

            } else if (level === 1) {
                const parentNodeIndex = state.categories.findIndex(
                    (a) => {
                        if (a['children'] && a['children'].length > 0) {
                            const grandChildIndex = a['children'].findIndex((b) => parseInt(b.cat_code) === catCode);
                            if (grandChildIndex >= 0) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                );
                if (parentNodeIndex >= 0) {
                    state.categories[parentNodeIndex]['children'] = state.categories[parentNodeIndex]['children']
                        .filter((b) => parseInt(b.cat_code) !== catCode);
                }
            } else if (level === 2) {
                let curGrandChildIndex: any;
                const parentNodeIndex = state.categories.findIndex(
                    (a) => {
                        if (a['children'] && a['children'].length > 0) {
                            const grandChildIndex = a['children'].findIndex(
                                (b) => {
                                    if (b['children'] && b['children'].length > 0) {
                                        const grandGrandChildIndex = b['children'].findIndex((c) => parseInt(c.cat_code) === catCode);
                                        if (grandGrandChildIndex >= 0) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        return false;
                                    }
                                });
                            if (grandChildIndex >= 0) {
                                curGrandChildIndex = grandChildIndex;
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                );
                if (parentNodeIndex >= 0) {
                    state.categories[parentNodeIndex]['children'][curGrandChildIndex]['children'] = state.categories[parentNodeIndex]['children'][curGrandChildIndex]['children']
                        .filter((b) => parseInt(b.cat_code) !== catCode);
                }
            }
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategory.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchCategory.fulfilled, (state, action) => {
                const resp = action.payload;
                state.loading = false;
                if (resp.state === 1) {
                    state.categories = resp.data;
                } else {
                    //set error message
                }
            });
    },
});

export default CategorySlice.reducer;
export const { addCategory, removeCategory, updateCategory, toggleCollapse } = CategorySlice.actions;
