import { useFormContext } from "react-hook-form";
import {
    InputProps,
} from "common/libraries/interface/PropTypeCollection";
export default function InputNumber({ classes = '',
    onBlur,
    value = '',
    showLabel = true,
    disabled = false,
    title = null,
    id = null,
    fontSize = '' }: InputProps) {
    const {
        register,
        setValue,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const componentId = `${id ?? "number"}`;
    const componentTitle = `${title ?? 'Number'}`;
    const pattern = /^[0-9]*$/;
    const handleChange = (e) => {
        const v = e.target.value;
        if (v && v.length) {
            const check = pattern.test(v);
            if (!check) {
                if (v.length == 1) {
                    setValue(componentId, '');
                } else {
                    setValue(componentId, parseInt(v));
                }
            } else {
                if (v.length > 1 && v.substring(0, 1) == 0) {
                    setValue(componentId, parseInt(v));
                }
            }
            clearErrors(componentId);
        }
    }

    return (
        <div className={`input-group ${classes}`}>
            {showLabel && <span className={`input-group-text ${fontSize}`}>
                {title ?? 'Number'} <label>*</label>
            </span>}

            <input
                type="text"
                disabled={disabled}
                min={0}
                max={99999999}
                autoComplete="given-name"
                className={`form-control input-calculator ${fontSize}`}
                maxLength={12}
                {...register(componentId, {
                    required: `Please enter ${componentTitle}`,
                    pattern: {
                        value:
                            /^[0-9]*$/,
                        message: `Please enter a valid ${componentTitle}`,
                    },
                    onChange: handleChange,
                    onBlur: onBlur
                })}
            />
        </div>
    );
}
