import { useState, useRef } from "react";
import { useFormContext, useController } from "react-hook-form";
import FormattingIdInput from "./FormattingIdInput";
import { callApi } from "adapter/ApiService";
import { getValue } from "@mui/system";
import { bool } from "aws-sdk/clients/signer";
import { InputProps } from "@/interface/PropTypeCollection";

const ID_REGEX = /^\d{12}$/;
export default function InputMemberId({
  verify = false,
  required = true,
  showIdOnly = false,
  classes = "",
  title = null,
  id = null,
}: InputProps) {
  const {
    trigger,
    getValues,
    setError,
    setFocus,
    formState: { dirtyFields, touchedFields, errors },
  } = useFormContext();

  const componentId = `${id ?? "member_id"}`;
  const componentTitle = `${title ?? "Member ID"}`;

  const checkIdExist = async () => {
    const response = await callApi("user", "get/id_exist", {
      id: value,
      id_type: "member",
    });
    if (response.state !== 1) {
      setError("member_id", {
        type: "custom",
        message: "Member Id is invalid",
      });
      if (idRef.current) {
        // phoneRef.current.focus();
      }
    }
  };

  const checkId = async (number) => {
    const strNumber = `${number}`;
    if (strNumber && strNumber.length > 0) {
      const check = ID_REGEX.test(number);
      if (!check) {
        return "Please type valide Id number";
      }
      if (verify) {
        const response = await callApi("user", "get/id_exist", {
          id: number,
          id_type: componentId,
        });
        //console.log(response);
        if (response.state !== 1) {
          return "Sorry, this id is invalid!";
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    return "Please enter a vaid id number";
  };

  const {
    field: { value, onChange },
  } = useController({
    name: componentId,
    rules: {
      required: required ? "Please enter id number." : false,
      validate: checkId,
      pattern: {
        value: ID_REGEX,
        message: "Please enter a valid id number.",
      },
    },
  });

  const idRef = useRef<HTMLInputElement | null>(null);

  const onBlurId = async () => {
    if (verify) {
      const number = getValues(componentId);
      if (number && number.length > 0) {
        trigger(componentId);
        setTimeout(() => {
          if (
            (dirtyFields[componentId] || touchedFields[componentId]) &&
            !errors[componentId]
          ) {
            checkIdExist();
          }
        }, 100);
      }
    }
  };

  return (
    <div className={`input-group ${classes}`}>
      <span className="input-group-text fs20">
        {componentTitle}
        <label>*</label>
      </span>
      <FormattingIdInput
        // onBlur={() => onBlurId()}
        disabled={showIdOnly ? true : false}
        value={value}
        onChange={onChange}
        ref={idRef}
      />
    </div>
  );
}
