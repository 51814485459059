import { useFormContext } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useState } from "react";
import { InputProps } from "common/libraries/interface/PropTypeCollection";

export default function InputPassword({
  classes,
  name = "password",
  label = "Password",
  enableBlur = true,
  required = true,
}: InputProps) {
  const {
    register,
    setFocus,
    trigger,
    getValues,
    setValue,
    formState: { errors, dirtyFields },
  } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);
  const confirmPassword = getValues("confirm_password");

  const onBlurPassword = () => {
    if (enableBlur) {
      const pass = getValues(name);
      if (pass && pass.length > 0) {
        trigger(name);
        setTimeout(() => {
          if (
            dirtyFields[name] &&
            !errors[name] &&
            confirmPassword &&
            confirmPassword.length
          ) {
            if (name === "password") {
              trigger("confirm_password");
            }
          }
        }, 100);
      }
    }
  };

  const onChangePassword = () => {
    if (name === "password") {
      if (confirmPassword && confirmPassword.length) {
        setValue("confirm_password", "");
      }
    }
  };

  return (
    <div
      className={`input-group`}
      style={{ border: "none", marginBottom: "0px" }}
    >
      <div className={`input-group ${classes}`}>
        <span className="input-group-text fs20">
          {label} <label>*</label>
        </span>
        <input
          type={showPassword ? "text" : "password"}
          className="form-control ig-input-password fs20"
          {...register(name, {
            required: required ? "Enter a valid password" : false,
            pattern: {
              value:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
              message:
                "Password must be minimum 6 characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
            },
            onBlur: () => onBlurPassword(),
            onChange: () => onChangePassword(),
          })}
        />
        <span
          onClick={() => {
            setShowPassword((prevState) => !prevState);
            setFocus(name, { shouldSelect: true });
          }}
          className="ig-password fs20"
        >
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </span>
      </div>
      <div className="desktop" style={{ marginTop: "-30px", fontSize: "11px" }}>
        Password must be a minimum of 6 characters, including at least 1 capital
        letter, 1 small letter, 1 number, and a special symbol.
      </div>
      <div className="mobile" style={{ fontSize: "11px" }}>
        Password must be a minimum of 6 characters, including at least 1 capital
        letter, 1 small letter, 1 number, and a special symbol.
      </div>
    </div>
  );
}
