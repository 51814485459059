import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

export default function CheckNewsletter() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

     
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value=""
        id="tips"
        {...register("newsletter")}
      />
      <label className="form-check-label fs16" htmlFor="tips">
        I would like to receive tips and savings to my email
      </label>
    </div>
  );
}
