import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SignUpBanner from "components/form/signup/Banner";
import SignUpMerchantFormWrapper from "components/form/signup/MerchantFormWrapper";
import { SignUpType } from "common/libraries/interface/PropTypeCollection";
import SignUpSteps from "components/form/signup/Steps";
import InputConfirmPassword from "components/inputs/InputConfirmPassword";
import InputEmail from "components/inputs/InputEmail";
import InputPassword from "components/inputs/InputPassword";
import InputPhone from "components/inputs/InputPhone";
import InputSponsorId from "components/inputs/InputSponsorId";
import SelectCountry from "components/inputs/SelectCountry";
import CheckTerms from "components/inputs/CheckTerms";
import CheckNewsletter from "components/inputs/CheckNewsletter";
import InputBusinessName from "components/inputs/InputBusinessName";
import InputBusinessCategory from "components/inputs/InputBusinessCategory";
import FormErrors from "components/form/FormErrors";
import { useAppSelector, useAppDispatch } from "common/hooks";
import SignUpFormSubmit from "components/form/signup/FormSubmit";
import { ShowFormError } from "widgets/ShowFormMsg";
import { useUserStatus } from "common/hooks";
import "common/assets/css/signup.css";

export default function MerchantSignUp() {
  const params = useParams();
  const { memberSid } = params;

  const { userRole } = useUserStatus();
  const navigate = useNavigate();
  const merchantSignup = useAppSelector((state) => state.UserSignup);

  const [formType] = useState<SignUpType>("business");
  const { status, message } = merchantSignup;

  useEffect(() => {
    if (status == "done") {
      navigate("/merchant/page/dashboard");
    }
  }, [status]);

  useEffect(() => {
    console.log("merchant-signup:role >>", userRole);
    if (userRole == 2 || userRole == 3) {
      navigate("/merchant/page/dashboard");
    }
  }, [userRole]);

  return (
    <SignUpMerchantFormWrapper type={formType}>
      <div
        className={`${status === "signup_form_submit" ? "disabled-div" : ""}`}
      >
        <h3 className="fs28">Create Your Account for Free</h3>
        <div className="row justify-content-center">
          <div className="member-type" id="form-top">
            <p className="text-center fs20">
              <a href={`/signup/member${memberSid ? `/${memberSid}` : ""}`}>
                Switch to Member Signup
              </a>
            </p>
          </div>
        </div>
        <div className="forms">
          <div className="row">
            <FormErrors />
            <div className="col-lg-6 col-md-6 col-sm-12 column">
              <InputBusinessName name="business_name" />
              <InputBusinessCategory />
              <SelectCountry />
              <InputPhone verify sign_role={2} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 column">
              <InputEmail verify sign_role={2} />
              <InputPassword />
              <InputConfirmPassword />
              <InputSponsorId />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-12 column">
              <CheckTerms role={2} />
              <CheckNewsletter />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-12 column justify-content-center">
              <SignUpFormSubmit />
            </div>
          </div>
        </div>
      </div>
    </SignUpMerchantFormWrapper>
  );
}
