import * as React from "react";
import { Link } from "react-router-dom";
import { useUserStatus } from "common/hooks";

export default function HomeStickMenu() {
  const { user } = useUserStatus();

  return (
    <div className="bg-primary our-links sticky-bottom">
      <ul className="nav justify-content-center">
        {!user && (
          <>
            <li className="nav-item dropup">
              <Link
                className="nav-link"
                to="#"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/signin.png`}
                  alt="signin-icon"
                />
                &nbsp;Sign In
              </Link>
              <ul
                className="dropdown-menu dropdown-menu-end"
                style={{ bottom: 45 }}
              >
                <li>
                  <Link className="dropdown-item" to="/login/member">
                    {" "}
                    Member
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/login/merchant">
                    {" "}
                    Merchant{" "}
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/login/cashier">
                    {" "}
                    Cashier{" "}
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item dropup">
              <Link
                className="nav-link"
                to="#"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  src={`${process.env.REACT_APP_IMG_HOME_LINK}/icons/signup.png`}
                  alt="signup-icon"
                />
                &nbsp;Sign Up
              </Link>
              <ul
                className="dropdown-menu dropdown-menu-end"
                style={{ bottom: 45 }}
              >
                <li>
                  <Link className="dropdown-item" to="/signup/member">
                    {" "}
                    Member
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/signup/merchant">
                    {" "}
                    Merchant{" "}
                  </Link>
                </li>
              </ul>
            </li>
          </>
        )}

        {/* 
            <li className="nav-item dropdown">
                <Link className="nav-link" to="#" onClick={(e) => e.preventDefault()}><img src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/share-social-media.png`} alt="share-social-media-icon" />&nbsp;Share With A Friend</Link>
                <ul className="dropdown-menu dropdown-menu-end" style={{ bottom: 45 }}>
                    <li><a className="dropdown-item" href="#"> Instagram </a></li>
                    <li><a className="dropdown-item" href="#"> Whatsapp</a></li>
                    <li><a className="dropdown-item" href="#"> Messenger</a></li>
                    <li><a className="dropdown-item" href="#"> Twitter </a></li>
                    <li><a className="dropdown-item" href="#"> Pinterest </a></li>
                    <li><a className="dropdown-item" href="#">  LinkedIn </a></li>
                    <li><a className="dropdown-item" href="#">  Google+ </a></li>
                </ul>
            </li>
*/}

        <li className="nav-item">
          <Link className="nav-link" to="/shopping">
            <img
              src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/shop-now.png`}
              alt="shop-now-icon"
            />
            &nbsp;Shop Now
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/how-it-works" className="nav-link">
            <img
              src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/more-info.png`}
              alt="more-info-icon"
            />
            &nbsp;How it works
          </Link>
        </li>
      </ul>
    </div>
  );
}
