import React, { useState } from "react";

import { ShowFormErrorMsg, ShowFormOkMsg } from "widgets/ShowFormMsg";
import { callApi } from "adapter/ApiService";
import { send } from "process";

export default function NewsLetter() {
  const [check, setCheck] = useState<boolean>(false);
  const [checkVal, setCheckVal] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailErro, setEmailError] = useState<boolean>(false);
  const [checkErro, setCheckError] = useState<boolean>(false);
  const [sendOk, setSendOk] = useState<boolean | null>(null);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
    setCheckError(false);
    setSendOk(null);
  };

  const onChangeCheck = (e) => {
    const { value, checked } = e.currentTarget;
    setCheck(checked);
    setCheckVal(value);
    if (checkErro && checked) {
      setCheckError(false);
    }
    setSendOk(null);
  };

  const emailRegx =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const onClickSend = async () => {
    const testCheck = emailRegx.test(email);
    if (!testCheck) {
      setEmailError(true);
    }
    if (!check) {
      setCheckError(true);
    }
    if (testCheck && check) {
      const response = await callApi("user", "add/newsletter_subscribe", {
        email,
      });
      if (response.state === 0) {
        setSendOk(false);
      } else {
        setSendOk(true);
      }
    }
  };

  const errMsg = emailErro
    ? "Please type valid email address"
    : checkErro
    ? "Please confirm the 'privacy policy'"
    : "";

  return (
    <>
      <div className="col-6 col-md-9 col-lg-3">
        <h4 className="fs20">News Letter</h4>
        <p className="lh-lg">
          Don't miss any updates or promotions by signing up to our newsletter.
        </p>
        <div className="d-none d-sm-none d-md-block">
          {errMsg && <ShowFormErrorMsg message={errMsg} />}
          {sendOk === false ? (
            <ShowFormErrorMsg
              message={"Your subscribe failed, please try again later!"}
            />
          ) : sendOk === true ? (
            <ShowFormOkMsg message={"Your subscribe successfully!"} />
          ) : null}
          <div className="input-group mb-3">
            <input
              id="news-letter-signup"
              type="email"
              className="form-control"
              placeholder="Your email"
              aria-describedby="basic-addon2"
              style={{ background: "white" }}
              value={email}
              onChange={(e) => onChangeEmail(e)}
            />
            <span className="input-group-text" id="basic-addon2">
              <img
                onClick={() => onClickSend()}
                alt="send"
                src={
                  process.env.REACT_APP_IMG_HOME_LINK +
                  "homepage/redicons/send.png"
                }
              />
            </span>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              value={checkVal}
              checked={check}
              onChange={(e) => onChangeCheck(e)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              I have read and agree to the Privacy Policy
            </label>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-3 d-sm-block d-md-none mt-2">
        {errMsg && <ShowFormErrorMsg message={errMsg} />}
        <div className="input-group mt-1 mb-3">
          <input
            type="email"
            className="form-control"
            placeholder="Your email"
            aria-describedby="basic-addon2"
            style={{ background: "white" }}
            value={email}
            onChange={(e) => onChangeEmail(e)}
          />
          <span className="input-group-text" id="basic-addon2">
            <img
              onClick={() => onClickSend()}
              alt="send"
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "homepage/redicons/send.png"
              }
            />
          </span>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            checked={check}
            value={checkVal}
            onChange={(e) => onChangeCheck(e)}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            I have read and agree to the Privacy Policy
          </label>
        </div>
      </div>
    </>
  );
}
