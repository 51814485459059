import {
  CategoriesProps,
  SubCategoriesProps,
} from "common/libraries/interface/PropTypeCollection";
import { useState, useEffect } from "react";
import ShopSubCategories from "./ShopSubCategories";
import { useAppDispatch, useAppSelector } from "common/hooks";
import {
  addCategories,
  removeCategories,
  fetchProducts,
} from "common/features/ShopSlice";

export default function ShopCategories({
  cat_code,
  cat_name,
  cat_slug,
  parent_code,
  children
}: SubCategoriesProps) {
  const [showSubCat, setShowSubCat] = useState(false);
  const [parentCheck, setParentCheck] = useState<boolean | null>(null);

  const dispatch = useAppDispatch();

  const { sidebar } = useAppSelector((state) => state.ShopReducer);
  const { categories } = sidebar;

  const onCheckCat = (e) => {
    if (e.target.checked) {
      dispatch(addCategories({ l1: cat_code }));
      dispatch(fetchProducts(0));
    }
    if (!e.target.checked) {
      //dispatch(removeCategories({ l1: cat_code }));
      dispatch(removeCategories(cat_code));
      dispatch(fetchProducts(0));
    }
  }

  return (
    <div className={`sublistmain ${showSubCat ? "show" : ""}`}>
      <div className="showmore">
        <div className="form-check">
          <input
            className="form-check-input fs20"
            type="checkbox"
            onChange={(e) => onCheckCat(e)}
            id={cat_code}
            checked={categories.length > 0 && categories.includes(cat_code)}
          />
          <label className="form-check-label lh-base" htmlFor={cat_code}>
            {cat_name}
          </label>
        </div>
        {children && children.length > 0 && <div
          className="showmoreicon"
          onClick={() => setShowSubCat((prevState) => !prevState)}
        ></div>
        }
      </div>
      {showSubCat &&
        children?.map((el: SubCategoriesProps) => (
          <ShopSubCategories
            key={el.cat_code}
            cat_name={el.cat_name}
            cat_slug={el.cat_slug}
            children={el.children}
            parent_code={el.parent_code}
            cat_code={el.cat_code}
            onParentCheck={parentCheck}
          />
        ))}
    </div>
  );
}
