import {
  Fragment,
  useState,
  MouseEvent,
  KeyboardEvent,
  useEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../common/hooks";
import { callApi } from "adapter/ApiService";
import { ShowFormError } from "widgets/ShowFormMsg";
import ConfirmModal from "components/common/ConfirmModal";

export default function PageUserForgotPassword() {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState<boolean | null>(null);
  const [msg, setMsg] = useState<string | null>(null);
  const [showCloseDlg, setShowCloseDlg] = useState(false);
  const navigate = useNavigate();

  const onClickReset = async (e: MouseEvent | KeyboardEvent) => {
    setMsg(null);
    e.preventDefault();
    if (
      email == "" ||
      !/^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)
    ) {
      setMsg("Please type the correct email address");
      return;
    }
    //const forwardUrl = `${process.env.REACT_APP_RESET_PASSWORD}?email=${email}`;
    const forwardUrl = `${process.env.REACT_APP_URL}login/slug`;
    const response = await callApi("user", "update/forgot_password", {
      email,
      forward_url: forwardUrl,
    });
    if (response && response.state == 1) {
      setSent(true);
      setTimeout(() => setShowCloseDlg(true), 30 * 1000); //30 seconds
    } else {
      setSent(false);
      setMsg(response.message);
    }
  };

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setMsg(null);
  };

  const onClickGoback = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleNotCloseWindow = () => {
    setShowCloseDlg(false);
  };

  const handleCloseWindow = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  useEffect(() => {
    document.title = `${process.env.REACT_APP_BRAND_NAME}-Reset-password`;
  }, []);

  return (
    <>
      {sent !== true && (
        <div className="member-signup-form forgot-form">
          <div className="container">
            <h3 className="mb-5">FORGOT PASSWORD</h3>
            <div id="withemail" className="forms">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {msg && (
                    <div className="mb-1">
                      <ShowFormError
                        title="There is something wrong here"
                        message={msg}
                      />
                    </div>
                  )}
                  <div className="input-group">
                    <span className="input-group-text">
                      Email <label>*</label>
                    </span>
                    <input
                      type="email"
                      onChange={(e) => onChangeEmail(e)}
                      className="form-control"
                    />
                  </div>
                  <div className="d-grid mb-3 mt-2">
                    <button
                      onClick={(e) => onClickReset(e)}
                      className="btn btn-primary"
                    >
                      SUBMIT
                    </button>
                  </div>
                  <div className="signin-type">
                    <p className="text-center login-account">
                      <a
                        href="#"
                        onClick={(e) => onClickGoback(e)}
                        className="text-primary"
                      >
                        Go back
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {sent === true && (
        <div className="member-signup-form forgot-form-success member-signup-completed">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-8 text-center">
                <label className="round-icon">
                  <img
                    src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/redicons/signup-email-icon.png`}
                  />
                </label>
                <h3 className="mt-3">EMAIL SENT!</h3>
                <p>We have emailed you a link to reset your password.</p>
                <p className="text-center login-account">
                  <a
                    href="#"
                    onClick={(e) => onClickGoback(e)}
                    className="text-primary"
                  >
                    {" "}
                    Go back
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmModal
        showModal={showCloseDlg}
        title={"Important Notice"}
        message={"Please close current window"}
        handleYes={handleCloseWindow}
        handleNo={handleNotCloseWindow}
        handleClose={handleCloseWindow}
      />
    </>
  );
}
