/*
 * Api service for data commuication with server side except drupal user managemnt
 */
import axios from 'axios';
// import moment from 'moment';

export const callApi = (section, action, param) => {
    const fetchUrl = `${process.env.REACT_APP_SERVICE_API}${section}/${action}`;
    // param['env'] = `${process.env.REACT_APP_ENV}`;
    try {
        var FormData = require('form-data');
        var data = new FormData();
        data.append('q', JSON.stringify(param));

        var config = {
            method: 'post',
            url: fetchUrl,
            headers: {
                'Authorization': `Basic ${process.env.REACT_APP_SERVICE_API_KEY}`
            },
            data: data
        };
        return axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
                if (response.data.error) {
                    return { state: -1 };
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
                return { state: -1 };
            });

    } catch (error) {
        console.error(error);
        return { state: -1 };
    }
};

export const callSPApi = (section, action, param) => {
    const fetchUrl = `${process.env.REACT_APP_SP_SERVICE_API}${section}/${action}`;
    // param['env'] = `${process.env.REACT_APP_ENV}`;
    try {
        var FormData = require('form-data');
        var data = new FormData();
        data.append('q', JSON.stringify({ inputs: param }));

        var config = {
            method: 'post',
            url: fetchUrl,
            headers: {
                'Authorization': `Basic ${process.env.REACT_APP_SP_SERVICE_API_KEY}`
            },
            data: data
        };
        return axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
                if (response.data.error) {
                    return { state: -1 };
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
                return { state: -1 };
            });

    } catch (error) {
        console.error(error);
        return { state: -1 };
    }
};



