import { useEffect, useState } from "react";
import { callApi } from "adapter/ApiService";
import { CategoriesProps, SubCategoriesProps } from "common/libraries/interface/PropTypeCollection";

import ShopCategories from "./ShopCategories";

export default function CategoryWrapper() {
    const [mainCategories, setMainCategories] = useState<Array<CategoriesProps> | []>([]);

    useEffect(() => {
        const getCategories = async () => {
            const response = await callApi("category", "get/subcat", { cat_code: "1" });
            setMainCategories(response.data);
        };
        getCategories();
    }, []);

    return <>
        <div className="showmore show">
            <h6 className="fs20">All Categories</h6>
            <div className="showmoreicon"></div>
        </div>
        <ul>
            <li className="fs20">
                {mainCategories && mainCategories.length > 0 &&
                    mainCategories.map((el: SubCategoriesProps) => (
                        <ShopCategories
                            key={el.cat_code}
                            cat_code={el.cat_code}
                            cat_name={el.cat_name}
                            cat_slug={el.cat_slug}
                            parent_code={el.parent_code}
                            children={el.children}
                        />
                    ))
                }
            </li>
        </ul>
    </>;
}
