import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NewsLetterSubscribe from "./NewsLetter";
import { useAppSelector } from "common/hooks";
import DashboardFooter from "components/footer/DashboardFooter";
import HelpReourceLink from "components/common/HelpResourceLink";
import { useEffect, useState } from "react";

export default function SiteFooter() {
  const [expand, setExpand] = useState(false);
  const { curPage } = useAppSelector((state) => state.PageSetup);
  let isNormalFooter = true;
  switch (curPage) {
    case "merchant_dashboard":
    case "member_dashboard":
    case "admin_dashboard":
    case "cashier_dashboard":
      isNormalFooter = false;
      break;

    default:
      isNormalFooter = true;
      break;
  }

  const showFooter = () => {
    return (
      <>
        <div className="bg-dark footer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <Link className="logo" to="/">
                  <img
                    src={
                      process.env.REACT_APP_IMG_HOME_LINK + "logo/logo-new.png"
                    }
                  />
                </Link>
                <p className="address">
                  4675 Steeles Ave. E. Unit 2B6
                  <br />
                  Toronto, Ontario, M1V 4S6, Canada
                </p>
                <p className="socialtext">
                  <img
                    src={
                      process.env.REACT_APP_IMG_HOME_LINK +
                      "homepage/redicons/social-phone.png"
                    }
                    className="icon"
                  />
                  : <a href="tel:416-878-3684">416-878-3684</a>
                </p>
                <p className="socialtext">
                  <img
                    src={
                      process.env.REACT_APP_IMG_HOME_LINK +
                      "homepage/redicons/social-email.png"
                    }
                    className="icon"
                  />
                  :{" "}
                  <a href="mailto:support@ezgoodie.com">support@ezgoodie.com</a>
                </p>
              </div>
              <div className="col-6 col-md-3 col-lg-2">
                <h4 className="fs20">About Us</h4>
                <ul>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/term-and-conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-3 col-lg-2">
                <h4 className="fs20">Here To Help</h4>
                <ul>
                  <li>
                    <Link to="/how-it-works">How it works</Link>
                  </li>
                  <li>
                    <Link to="/customer-service">Customer Service</Link>
                  </li>
                  <li>
                    <Link to="/frequent-asked-questions">FAQs</Link>
                  </li>
                  <li>
                    <HelpReourceLink />
                  </li>
                </ul>
              </div>
              <div className="col-6 col-md-3 col-lg-2">
                <h4 className="fs20">Quick Links</h4>
                <ul>
                  <li>
                    Member: <Link to="/signup/member">Sign-up</Link> |{" "}
                    <Link to="/login/member">login</Link>
                  </li>
                  <li>
                    Merchant: <Link to="/signup/merchant">Sign-up</Link> |{" "}
                    <Link to="/login/merchant">login</Link>
                  </li>
                  <li>
                    <Link to="/login/cashier">Cashier login</Link>
                  </li>
                  <li>
                    <Link to="/login/admin">Admin login</Link>
                  </li>
                </ul>
              </div>
              <NewsLetterSubscribe />
            </div>
          </div>
        </div>
        <div className="bg-primary footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 text-center text-md-start">
                <p>Copyright © 2022 EzGoodie. All Rights Reserved</p>
              </div>
              <div className="col-md-6 col-sm-12 text-center text-md-end mt-2 mt-md-0">
                <img
                  alt="social facebook"
                  src={
                    process.env.REACT_APP_IMG_HOME_LINK +
                    "homepage/redicons/social-facebook.png"
                  }
                />
                <img
                  alt="social instagram"
                  src={
                    process.env.REACT_APP_IMG_HOME_LINK +
                    "homepage/redicons/social-instagram.png"
                  }
                />
                <img
                  alt="social twitter"
                  src={
                    process.env.REACT_APP_IMG_HOME_LINK +
                    "homepage/redicons/social-twitter.png"
                  }
                />
                <img
                  alt="social youtube"
                  src={
                    process.env.REACT_APP_IMG_HOME_LINK +
                    "homepage/redicons/social-youtube.png"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  useEffect(() => {
    setExpand(false);
  }, []);

  const handleChange = () => {
    setExpand(!expand);
  };
  return isNormalFooter ? (
    <>
      <div className="desktop">{showFooter()}</div>
      <div className="mobile">
        <div
          onClick={handleChange}
          className="w-100 pt-3 pb-3 text-white fs20 text-uppercase bg-dark text-center"
        >
          Footer {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>

        <div
          onClick={() => setExpand(false)}
          className={`${expand ? "" : "hide"}`}
        >
          {showFooter()}
        </div>
      </div>
    </>
  ) : (
    <DashboardFooter />
  );
}
