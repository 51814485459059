import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { IObjectKeys } from "common/libraries/interface/PropTypeCollection";
import { useAppSelector, useAppDispatch, useUserStatus } from "common/hooks";
import { updateStatus, updateStep } from "common/features/UserSignupSlice";
import SignUpBanner from "components/form/signup/Banner";
import SignUpSteps from "components/form/signup/Steps";
import { ModalLoader } from "widgets/ShowLoader";
import { setCurPage } from "common/features/PageSetupSlice";
import { ShowFormError } from "widgets/ShowFormMsg";
import { Show403 } from "widgets/Errors";

export default function PageUserSignup() {
  const params = useParams();
  const { pathname } = useLocation();
  const pathSplit = pathname.replace(/^\/+/, "").replace(/\/+$/, "").split("/");
  const userSignup = useAppSelector((state) => state.UserSignup);
  const { status, step, role, message } = userSignup;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  useEffect(() => {
    document.title = `${process.env.REACT_APP_BRAND_NAME}-signup`;
    // if (pathSplit[1] === 'member' || pathSplit[1] === 'merchant') {
    if (!step) {
      dispatch(updateStep(1));
    }
    dispatch(updateStatus("pending"));
    // }
    dispatch(setCurPage("signup"));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (step == 2) {
        navigate("/signup/confirm-email");
      }
    }, 1000);
  }, [step]);

  useEffect(() => {
    if (status == "done") {
      navigate("/signup/completed", { state: { role } });
    }
  }, [status]);

  return (
    <>
      <SignUpBanner currentStep={step} />
      <div className="member-signup-form">
        <div className="container">
          <SignUpSteps currentStep={step} />
          {status === "signup_form_submit" && <ModalLoader />}
          {status === "add_account_failed" && (
            <ShowFormError message={message} />
          )}
          {status === "account_not_found" && (
            <Show403
              title="!"
              message="Sorry, we haven't found the account for this link"
            />
          )}
          <Outlet />
        </div>
      </div>
    </>
  );
}
