import React from "react";
import { useFormContext } from "react-hook-form";
import { ShowFormError } from 'widgets/ShowFormMsg';

export default function FormErrors() {
  const {
    formState: { errors, submitCount },
  } = useFormContext();

  const formatKey = (key: string) => {
    return key.replace("_", " ");
  };
  //console.log(errors);
  if (Object.keys(errors).length > 0) {
    /*
    if (Object.keys(errors).length > 0) {
      return (
        <div className="bg-gradient bg-warning text-danger p-3">
          {Object.keys(errors).map((key) => (
            <p key={`error-${formatKey(key)}`}>
              <span>{errors[key]?.message as any}</span>
            </p>
          ))}
        </div>
      );
    }*/
    const message = Object.keys(errors).map((key) => (
      errors[key]?.message as any
    ))
    return <ShowFormError title="There is something wrong" message={message} />;
  }
  return null;
}
