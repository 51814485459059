import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUpBanner from "components/form/signup/Banner";
import SelectMemberType from "components/inputs/SelectMemberType";
import SignUpFormWrapper from "components/form/signup/MemberFormWrapper";
import { SignUpType } from "common/libraries/interface/PropTypeCollection";
import SignUpSteps from "components/form/signup/Steps";
import SignUpIndividual from "components/form/signup/MemberIndividual";
import SignUpGroup from "components/form/signup/MemberGroup";
import { useAppSelector, useAppDispatch } from "common/hooks";
import { setCurPage } from "common/features/PageSetupSlice";
import { useUserStatus } from "common/hooks";
import { ShowFormError } from "widgets/ShowFormMsg";

export default function MemberSignUp() {
  const { userRole } = useUserStatus();
  const navigate = useNavigate();
  const userSignup = useAppSelector((state) => state.UserSignup);
  const dispatch = useAppDispatch();
  const [formType, setFormType] = useState<SignUpType>("individual");
  const { status, message } = userSignup;

  const handleFormChange = (type: SignUpType) => {
    setFormType(type);
  };

  useEffect(() => {
    if (status == "done") {
      navigate("/member/page/dashboard");
    }
  }, [status]);

  useEffect(() => {
    console.log("member-signup:role >>", userRole);
    if (userRole == 1 || userRole == 3) {
      navigate("/member/page/dashboard");
    }
  }, [userRole]);

  return (
    <SignUpFormWrapper type={formType}>
      <div id="step-1" className="step-container">
        <h3 className="fs28" id="form-top">
          Create Your Account for Free
        </h3>
        <div
          className={`${status == "signup_form_submit" ? "disabled-div" : ""}`}
        >
          <SelectMemberType onChange={handleFormChange} />
          {formType === "individual" ? (
            <SignUpIndividual />
          ) : (
            <SignUpGroup formType={formType} />
          )}
        </div>
      </div>
    </SignUpFormWrapper>
  );
}
