import * as React from 'react';
import Helper from "common/libraries/Helper";

export default function MerchantCover({ coverUri, catSlug }) {
    return (
        <img
            alt="banner"
            src={Helper.getMerchantCover(coverUri, catSlug)}
            className="main-banner mx-auto d-block" />
    );
}


