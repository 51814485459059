import { Link, useNavigate, useLocation } from "react-router-dom";

export default function PageUserLogoutResult() {
    const { state } = useLocation();
    let loginMethod;
    if (state) {
        const { role } = state;
        if (role == 1) {
            loginMethod = "/login/member";
        } else if (role == 2) {
            loginMethod = "/login/merchant";
        } else if (role == 4) {
            loginMethod = "/login/cashier";
        } else if (role == 8) {
            loginMethod = "/login/admin";
        } else {
            loginMethod = "/login/member";
        }
    }

    const navigate = useNavigate();
    return (
        <>
            <div className="logout-success-container">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <img className="img-fluid" alt="sign-out" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/signout-success.png`} />
                        </div>

                        <div className="col-12 col-lg-6 col-md-6 col-sm-12 align-self-center text-center">
                            <img className="img-fluid dialog-icon" alt="check-mark" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/green-check.png`} />
                            <h3 className="fs28">You have logout your account successfully!</h3>
                            <p className="fs20">Looking forward to see your visiting us again very soon !</p>
                            <p className="text-center fs20">Back to <Link to={`${loginMethod ?? '/login/member'}`}>Login</Link></p>

                            <div className="buttons">
                                <button onClick={() => navigate("/")}
                                    className="btn btn-primary mt-4">BACK TO &nbsp;
                                    <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
