import { Link } from "react-router-dom";
export default function MemberInfoNavLinks({ navIndex = 0, fsClass = "" }) {
  return (
    <div className="container">
      <div className="row pt-2 d-flex align-items-center justify-content-center">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <Link className="" to="/page/member/shopping-process">
            <button
              className={`me-1 mb-2 w-100 btn border ${
                navIndex === 0 ? "btn-primary" : ""
              } ${fsClass}`}
            >
              Merchant Partners <br />
              Shopping Process
            </button>
          </Link>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <Link className="" to="/page/member/refer-earn">
            <button
              className={`mb-2 w-100 btn border me-2 ${
                navIndex === 1 ? "btn-primary" : ""
              } ${fsClass}`}
            >
              Cash-Back,Referral &<br /> Team Bonus
            </button>
          </Link>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <Link className="" to="/page/member/profit-share">
            <button
              className={`mb-2 w-100 btn border ${
                navIndex === 2 ? "btn-primary" : ""
              } ${fsClass}`}
            >
              MerchRep & <br /> Commission
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
