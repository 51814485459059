import React, { useEffect, useState, useLayoutEffect } from "react";
import Maintenance from "./pages/Maintenance";
import AppRoutes from "AppRoutes";

function App() {
  const [idle, setIdle] = useState(false);
  const onHandlePassed = () => {
    setIdle(false);
  }

  useEffect(() => {
    const needVerify = parseInt(`${process.env.REACT_APP_MAINTENANCE_REQUIRED}`);
    if (needVerify) {
      const accessToken = window.localStorage.getItem(`${process.env.REACT_APP_MAINTENANCE_CHECK_ITEM}`);
      if (accessToken) {
        setIdle(false);
      } else {
        setIdle(true);
      }
    } else {
      setIdle(false);
    }
  }, []);

  return (
    <div className="App">
      {idle ? <Maintenance onHandlePassed={onHandlePassed} /> :
        <AppRoutes />
      }
    </div >
  );
}

export default App;
