import * as React from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { isArray } from "react-select/dist/declarations/src/utils";

/*
 <Alert severity="error">This is an error message!</Alert>
 <Alert severity="warning">This is a warning message!</Alert>
 <Alert severity="info">This is an information message!</Alert>
 <Alert severity="success">This is a success message!</Alert>
*/
export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

export function ShowFormOk({
  title,
  message,
}: {
  title?: string;
  message?: null | string;
}) {
  return (
    <div
      style={{ background: "#FFBCB9" }}
      className="custom-dialog align-items-center justify-content-center"
    >
      <img
        alt="success"
        className="dialog-icon"
        style={{ width: "40px", height: "40px" }}
        src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/redicons/check-mark-green.png`}
      />
      {title && title.length > 0 && (
        <h3 className="title-border-center">{title}</h3>
      )}
      {message && message.length > 0 && <p className="">{message}</p>}
    </div>
  );
}

export function ShowFormOkMsg({ message }: { message: string }) {
  return (
    <>
      {message && message.length > 0 && (
        <Alert severity="success"> {message}</Alert>
      )}
    </>
  );
}

export function ShowFormError({
  title,
  message,
}: {
  title?: string;
  message?: string[] | string | null;
}) {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 pt-2 pb-2 column">
      {message &&
        Array.isArray(message) &&
        message.length > 0 &&
        message.map((item, i) => (
          <Alert className="mb-1" key={`msg-item-${i}`} severity="warning">
            {" "}
            {item}
          </Alert>
        ))}
      {message &&
        (typeof message == "string" || message instanceof String) &&
        message.length > 0 && <Alert severity="warning"> {message}</Alert>}
    </div>
  );
}

export function ShowFormErrorMsg({ message }: { message: string }) {
  return (
    <>
      {message && message.length > 0 && (
        <Alert severity="error">{message}</Alert>
      )}
    </>
  );
}
