import { useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "common/hooks";
import { setKeyword, fetchProducts } from 'common/features/ShopSlice';
import CategoryWrapper from "./CategoryWrapper";

export default function ShopSideBar() {
  const dispatch = useAppDispatch();
  const { sidebar: { keywords } } = useAppSelector((state) => state.ShopReducer);


  const onHandleKeyword = (e) => {
    const { value } = e.target;
    dispatch(setKeyword(value));
    dispatch(fetchProducts(0));
  }

  return (
    <div className="col col-lg-3 col-md-3 desktop">
      <div className="categories custom-form">
        <div className="input-group align-items-center mb-5">
          <span className="input-group-text fs16 content-max-width">
            Filter
          </span>
          <input
            type="text"
            className="form-control py-1 px-1 fs16 ms-1"
            onChange={onHandleKeyword}
            value={keywords}
            placeholder="Store name"
          />
          <button
            type="button"
            className="btn btn-primary fs16 py-1 px-1 ms-2 clearbtn bg-grayer-lighter text-dark"
          >
            <img alt="search"
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "homepage/redicons/search-icon.svg"
              }
            />
          </button>
        </div>
        <CategoryWrapper />
      </div>
    </div>
  );
}
