import { configureStore } from "@reduxjs/toolkit";
import UserLoginReducer from "common/features/UserLoginSlice";
import MemberReducer from "common/features/MemberSlice";
import MerchantReducer from "common/features/MerchantSlice";
import UserSignupReducer from "common/features/UserSignupSlice";
import AdminReducer from "common/features/AdminSlice";
import CashierReducer from "common/features/CashierSlice";
import PageSetupReducer from "common/features/PageSetupSlice";
import MaintenanceReducer from "common/features/MaintenanceSlice";
import HomepageReducer from "common/features/HomeSlice";
import ShopReducer from "common/features/ShopSlice";
import SidebarReducer from "common/features/SidebarSlice";
import CategoryReducer from "common/features/CategorySlice";



export const store = configureStore({
  reducer: {
    PageSetup: PageSetupReducer,
    UserResult: UserLoginReducer,
    MemberResult: MemberReducer,
    MerchantResult: MerchantReducer,
    AdminResult: AdminReducer,
    CashierResult: CashierReducer,
    UserSignup: UserSignupReducer,
    MaintenanceReducer,
    HomepageReducer,
    ShopReducer,
    SidebarReducer,
    CategoryReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
