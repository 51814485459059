import React, { useState, useEffect, useMemo } from "react";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player/lazy";
import { ConfirmationDialogRawProps } from "common/libraries/interface/PropTypeCollection";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";

export function DialogShowVideo(props: ConfirmationDialogRawProps) {
  const { onClose, open, ...other } = props;

  const handleEntering = () => {};

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
      maxWidth="md"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogContent dividers>
        <div className="sub-header mt-4">
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
            width="100%"
            height="420px"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

const popWinS = (url, w, h, scroll, x, y) => {
  const X = x + 50;
  const Y = y + 0;
  const newUrl = decodeURIComponent(url.replace(/\+/g, " "));
  window.open(
    newUrl,
    "win",
    `toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=${scroll},resizable=0,width=${w},height=${h},top=${Y},left=${X}`
  );
};

export function DialogShowPdf(props: ConfirmationDialogRawProps) {
  const { onClose, open, ...other } = props;

  const handleEntering = () => {};

  const handleCancel = () => {
    onClose();
  };

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
      maxWidth="lg"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogContent dividers>
        <div className="sub-header mt-4">
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ width: "100%", height: "640px" }}>
          <object
            data={`${process.env.REACT_APP_SOURCE_HOME_LINK}how-it-works.pdf`}
            type="application/pdf"
            width="100%"
            height="100%"
          ></object>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default function HelpReourceLink(props) {
  const [showVideo, setShowVideo] = useState(false);
  const [showPdf, setShowPdf] = useState(false);

  const handleOpenVideo = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  const handleOpenPdf = () => {
    setShowPdf(true);
    /*
    popWinS(
      `${process.env.REACT_APP_SOURCE_HOME_LINK}how-it-works.pdf`,
      800,
      720,
      true,
      "10%",
      "30%"
    );
    */
  };

  const handleClosePdf = () => {
    setShowPdf(false);
  };

  return (
    <>
      {/*
      <button
        onClick={handleOpenVideo}
        className="btn btn-primary text-white mb-1"
      >
        <VideoLibraryIcon />
      </button>
  */}
      <button
        onClick={handleOpenPdf}
        className="btn btn-primary text-white me-1 ms-1"
      >
        <PictureAsPdfIcon />
      </button>
      {showVideo && (
        <DialogShowVideo
          id="video-content"
          keepMounted
          open={showVideo}
          onClose={handleCloseVideo}
        />
      )}

      {showPdf && (
        <DialogShowPdf
          id="pdf-content"
          keepMounted
          open={showPdf}
          onClose={handleClosePdf}
        />
      )}
    </>
  );
}
