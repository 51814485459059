import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ButtonLink from "widgets/ButtonLinks";

export default function PageUserSignupCompleted() {
  const { state } = useLocation();
  const { role } = state;
  const navigate = useNavigate();

  return (
    <>
      <div className="member-signup-completed">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-8 text-center">
              <div className="row">
                <div className="col-1">
                  <img
                    alt="success"
                    src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/redicons/check-mark-green.png`}
                  />
                </div>
                <div className="col-10">
                  <h3 className="mt-3">Congratulations!</h3>
                  <p>Your setup is completed.</p>
                  <p>
                    You are all set to enjoy the full benifits of our platform{" "}
                  </p>
                  <div className="text-center">
                    <ButtonLink
                      className="btn text-white bg-success btn-primary"
                      to={`/${
                        role == 2 ? "merchant" : "member"
                      }/page/dashboard`}
                    >
                      My dashboard
                    </ButtonLink>

                    <ButtonLink to={"/"} className="btn btn-primary ms-4">
                      BACK TO &nbsp;
                      <span>
                        <img
                          style={{ height: "15px" }}
                          className="img-fluid"
                          src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`}
                          alt="home-icon"
                        />
                      </span>
                    </ButtonLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
