import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ShopSliceProps,
  IObjectKeys,
} from "@/interface/PropTypeCollection";
import type { RootState } from "common/store";
import { callApi } from "adapter/ApiService";

const initialState: ShopSliceProps = {
  loading: false,
  products: [],
  pagination: { limit: 20, total_result: 0, total_page: 0 },
  page: 0,
  sidebar: {
    keywords: "",
    sortby: "name-desc",
    categories: [],
    selectDataMap: {},
  },
};

export const fetchProducts = createAsyncThunk(
  "shop/fetchProducts",
  async (page: number, { getState }) => {
    try {
      const stateInfo = <RootState>getState();
      const { sidebar, pagination } = stateInfo.ShopReducer;
      const { limit } = pagination;
      const { categories, keywords, sortby } = sidebar;
      const response = await callApi("merchant", "get/filter_list", {
        limit,
        page,
        categories: categories.join(","),
        keywords,
        sortby,
      });
      return response;
    } catch (error) {
      return { state: -1 };
    }
  }
);
const ShopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    addCategories: (state, action) => {
      const { l1, l2, l3 } = action.payload;
      const { selectDataMap } = state.sidebar;
      if (l3) {
        if (!state.sidebar.categories.find((el) => el === l3)) {
          const filter = state.sidebar.categories.filter(
            (el) => el !== l1 && el !== l2
          );
          state.sidebar.categories = [...filter, l3];
          if (selectDataMap[l1] && selectDataMap[l1][l2] && selectDataMap[l1][l2]['value']) {
            selectDataMap[l1][l2]['value'] = [...selectDataMap[l1][l2]['value'], l3];
          } else {
            if (selectDataMap[l1] && selectDataMap[l1][l2]) {
              selectDataMap[l1][l2]['value'] = [l3];
            } else if (selectDataMap[l1]) {
              selectDataMap[l1][l2] = { value: [l3] };
            } else {
              selectDataMap[l1] = { [l2]: { value: [l3] } };
            }
          }

          if (selectDataMap[l1]['value']) {
            selectDataMap[l1]['value'] = selectDataMap[l1]['value'].filter(
              (el) => el !== l2
            )
          }

          if (selectDataMap['value']) {
            selectDataMap['value'] = selectDataMap['value'].filter(
              (el) => el !== l1
            )
          }
        }
      } else if (l2) {
        if (!state.sidebar.categories.find((el) => el === l2)) {
          if (selectDataMap[l1] && selectDataMap[l1][l2] && selectDataMap[l1][l2]['value'] && selectDataMap[l1][l2]['value'].length) {
            const filterA = state.sidebar.categories.filter(
              (el) => !selectDataMap[l1][l2]['value'].includes(el)
            );
            const filterB = filterA.filter(
              (el) => el !== l1
            );
            state.sidebar.categories = [...filterB, l2];
            selectDataMap[l1][l2]['value'] = [];
          } else {
            const filter = state.sidebar.categories.filter(
              (el) => el !== l1
            );
            state.sidebar.categories = [...filter, l2];
          }
          if (selectDataMap[l1] && selectDataMap[l1]['value']) {
            selectDataMap[l1]['value'] = [...selectDataMap[l1]['value'], l2];
          } else {
            selectDataMap[l1] = { value: [l2] };
          }

          if (selectDataMap['value']) {
            selectDataMap['value'] = selectDataMap['value'].filter(
              (el) => el !== l1
            )
          }
        }
      } else if (l1) {
        if (!state.sidebar.categories.find((el) => el === l1)) {
          let filterA: Array<string> = state.sidebar.categories;
          if (selectDataMap[l1] && selectDataMap[l1]['value']) {
            filterA = state.sidebar.categories.filter(
              (el) => !selectDataMap[l1]['value'].includes(el)
            );
          }

          if (selectDataMap[l1] && Object.keys(selectDataMap[l1]).length) {
            Object.keys(selectDataMap[l1]).forEach(key => {
              if (key !== 'value') {
                filterA = filterA.filter(
                  (el) => !selectDataMap[l1][key]['value'].includes(el)
                );
                delete (selectDataMap[l1][key]);
              }
            });
          }
          state.sidebar.categories = [...filterA, l1];
          if (selectDataMap[l1] && selectDataMap[l1]['value']) {
            selectDataMap[l1]['value'] = [];
          }
          if (selectDataMap['value']) {
            selectDataMap['value'] = [...selectDataMap['value'], l1]
          } else {
            selectDataMap['value'] = [l1];
          }
        }
      }
    },
    removeCategories: (state, action) => {
      const code = action.payload;
      if (code) {
        const filter = state.sidebar.categories.filter(
          (el) => el !== code
        );
        state.sidebar.categories = [...filter];
      }
    },
    removeAllFilter: (state) => {
      state.sidebar.categories = [];
      state.sidebar.keywords = "";
    },
    setSortby: (state, action) => {
      state.sidebar.sortby = action.payload;
    },
    setKeyword: (state, action) => {
      state.sidebar.keywords = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        const resp = action.payload;
        state.loading = false;
        if (resp.state == 1) {
          const data = resp.data;
          state.page = data.page;
          if (data.page == 0) {
            state.pagination = data.pagination;
            state.products = data.results;
          } else {
            state.products = [...state.products, ...data.results];
          }

        } else {
          //set error message
        }

      });
  },
});

export default ShopSlice.reducer;
export const { addCategories, removeCategories, removeAllFilter, setPage, setKeyword, setSortby } = ShopSlice.actions;
