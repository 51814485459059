import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "common/hooks";
import { setCurPage } from "common/features/PageSetupSlice";
import HeroBanner from "components/home/HeroBanner";
import LaunchNews from "components/home/LaunchNews";
import RecentlyJoinedBanner from "components/home/RecentlyJoinedBanner";
import MakingMoneyMadeEasy from "components/home/MakingMoneyMadeEasy";
import WhyEzGoodie from "components/home/WhyEzGoodie";
import WhyMerchants from "components/home/WhyMerchant";

import WeKeepTheProcessSimple from "components/home/WeKeepTheProcessSimple";
import OurMission from "components/home/OurMission";
import BuildYourBusiness from "components/home/BuildYourBusiness";
import TopDeals from "components/home/TopDeals";
import HomeStickMenu from "widgets/HomeStickMenu";
import { Show401, Show502 } from "widgets/Errors";

const HomePage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const title = `${process.env.REACT_APP_BRAND_NAME}`;
    document.title = title;
    dispatch(setCurPage("home"));
  }, []);

  //console.log("home loaded")
  return (
    <>
      <HeroBanner />
      <RecentlyJoinedBanner />
      <MakingMoneyMadeEasy />
      {/* <WhyEzGoodie /> */}
      <WhyMerchants />
      {/*<WeKeepTheProcessSimple /> */}
      <OurMission />
      <TopDeals />
      {/* <BuildYourBusiness /> */}
      <HomeStickMenu />
    </>
  );
};

export default HomePage;
