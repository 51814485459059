import { createSlice } from "@reduxjs/toolkit";

const MaintenanceSlice = createSlice({
  name: "Maintenance",
  initialState: {
    show: false,
  },
  reducers: {
    showUI: (state) => {
      state.show = true;
    },
  },
});

export default MaintenanceSlice.reducer;
export const { showUI } = MaintenanceSlice.actions;
