import * as React from 'react';
import Helper from "common/libraries/Helper";

export default function MerchantLogo({ logoUri, catSlug }) {
    return (
        <img
            className="ads-brand"
            alt="logo"
            src={Helper.getMerchantLogo(logoUri, catSlug)}
        />
    );
}


