import React from "react";
import ButtonLink from "widgets/ButtonLinks";
import useImagePreloader from "common/hooks/useImagePreloader";
import loader from "common/assets/images/loader.svg";
import { useUserStatus } from "common/hooks";

export default function HeroBanner() {
  const preloadSrcList: string[] = [
    `${process.env.REACT_APP_IMG_HOME_LINK}homepage/images/bannerredthememobile.jpg`,
    `${process.env.REACT_APP_IMG_HOME_LINK}homepage/images/bannerRedTheme.jpg`,
  ];
  const { imagesPreloaded, imgObjList } = useImagePreloader(preloadSrcList);
  const { user } = useUserStatus();

  return (
    <div className="banner">
      <div className="col-lg-12 col-md-12 col-sm-12 position-relative">
        <img
          src={imagesPreloaded ? imgObjList[0].src : loader}
          className="w-100 mobile"
          alt="banner"
        />
        <img
          src={imagesPreloaded ? imgObjList[1].src : loader}
          className="w-100 desktop"
          alt="banner text"
        />
        <div className="banner-button-div">
          {!user && (
            <>
              <ButtonLink
                to={"/signup/member"}
                className="btn btn-primary banner-button mt-4"
              >
                JOIN FOR FREE
              </ButtonLink>

              <ButtonLink
                to={"/signup/merchant"}
                className="btn btn-primary banner-button mt-4 ms-3"
              >
                LIST FOR FREE
              </ButtonLink>
            </>
          )}
          <div className="fs20 text-white mt-5 desktop">
            Shopper and Business Unite: Saving, Success,Synergy
          </div>

          <div className="bg-f8f8f8 fs20 mt-2 mobile">
            Shopper and Business Unite: Saving, Success,Synergy
          </div>
        </div>
      </div>
    </div>
  );
}
