import { useFormContext } from "react-hook-form";
export default function InputFullName({
  classes,
  title = "Full Name",
}: {
  classes?: string | null;
  title?: string;
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={`input-group ${classes}`}>
      <span className="input-group-text fs20">
        {title} <label>*</label>
      </span>
      <input
        type="text"
        autoComplete="full-name"
        className="form-control fs20"
        {...register("full_name", {
          required: "Please enter name.",
        })}
      />
    </div>
  );
}
