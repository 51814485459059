import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  SignFormWrapperProps,
  IndividualDefaultValues,
  GroupDefaultValues,
  IObjectKeys,
} from "common/libraries/interface/PropTypeCollection";
import { useNavigate } from "react-router-dom";
import buildFormValues from "common/libraries/interface/buildFormValues";
import { useEffect, useState } from "react";
import { callApi } from "adapter/ApiService";
import { useAppDispatch, useAppSelector, useUserStatus } from "common/hooks";
import {
  signupWithEmail,
  updateMessage,
  updateStatus,
} from "common/features/UserSignupSlice";
import Helper from "common/libraries/Helper";
import SignupFormWrapper from "./SignupFormWrapper";

export default function MemberFormWrapper({
  children,
  type = "individual",
}: SignFormWrapperProps) {
  const { userRole, user } = useUserStatus();
  const dispatch = useAppDispatch();
  const userSignup = useAppSelector((state) => state.UserSignup);
  const navigate = useNavigate();
  const [defaultValues, setDefaultValues] = useState<
    IndividualDefaultValues | GroupDefaultValues
  >(buildFormValues(type));

  const methods = useForm<IndividualDefaultValues | GroupDefaultValues>({
    defaultValues,
  });

  const {
    handleSubmit,
    setError,
    trigger,
    getValues,
    setValue,
    formState: { errors, touchedFields, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    setDefaultValues(buildFormValues(type));
    if (errors) {
      for (const key in errors) {
        delete errors[key];
      }
    }
  }, [type]);

  const onSubmit = async (
    values: IndividualDefaultValues | GroupDefaultValues | any
  ) => {
    //when form is autofilled
    //trigger();
    dispatch(updateStatus("signup_form_submit"));
    let errorCount = 0;
    const {
      member_type,
      phone,
      email,
      country,
      sponsor_id,
      sponsor_phone,
      sponsor_type,
      sponsor_check,
      full_name,
      title,
      password,
      newsletter,
      group_name,
    } = values;

    /* remove the touchFields case
    if (!touchedFields.phone) {
      const curPhone = getValues("phone");
      const response = await callApi("user", "get/phone_exist", {
        phone: curPhone,
      });
      if (response.state == 1 && !errors['phone']) {
        setError("phone", {
          message: "Phone number is already used by another account.",
        });
        errorCount++;
      }
    }
    if (!touchedFields.email) {
      const curEmail = getValues("email");
      const response = await callApi("user", "get/email_exist", {
        email: curEmail,
      });
      if (response.state == 1 && !errors['email']) {
        errorCount++;
        setError("email", {
          message: "Email is already used by another account.",
        });
      }
    }
    */

    if (!sponsor_check) {
      const response = await callApi("user", "get/sponsor_exist", {
        id: sponsor_type === "member_id" ? sponsor_id : sponsor_phone,
        sponsor_type,
      });
      if (response.state === 0) {
        setError("sponsor_id", {
          message:
            "Oops! It seems that the sponsor's information provided is incorrect or not found in our records. Please double-check the details and ensure they are accurate.",
        });
        errorCount++;
      }
    }

    if (Object.keys(errors).length === 0 && errorCount == 0) {
      //store user
      let memberObject;
      const encrptPwd = Helper.jsEncode(
        password,
        process.env.REACT_APP_ENCRYPT_KEY
      );

      if (type === "individual") {
        memberObject = {
          member_type: "0",
          phone,
          email,
          country,
          sponsor_id: sponsor_id ? sponsor_id : null,
          sponsor_phone: sponsor_phone ? sponsor_phone : null,
          full_name,
          title,
          newsletter,
          group_name: null,
          password: encrptPwd,
        };
      }
      if (type !== "individual") {
        memberObject = {
          member_type: "1",
          phone,
          email,
          country,
          sponsor_id: sponsor_id ? sponsor_id : null,
          sponsor_phone: sponsor_phone ? sponsor_phone : null,
          newsletter,
          group_name: group_name,
          password: encrptPwd,
        };
      }
      // console.log(memberObject);
      // no need for password here
      const response = await callApi("member", "add/account", memberObject);
      if (response.state === 0) {
        dispatch(updateStatus("add_account_failed"));
        dispatch(updateMessage(response.message));
      } else {
        const name = type === "individual" ? full_name : group_name;
        const { sid, uid } = response.data;
        localStorage.setItem(`${process.env.REACT_APP_SIGNUP_SID}`, sid);
        dispatch(signupWithEmail({ name, email, role: member_type, sid, uid }));
      }
      //console.log(values);
    } else {
      dispatch(updateStatus("pending"));
    }
  };

  return (
    <SignupFormWrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
      </FormProvider>
    </SignupFormWrapper>
  );
}
