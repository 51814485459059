import React, { useState, useEffect, ReactNode } from "react";
import AriaModal from "react-aria-modal";
import { ConfirmModalProp } from "@/interface/PropTypeCollection";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

export default function ConfirmModal({
  showModal,
  showWarning = false,
  mdlId = null,
  title = "",
  btnTitleNo = null,
  btnTitleYes = null,
  message,
  handleYes,
  handleNo,
  handleClose,
}: ConfirmModalProp) {
  const onCloseUpModal = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const onClickYes = () => {
    if (handleYes) {
      handleYes();
    }
  };

  const onClickNo = () => {
    if (handleNo) {
      handleNo();
    }
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "450px" },
      }}
      maxWidth="md"
      open={showModal}
    >
      {/*  
      <IconButton
        id="modal-close"
        aria-label="close"
        onClick={() => onCloseUpModal()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    */}
      <DialogContent dividers>
        <div className="dlg-content modal-body text-center">
          {showWarning && (
            <img
              className="dialog-icon-image"
              alt="exclamation icon"
              src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/exclamation-icon-fill.png`}
              style={{ width: "50px", marginBottom: "1rem" }}
            />
          )}
          {title && (
            <div className="fs24 text-uppercase title-border-center">
              {" "}
              {title}
            </div>
          )}
          <div className="dlg-word fs20">{message}</div>
          <button
            id={`${mdlId ?? "mdl-dl-"}btn-yes`}
            onClick={onClickYes}
            type="button"
            className="btn button text-white bg-success text-uppercase me-3"
          >
            {btnTitleYes ?? "Yes"}
          </button>
          <button
            onClick={onClickNo}
            type="button"
            className="btn button btn-secondary text-white bg-danger text-uppercase me-3"
          >
            {btnTitleNo ?? "No"}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
