import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ButtonLink from "widgets/ButtonLinks";
import { useUserStatus, useAppDispatch, useAppSelector } from "common/hooks";
import { logout } from "common/features/UserLoginSlice";
import AccountMenu from "components/navigation/AccountMenu";
import { MenuNavProp } from "common/libraries/interface/PropTypeCollection";

export default function ShopDetailNav() {
    const dispatch = useAppDispatch();
    const { userRole, user, loading, error } = useUserStatus();
    const { curPage } = useAppSelector((state) => state.PageSetup);
    const [showNav, setShowNav] = useState(false);

    const navContent = [
        {
            page: 'shop',
            title: "Shopping",
            link: "/shopping",
        },
        {
            page: 'sign up ',
            title: "Sign up",
            link: "/signup/member",
        },
    ];


    const navMobileContent = [
        {
            page: 'shop',
            title: "Shopping",
            link: "/shopping",
        },
        {
            page: 'join ',
            title: "Join for free",
            link: "/signup/member",
        },
    ];

    useEffect(() => {
        setShowNav(false);
    }, [curPage]);

    const onClickSignout = (e) => {
        e.preventDefault();
        dispatch(logout());
    }

    useEffect(() => {


    }, [userRole, curPage])


    return (
        <>
            <div className="header-menu shopping-detail-header sticky-top bg-grayer-lighter">
                <div className="topborder"> </div>
                <nav className="navbar navbar-expand-lg">
                    <div className="container navbar-main">
                        <div className="header-logo">
                            <Link className="navbar-brand" to="/">
                                <img
                                    src={
                                        process.env.REACT_APP_IMG_HOME_LINK + "logo/logo-white.png"
                                    }
                                    alt="Ez Goodie Logo"
                                />
                            </Link>
                        </div>
                        <button
                            className={`navbar-toggler ${showNav ? "" : "collapsed"}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#mobilenavbar"
                            aria-controls="mobilenavbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => setShowNav((prevState) => !prevState)}
                        >
                            <span
                                className={`${showNav ? "navbar-toggler:not(.collapsed)" : ""
                                    } navbar-toggler-icon`}
                            ></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end">
                            <ul className="navbar-nav">
                                {navContent && navContent.map((el, i) => (
                                    <li className="nav-item" key={`menu-item-${i}`}>
                                        <Link
                                            aria-current="page"
                                            className={`${curPage == el.page ? "active" : ""} nav-link`}
                                            to={el.link}
                                        >
                                            {el.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <AccountMenu page="shopping-detail" />
                        </div>
                    </div>

                    <div
                        className={`${showNav ? "show" : ""
                            } navbar-collapse justify-content-end collapse`}
                        id="mobilenavbar"
                    >
                        <ul className="navbar-nav">
                            {navMobileContent && navMobileContent.map((el, i) => (
                                <li className="nav-item" key={`menu-mobile-item-${i}`}>
                                    <Link
                                        aria-current="page"
                                        className={`${curPage == el.page ? "active" : ""} nav-link`}
                                        to={el.link}
                                    >
                                        {el.title}
                                    </Link>
                                </li>
                            ))}
                            {user && <Link className="nav-link" to="#" onClick={(e) => onClickSignout(e)}>Sign out</Link>}
                        </ul>
                        {!user && <div className="d-grid">
                            <ButtonLink
                                to="/signup/member"
                                className="btn btn-primary header-button"
                            >
                                JOIN FOR FREE
                            </ButtonLink>
                        </div>}
                    </div>
                </nav>
            </div>
        </>
    );
}
