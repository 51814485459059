import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IObjectKeys,
  UserSignupState,
  UserSignupData,
} from "@/interface/PropTypeCollection";
import type { RootState } from "common/store";
import { auth } from "common/config/firebase";
import { sendSignInLinkToEmail } from "firebase/auth";
import { callApi } from "adapter/ApiService";
import Helper from "common/libraries/Helper";

const initialState: UserSignupState = {
  status: "pending",
  step: 1,
  message: "",
  uid: null,
  sid: null,
  email: "",
  phone: "",
  role: 1,
  password: null,
  userInfo: null,
};

export const getUserSignupInfoBySid = createAsyncThunk(
  "UserSignup/getUserSignupInfoBySid",
  async ({ sid }: { sid: string | null }) => {
    try {
      const response = await callApi("user", "get/account_infor_by_sid", {
        sid,
      });
      //console.log(resSendemail);
      return response;
    } catch (error: any) {
      Helper.appLog("Signup slice:getUserSignupInfoBySid", error);
      return { state: 0 };
    }
  }
);

export const syncFirebaseAccountStatus = createAsyncThunk(
  "UserSignup/syncAccountStatus",
  async ({ accStatus }: { accStatus: number }, { getState }) => {
    try {
      const stateInfo = <RootState>getState();
      const { email, userInfo } = <IObjectKeys>stateInfo.UserSignup;
      const { uid } = userInfo;
      const response = await callApi("user", "update/account_auth", {
        auth_uid: uid,
        email,
        acct_state: accStatus,
      });
      //console.log(resSendemail);
      return response;
    } catch (error: any) {
      console.error(error);
      return { state: 0 };
    }
  }
);

export const reSendEmailLink = createAsyncThunk(
  "UserSignup/reSendEmailLink",
  async ({ sid, email }: { sid: string; email: string }) => {
    try {
      const forwardUrl = `${process.env.REACT_APP_VERIFY_PHONE}?sid=${sid}&email=${email}`;
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: forwardUrl,
        // This must be true.
        handleCodeInApp: true,
      };
      const resSendemail = await sendSignInLinkToEmail(
        auth,
        email,
        actionCodeSettings
      );
      Helper.appLog("resend email", "link re-sent");
      return { state: 1, data: { email } };
      //console.log(resSendemail);
    } catch (error: any) {
      console.error(error);
      return { state: 0 };
    }
  }
);

export const signupWithEmail = createAsyncThunk(
  "UserSignup/signupWithEmail",
  async (signupCredentials: UserSignupData, { getState }) => {
    try {
      const state = getState();
      const { name, email, sid, uid } = signupCredentials;
      const forwardUrl = `${process.env.REACT_APP_VERIFY_PHONE}?sid=${sid}&email=${email}`;
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: forwardUrl,
        // This must be true.
        handleCodeInApp: true,
      };
      const resSendemail = await sendSignInLinkToEmail(
        auth,
        email,
        actionCodeSettings
      );
      Helper.appLog("UserSignup/signupWithEmail", "link sent");
      return { state: 1, data: { name, email, sid, uid } };
    } catch (error: any) {
      console.error(error);
      return { state: 0 };
    }
  }
);

export const UserSignupSlice = createSlice({
  name: "UserSignupResult",
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
    updateStep: (state, action) => {
      state.step = action.payload;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updatePhone: (state, action) => {
      state.phone = action.payload;
      state.step = 3;
    },
    updateSid: (state, action) => {
      state.sid = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupWithEmail.fulfilled, (state, action) => {
        const resp: IObjectKeys = action.payload;
        if (resp && resp.state == 1) {
          const {
            data: { sid, email, uid },
          } = resp;
          state.sid = sid;
          state.email = email;
          state.uid = uid;
          state.step = 2;
        } else {
          state.status = "failed";
        }
      })
      .addCase(reSendEmailLink.fulfilled, (state, action) => {
        const resp: IObjectKeys = action.payload;
        if (resp && resp.state == 1) {
          state.status = "email_resend";
          const { email } = resp.data;
          state.email = email;
          state.step = 2;
        } else {
          state.status = "failed";
        }
      })
      .addCase(getUserSignupInfoBySid.fulfilled, (state, action) => {
        const resp: IObjectKeys = action.payload;
        if (resp && resp.state == 1) {
          const { email, sid, uid, phone, role, encrypt_password, acct_state } =
            resp.data;
          state.email = email;
          state.sid = sid;
          state.uid = uid;
          state.phone = phone;
          state.role = role;
          state.password = encrypt_password;
          if (acct_state == 1) {
            state.status = "done";
          }
        } else {
          state.status = "account_not_found";
        }
      })
      .addCase(syncFirebaseAccountStatus.fulfilled, (state, action) => {
        const resp: IObjectKeys = action.payload;
        if (resp && resp.state == 1) {
          state.status = "done";
          state.step = 4;
        } else {
          state.status = "sync_account_failed";
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateMessage,
  updateStep,
  updateStatus,
  updateEmail,
  updatePhone,
  updateSid,
  setRole,
  updateUserInfo,
} = UserSignupSlice.actions;

export default UserSignupSlice.reducer;
