import {
    useState,
    useEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "common/hooks";
import {
    updateCashierStatus,
    updateCashier
} from "common/features/CashierSlice";
import SigninCashierId from 'components/form/signin/CashierId';
import { setCurPage } from "common/features/PageSetupSlice";
import { ModalLoader } from "widgets/ShowLoader";

export default function PageCashierLogin() {
    const cashierInfo = useAppSelector((state) => state.CashierResult);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const { isAuth, status, message } = cashierInfo;

    useEffect(() => {
        document.title = `${process.env.REACT_APP_BRAND_NAME}-Cashier-Signin`;
        const loginStorage = window.localStorage.getItem(`${process.env.REACT_APP_CASHIER_LOGIN_KEY}`);
        if (loginStorage && loginStorage.length > 0 && loginStorage != "undefined") {
            const authInfo = JSON.parse(loginStorage);
            dispatch(updateCashier(authInfo));
        } else {
            dispatch(updateCashierStatus({ status: 'idle', message: "" }));
            dispatch(setCurPage("signin"));
        }
    }, [])

    useEffect(() => {
        if (isAuth) {
            navigate(`/cashier/page/dashboard`);
        }
    }, [isAuth]);

    return (
        <div className="member-signup-form signin-form">
            {status == 'pending' && <ModalLoader />}
            <div className="container">
                <h3 className="">Cashier Log in</h3>
                <div className={status === 'pending' ? 'disabled-div' : ''}>
                    <SigninCashierId />
                </div>
            </div>
        </div>
    );
}
