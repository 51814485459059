import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export default function BorderBox({ children }) {
    return (
        <Box>
            <Paper className="p-4 m-1" >
                {children}
            </Paper>
        </Box>
    );
};

export function BorderSmallBox({ children }) {
    return (
        <Box sx={{ padding: '5px' }}>
            <Paper className="m-1" >
                {children}
            </Paper>
        </Box>
    );
};

export function BorderSmallBackgroundBox({ children }) {
    return (
        <Box sx={{ padding: '5px', background: 'rgba(55, 111, 208, 0.125)' }}>
            <Paper className="m-1" >
                {children}
            </Paper>
        </Box>
    );
};