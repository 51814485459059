import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { callApi } from "adapter/ApiService";

export default function TblComissionChart({ currentRateGrade = 10 }) {
  const [rateList, setRateList] = useState([
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
  ]);
  const getSystemComissionRateTbl = async () => {
    const response = await callApi("site", "get/comission_rate_tbl", []);
    if (response.state === 1) {
      setRateList([...response.data]);
    } else {
    }
  };
  useEffect(() => {
    getSystemComissionRateTbl();
  }, []);

  return (
    <table
      className="table border text-center text-nowrap mt-4"
      style={{ width: "800px" }}
    >
      <thead>
        <tr className="fs20">
          <th className="border" style={{ width: "16%" }}>
            Levels
          </th>
          <th
            className={`border ${currentRateGrade == 0 ? "bg-warning" : ""}`}
            style={{ width: "16%" }}
          >
            $0 -<br />
            $99.99
          </th>
          <th
            className={`border ${currentRateGrade == 1 ? "bg-warning" : ""}`}
            style={{ width: "16%" }}
          >
            $100 - <br />
            $199.99
          </th>
          <th
            className={`border ${currentRateGrade == 2 ? "bg-warning" : ""}`}
            style={{ width: "16%" }}
          >
            $200 -<br />
            $399.99
          </th>
          <th
            className={`border ${currentRateGrade == 3 ? "bg-warning" : ""}`}
            style={{ width: "16%" }}
          >
            $400-
            <br />
            $599.99
          </th>
          <th
            className={`border ${currentRateGrade == 4 ? "bg-warning" : ""}`}
            style={{ width: "16%" }}
          >
            $600+
          </th>
        </tr>
      </thead>
      <tbody>
        {rateList.map((rateArray, index) => (
          <tr key={`tbl-calc-${index}`}>
            <td className={`px-1 py-2 border`}>{`Level ${index + 1}`}</td>
            <td
              className={`px-1 py-2 border ${
                currentRateGrade == 0 ? "bg-warning" : ""
              }`}
            >{`${rateArray[0]}`}</td>
            <td
              className={`px-1 py-2 border ${
                currentRateGrade == 1 ? "bg-warning" : ""
              }`}
            >{`${rateArray[1]}`}</td>
            <td
              className={`px-1 py-2 border ${
                currentRateGrade == 2 ? "bg-warning" : ""
              }`}
            >{`${rateArray[2]}`}</td>
            <td
              className={`px-1 py-2 border ${
                currentRateGrade == 3 ? "bg-warning" : ""
              }`}
            >{`${rateArray[3]}`}</td>
            <td
              className={`px-1 py-2 border ${
                currentRateGrade == 4 ? "bg-warning" : ""
              }`}
            >{`${rateArray[4]}`}</td>
          </tr>
        ))}
        <tr>
          <td className="px-1 py-2 border"></td>
          <td
            className={`px-1 py-2 border ${
              currentRateGrade == 0 ? "bg-warning" : ""
            }`}
          >
            Grade 0{" "}
          </td>
          <td
            className={`px-1 py-2 border ${
              currentRateGrade == 1 ? "bg-warning" : ""
            }`}
          >
            Grade 1
          </td>
          <td
            className={`px-1 py-2 border ${
              currentRateGrade == 2 ? "bg-warning" : ""
            }`}
          >
            Grade 2
          </td>
          <td
            className={`px-1 py-2 border ${
              currentRateGrade == 3 ? "bg-warning" : ""
            }`}
          >
            Grade 3
          </td>
          <td
            className={`px-1 py-2 border ${
              currentRateGrade == 4 ? "bg-warning" : ""
            }`}
          >
            Grade 4
          </td>
        </tr>
      </tbody>
    </table>
  );
}
