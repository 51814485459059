import { Fragment, useState, useEffect } from "react";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { FormProvider, useForm, useWatch } from "react-hook-form";
import FormErrors from "components/form/FormErrors";
import InputPhone from "components/inputs/InputPhone";
import InputEmail from "components/inputs/InputEmail";
import InputFullName from "components/inputs/InputFullName";
import { callApi } from "adapter/ApiService";
import { ShowFormOkMsg, ShowFormErrorMsg } from "widgets/ShowFormMsg";
import { Show501 } from "widgets/Errors";
import BorderBox from "widgets/BorderBox";

export default function PageCustomerService() {
    const method = useForm();
    const { register, handleSubmit, watch, getValues, formState: { errors } } = method;
    const [formErro, setFormError] = useState<boolean | null>(null);
    const handleVerify = () => {

    }

    const onSubmit = async () => {
        const values = getValues();
        console.log("Customer service form >>", values);
        const response = await callApi("site", "add/customer_service_msg", values);
        if (response.state === 1) {
            setFormError(true);
        } else {
            setFormError(false);
        }
    }

    return <Fragment>
        <div className="customer-support-form">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <BorderBox>
                            <div className="forms">
                                <h3 className="fs28">Customer Support</h3>
                                <GoogleReCaptchaProvider reCaptchaKey={`${process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}`}>
                                    <FormProvider {...method} >
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="col-12">
                                                <InputFullName />
                                                <InputEmail verify={false} />
                                                <InputPhone verify={false} title="Contact Phone" />
                                                <div className="input-group">
                                                    <span className="input-group-text fs20">Subject</span>
                                                    <input type="text" {...register("subject", {
                                                        required: "Please type subject",
                                                    })} className="form-control fs20" />
                                                </div>
                                                <div className="input-group">
                                                    <textarea {...register("message", {
                                                        required: "Please type the detail message",
                                                    })} className="form-control fs20" id="" rows={5}></textarea>
                                                </div>
                                            </div>
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-12">
                                                    <FormErrors />
                                                    {formErro === false && <ShowFormErrorMsg message="Sorry, your information not submited correctly, please try again later" />}
                                                    {formErro === true && <ShowFormOkMsg message="Thanks for your feedback, we will contact you later once we have reviewed your information" />}
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="d-grid mb-4">
                                                        <button type="submit" className="btn btn-primary">SUBMIT</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </FormProvider>
                                    <GoogleReCaptcha onVerify={handleVerify} />
                                </GoogleReCaptchaProvider>
                            </div>

                        </BorderBox>
                    </div>
                </div>
            </div>
        </div>
    </Fragment >
}