import React from "react";
import { ButtonLinkProps } from "common/libraries/interface/PropTypeCollection";
import { Link } from "react-router-dom";

export default function ButtonLink({ children, className, to, ...other }: ButtonLinkProps) {
  return (
    <Link className={className} to={to} {...other} >
      {children}
    </Link>
  );
}
