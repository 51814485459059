import { useState, useEffect } from "react";
import {
    IObjectKeys,
} from "@/interface/PropTypeCollection";
import Rating from '@mui/material/Rating';
import ReviewInput from "./ReviewInput";
import { callApi } from "adapter/ApiService";
import { Alert } from "widgets/ShowFormMsg";
import ShowLoader from "widgets/ShowLoader";
import Helper from "common/libraries/Helper";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { pink, blue, green } from '@mui/material/colors';
import { useAppSelector, useAppDispatch, useUserStatus } from "common/hooks";
import { loadMemberDetail } from "common/features/MemberSlice";
import { styled } from '@mui/material/styles';
import moment from 'moment';

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        right: -5,
        top: 13,
        border: `2px solid `,
        padding: '0 4px',
    },
}));
export default function ReviewDisplay({ merchantId }) {
    const { profileData } = useAppSelector((state) => state.MemberResult);
    const dispatch = useAppDispatch();
    const { user, userRole } = useUserStatus();
    const [showReviewInput, setShowReviewInput] = useState(false);
    const [list, setList] = useState<IObjectKeys[]>([]);
    const [summary, setSummary] = useState<IObjectKeys | null>(null);
    const [submitOk, setSubmitOK] = useState<boolean | null>(null);

    const onToggleShowReviewInput = () => {
        setShowReviewInput(!showReviewInput);
        setSubmitOK(null);
    }

    const onHandleSubmitComment = (result) => {
        setShowReviewInput(false);
        setList([...list, result]);
        setSubmitOK(true);
    }

    const checkUserState = async () => {
        if (user && [1, 3].includes(userRole)) {
            const { uid } = user;
            dispatch(loadMemberDetail({ uid }));
        }
    }

    useEffect(() => {
        checkUserState();
    }, [user])

    useEffect(() => {
        const loadReviewList = async () => {
            const postData = {
                merchant_id: merchantId,
            };
            const response = await callApi("review", "get/review_content", postData);
            if (response.state === 1) {
                setList(response.data['list']);
                setSummary(response.data['summary']);
            } else {

            }
        }
        loadReviewList();
    }, []);

    const onHandleThump = async (opt, commentId) => {
        const postData = {
            comment_id: commentId,
            merchant_id: merchantId,
            operation: opt,
        };
        if (profileData) {
            const { member_id } = profileData.profile_detail;
            postData['member_id'] = member_id;
        }
        const response = await callApi("review", "add/comment_support", postData);
        if (response.state === 1) {
            const updateItem = list.find((item) => item['id'] == commentId);
            if (updateItem) {
                updateItem['agree_count'] = response.data['agree_count'];
                updateItem['disagree_count'] = response.data['disagree_count'];
                setList([...list]);
            }
            // setSummary(response.data['summary']);
        } else {

        }
    }

    return (
        <>
            <div className="newcard mt-5 mb-5" id="store-review">
                <div className="row">
                    {!summary && <ShowLoader />}
                    <div className="col-12 col-lg-3 col-md-6 col-sm-12 pb-1">
                        <h6 className="fs24">REVIEWS</h6>
                        {summary && summary['total_review'] > 0 && <>
                            <div className="review-number fs50">{summary['overal_rating']}</div>
                            <Rating value={parseFloat(summary['overal_rating'])} precision={0.5} size="large" readOnly />
                            <p className="fs20">Total {Helper.number_format(summary['total_review'], 0)} Reviews</p>
                            {/* <p className="mt-2 fs20">90% Positive Reviews In The Past 3 Months</p> */}
                            {summary['rating_group'].map((item) => <div key={`comment-${item['rating']}`} className="d-flex">
                                <Rating value={parseFloat(item['rating'])} size="large" precision={0.5} readOnly />
                                <p className="mb-0 fs20">{Helper.number_format(item['number'], 0)} Reviews</p>
                            </div>)}
                        </>
                        }
                        {summary && summary['total_review'] === 0 && <p>No Review yet!</p>}
                    </div>
                    <div className="col-12 col-lg-9 col-md-12 col-sm-12 border-left">

                        <h6 className="fs24">Review Details</h6>
                        {list && list.length > 0 && list.map((item) => <div key={`comment-${item['id']}`} className="ratingbox">
                            <img alt="user icon" src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/redicons/user-icon.png`} />
                            <div>
                                <p className="mb-0">{item['user_name']}</p>
                                <p className="mb-0 fs20"><Rating name="" precision={0.5} value={parseFloat(item['rating'])} readOnly /> {item['title']}</p>
                                <p className="mb-0">Reviewd on {moment(item['submit_datetime']).format("MMMM Do YYYY")}</p>
                                <p className="mb-0 fs18">{item['comments']}</p>
                                <div className="row">
                                    <div className="col-2">
                                        {item['agree_count'] > 0 ?
                                            <StyledBadge badgeContent={item['agree_count']} className="" color="success">
                                                <ThumbUpAltIcon
                                                    className="pointer"
                                                    onClick={() => onHandleThump(1, item['id'])}
                                                    sx={{ color: blue[500], cursor: 'pointer' }}
                                                />
                                            </StyledBadge>
                                            : <ThumbUpAltIcon
                                                className="pointer"
                                                onClick={() => onHandleThump(1, item['id'])}
                                                sx={{ color: blue[500], cursor: 'pointer' }}
                                            />
                                        }
                                    </div>
                                    <div className="col-2">
                                        {item['disagree_count'] > 0 ?
                                            <StyledBadge className="" badgeContent={item['disagree_count']} color="secondary">
                                                <ThumbDownAltIcon
                                                    className="pointer"
                                                    onClick={() => onHandleThump(0, item['id'])}
                                                    sx={{ color: pink[500], cursor: 'pointer' }}
                                                />
                                            </StyledBadge>
                                            : <ThumbDownAltIcon
                                                className="pointer"
                                                onClick={() => onHandleThump(0, item['id'])}
                                                sx={{ color: pink[500], cursor: 'pointer' }}
                                            />}
                                    </div>
                                </div>
                            </div>
                        </div>)
                        }

                        <div className="mt-3 d-flex d-grid gap-2 pb-1">
                            <button onClick={onToggleShowReviewInput} className="btn btn-secondary w-100" type="button">WRITE A REVIEW</button>
                            {summary && summary['comment_count'] > 5 && <button className="btn btn-secondary w-100" type="button">VIEW MORE</button>}
                        </div>
                        {submitOk && <Alert severity="success">Thanks for your comments,your feedback has been submitted successfully!</Alert>}
                        {showReviewInput && <ReviewInput onSubmit={onHandleSubmitComment} merchantId={merchantId} />}

                    </div>
                </div>
            </div>
        </>
    );
}
