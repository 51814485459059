import React from "react";

export default function OurMission() {
  return (
    <>
      <div className="bg-white our-mission pt-2">
        <div className="container">
          <h3 className="fs32">Our Mission:</h3>
          <p className="text-center fs28">
            Our mission is to create a thriving ecosystem, to drive prosperity
            <br />
            by fostering a mutually beneficial relationship between shoppers and
            merchants.
            <br />
            Shoppers enjoy discounted prices, cash rewards and earning
            potential,
            <br />
            while merchants witness business expansion and higher customer
            engagement <br />
          </p>
        </div>
      </div>
    </>
  );
}
