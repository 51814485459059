import {
  useState,
  useEffect,
} from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { auth } from "../../common/config/firebase";
import { useAppSelector, useAppDispatch } from "../../common/hooks";
import { getUserSignupInfoBySid, updateStatus, updateStep, updateSid } from "../../common/features/UserSignupSlice";
import { isSignInWithEmailLink } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import SignUpVerifyPhone from "../../components/form/signup/VerifyPhone";
import SignUpReEditPhone from "../../components/form/signup/ReEditPhone";
import { callApi } from "adapter/ApiService";
import ShowLoader from "widgets/ShowLoader";
import { Show403 } from "widgets/Errors";
import { appLog } from "common/libraries/Helper";

export default function PageUserVerifyPhone() {
  const [user] = useAuthState(auth);
  const userSignup = useAppSelector((state) => state.UserSignup);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const sid = searchParams.get('sid');
  const emailInLink = searchParams.get('email');
  const { status, email, step } = userSignup;
  const [emailMatched, setEmailMatched] = useState<boolean | null>(null);
  /*

  */

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(updateStatus('pending'));
    document.title = `${process.env.REACT_APP_BRAND_NAME}-verify-phone`;
    if (!user) {
      dispatch(updateSid(sid));
      dispatch(updateStatus("phone"));
      dispatch(updateStep(3));
    } else {
      //console.log(user);
    }
  }, []);



  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      dispatch(getUserSignupInfoBySid({ sid }));
      //console.log("email verfied");
    } else {
      appLog("email link expired!");
    }
  }, [sid]);

  useEffect(() => {
    const updateEmailVerifiedState = async () => {
      await callApi("user", "update/account_state", { sid, acct_state: 0 });
    }
    if (email) {
      if (email == emailInLink) {
        updateEmailVerifiedState();
        setEmailMatched(true);
      } else {
        setEmailMatched(false);
      }
    }
  }, [email])

  return (
    <div id="step-3" className="container">

      {status == "failed" &&
        <Show403 title="!" message="Sorry, some exception happened during your account setup, please contact our customer service for help!" />
      }
      {emailMatched === null && status !== "account_not_found" ? <ShowLoader /> :
        (emailMatched ?
          <>
            {step == 3 ? <SignUpVerifyPhone />
              : <SignUpReEditPhone />
            }
          </> :
          status !== "account_not_found" ?
            <Show403 title="!" message="Sorry, We have detected your email address has been updated, please use the correct email verification link !" />
            : <></>

        )
      }
    </div>
  );
}
