import { forwardRef, useEffect, useState } from "react";
import { InputProps } from "common/libraries/interface/PropTypeCollection";

function format(value: string) {
  if (value && value.length && value.substring(0, 2) == "+1") {
    value = value.substring(2);
  }
  const raw = (value || "").replace(/\D/g, "").substring(0, 10);
  const len = raw.length;
  if (len === 0) {
    return raw;
  } else if (len < 4) {
    return `(${raw}`;
  } else if (len < 7) {
    return `(${raw.substring(0, 3)}) ${raw.substring(3, 6)}`;
  } else {
    return `(${raw.substring(0, 3)}) ${raw.substring(3, 6)}-${raw.substring(
      6,
      10
    )}`;
  }
}
function toValue(formatted: string) {
  const raw = formatted.replace(/\D/g, "").substring(0, 10);
  return `+1${raw}`;
}

const FormattingPhoneInput = forwardRef<HTMLInputElement, InputProps>(
  ({ value, onChange, onBlur, ...other }, ref) => {
    const [isTouched, setIsTouched] = useState(false);
    const [formatted, setFormatted] = useState(value ? format(value) : "");
    useEffect(() => {
      if (!isTouched && value && value.length > 0) {
        setFormatted(() => format(value));
      }
    }, [isTouched, value]);

    return (
      <input
        {...other}
        type="tel"
        inputMode="tel"
        autoComplete="tel-national"
        className="form-control fs20"
        maxLength={14}
        ref={ref}
        value={formatted}
        onBlur={onBlur}
        onChange={(e) => {
          setIsTouched(true);
          const f = format(e.target.value);
          setFormatted(f);
          if (onChange) {
            onChange(toValue(f));
          }
        }}
      />
    );
  }
);

FormattingPhoneInput.displayName = "FormattingPhoneInput";

export default FormattingPhoneInput;
