import { useFormContext } from "react-hook-form";
import { OnChange } from "common/libraries/interface/PropTypeCollection";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
export default function SelectMemberType({ onChange }: OnChange) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const params = useParams();
  const { memberSid } = params;

  return (
    <div className="row justify-content-center">
      <div className="member-type">
        <div className="input-group mb-3">
          <span className="input-group-text justify-content-center fs20">
            Member Type<label>*</label> :
          </span>
          <select
            id="member-type-select"
            defaultValue={"individual"}
            className="form-select text-center fs20"
            {...register("membership_type", {
              required: true,
              onChange: (e) => {
                onChange(e.target.value);
              },
            })}
          >
            <option value="individual">Individual</option>
            <option value="group">Group</option>
          </select>
        </div>
        <p className="text-center fs20">
          <Link to={`/signup/merchant${memberSid ? `/${memberSid}` : ""}`}>
            Switch to Merchant Signup
          </Link>
        </p>
      </div>
    </div>
  );
}
