import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { callApi } from "adapter/ApiService";
import { appLog } from "common/libraries/Helper";
import { InputProps } from "common/libraries/interface/PropTypeCollection";

export default function InputEmail({
  verify = false,
  sign_role = 1,
  required = true,
}: InputProps) {
  const {
    register,
    trigger,
    getValues,
    setValue,
    setError,
    formState: { touchedFields, dirtyFields, errors },
  } = useFormContext();

  const params = useParams();
  const { referCode, adminInviteSpecialCode } = params;
  const [defaultEmail, setDefaultEmail] = useState("");

  useEffect(() => {
    const getMemberIdByRefer = async () => {
      const response = await callApi("member", "get/refer_info_by_code", {
        refer_code: referCode,
      });
      if (response.state == 1) {
        const email = response.data["for_email"];
        setDefaultEmail(email);
        setValue("email", email);
      }
    };
    if (referCode && referCode.length > 0) {
      appLog("member-from-a-friend:slug", referCode);
      getMemberIdByRefer();
    }
  }, [referCode]);

  useEffect(() => {
    const getAdminReferInfo = async () => {
      const response = await callApi("admin", "get/invite_merchant_reference", {
        refer_code: adminInviteSpecialCode,
      });
      if (response.state == 1) {
        const email = response.data["for_email"];
        setDefaultEmail(email);
        setValue("email", email);
      }
    };
    if (adminInviteSpecialCode && adminInviteSpecialCode.length > 0) {
      console.log("admin-invite-a-merchant:slug >>", adminInviteSpecialCode);
      getAdminReferInfo();
    }
  }, [adminInviteSpecialCode]);

  const checkEmailExist = async () => {
    const value = getValues("email");
    const response = await callApi("user", "get/email_exist", {
      email: value,
    });
    //console.log(response);
    if (response.state === 1) {
      const { role } = response.data;
      if (role == 3 || role == sign_role) {
        setError("email", {
          type: "custom",
          message:
            "Oops! Looks like that email address is already registered by another member. Please try using a different one or contact our support for help. Thank you!",
        });
      } else {
      }
    }
  };

  const onBlurEmail = () => {
    if (verify) {
      const email = getValues("email");
      if (email && email.length > 0) {
        trigger("email");
        setTimeout(() => {
          if (
            (dirtyFields["email"] || touchedFields["email"]) &&
            !errors["email"]
          ) {
            checkEmailExist();
          }
        }, 100);
      }
    }
  };

  const validEmail = async (email) => {
    const pattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email && email.length > 0) {
      const check = pattern.test(email);
      if (!check) {
        return "Please type valide email address";
      }
      if (verify) {
        const response = await callApi("user", "get/email_exist", {
          email,
        });
        //console.log(response);
        if (response.state === 1) {
          const { role } = response.data;
          if (role == 3 || role == sign_role) {
            return "Sorry, this email address has been used by another account!";
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    return "Please enter email address";
  };

  return (
    <div className="input-group">
      <span className="input-group-text fs20">
        Email <label>*</label>
      </span>
      <input
        type="email"
        disabled={defaultEmail ? true : false}
        className="form-control fs20"
        {...register("email", {
          required: required ? "Please enter email address." : false,
          validate: validEmail,
          pattern: {
            value:
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: "Please enter a valid email.",
          },
          // onBlur: () => onBlurEmail(),
        })}
      />
    </div>
  );
}
