import React from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import { useNavigate } from "react-router-dom";
import { DealProp } from "common/libraries/interface/PropTypeCollection";
import { Link } from "react-router-dom";
import noLogo from 'common/assets/images/caseno/merchant_logo.png';
export default function Deal({ details }: { details: DealProp }) {
  const navigate = useNavigate();
  const { slug, expiry, company, cat_name, offer, logo_uri, cash_back } = details;
  return (<Card>
    <Link to={`/shopping/${slug}`}>
      <CardMedia
        component="img"
        height="150"
        image={logo_uri ? `${process.env.REACT_APP_S3_URL}${logo_uri}` : noLogo}
        alt={company}
      />
    </Link>
    <CardHeader
      title={company}
      subheader={cat_name}
      className="text-center"
    />
    <CardContent className="text-center">
      <p className="offer">{`Up to ${offer}% commission for the purchase`}</p>
      <p className="cashback text-primary">{`${(parseFloat(cash_back) * 100).toFixed(2)}%`} Cash back</p>
      <p className="text-center"><Link to={`/shopping/${slug}`}><span className="btn btn-primary btn-block">SHOP NOW</span></Link></p>
    </CardContent>
  </Card>
  );
}
