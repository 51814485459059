import React, { useState, useEffect } from "react";
import { ModalLoader } from "widgets/ShowLoader";
import { callApi } from "adapter/ApiService";

export default function Maintenance({ onHandlePassed }: { onHandlePassed?: Function }) {
  const accessPermitKey = process.env.REACT_APP_MAINTENANCE_PASSWORD;
  const needVerify = parseInt(`${process.env.REACT_APP_MAINTENANCE_REQUIRED}`);
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const { pathname } = window.location;
  const pathSplit = pathname.replace(/^\/+/, '').replace(/\/+$/, '').split("/");

  let a = '', b = '';
  if (pathSplit[0] == 'signup') {
    if (pathSplit.length === 5 && pathSplit[1] === 'admin' && pathSplit[2] === 'invite') {
      if (pathSplit[3] === 'merchant') {
        a = pathSplit[4];
      } else if (pathSplit[3] === 'to-merchant') {
        b = pathSplit[4];
      }
    }
  }

  const [adminInviteMerchantCode] = useState(a);
  const [adminInviteSpecialCode] = useState(b);


  const checkPassword = (passwordInput: string) => {
    if (passwordInput === accessPermitKey) {
      window.localStorage.setItem(`${process.env.REACT_APP_MAINTENANCE_CHECK_ITEM}`, accessPermitKey);
      if (onHandlePassed) {
        onHandlePassed();
      }
    }
  };

  const handleChange = (password: string) => {
    setPassword(password);
    checkPassword(password);
  };

  useEffect(() => {
    if (needVerify) {
      if (adminInviteSpecialCode && adminInviteSpecialCode.length > 0) {
        setLoader(true);
        const checkAdminCode = async () => {
          const data = { refer_code: adminInviteSpecialCode }
          const response = await callApi("admin", "get/invite_merchant_reference", data);
          if (response.state == 1) {
            window.localStorage.setItem(`${process.env.REACT_APP_MAINTENANCE_CHECK_ITEM}`, adminInviteSpecialCode);
            if (onHandlePassed) {
              onHandlePassed();
            }
          }
          setLoader(false);
        }
        checkAdminCode();
      }
    }

  }, [adminInviteSpecialCode]);

  useEffect(() => {
    if (needVerify) {
      if (adminInviteMerchantCode && adminInviteMerchantCode == `${process.env.REACT_APP_ADMIN_INVITE_MERCHANT_CODE}`) {
        setLoader(true);
        window.localStorage.setItem(`${process.env.REACT_APP_MAINTENANCE_CHECK_ITEM}`, adminInviteMerchantCode);
        if (onHandlePassed) {
          onHandlePassed();
        }
        setLoader(false);
      }
    }

  }, [adminInviteMerchantCode]);


  return (
    <div className="site-access-container">
      <div className="container text-center">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-6 col-sm-12 site-access-content">
            <img
              src={
                process.env.REACT_APP_IMG_HOME_LINK + "logo/logo-white.png"
              }
              alt="Ez Goodie Logo" className="img-fluid"
            />
            <h3 className="text-uppercase text-primary mt-5">site access login</h3>
            {loader === false && <div className="input-group">
              <span className="input-group-text">Password<label>*</label></span>
              <input required={true} type="password" name="password" value={password}
                onChange={(e) => handleChange(e.target.value)} className="form-control"
              />
            </div>
            }
            {loader === true && <ModalLoader />}
          </div>
        </div>
      </div>
    </div>
  );
}
