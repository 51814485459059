import React, { useState, useEffect, useMemo } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { ConfirmationDialogRawProps } from "common/libraries/interface/PropTypeCollection";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FormErrors from "components/form/FormErrors";
import Helper from "common/libraries/Helper";
import { callApi } from "adapter/ApiService";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import InputAmount from "components/inputs/InputAmount";
import InputNumber from "components/inputs/InputNumber";
import DialogChart from "./DialogComissionChart";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function DialogCalculator(props: ConfirmationDialogRawProps) {
  const { onClose, memberId, open, ...other } = props;

  const [openChart, setOpenChart] = useState(false);
  const handleOpenChart = () => {
    setOpenChart(true);
  };
  const handleCloseChart = () => {
    setOpenChart(false);
  };

  const [calMode, setCalMode] = useState(memberId ? "1" : "0"); //0: assumption 1: real
  const [rateList, setRateList] = useState(["0", "0", "0", "0", "0"]);
  const [cashbackList, setCashbackList] = useState(["0", "0", "0", "0", "0"]);
  const [comissionList, setComissionList] = useState(["0", "0", "0", "0", "0"]);
  const [totalMember, setTotalMember] = useState(0);
  const [totalComssion, setTotalComission] = useState("0");
  const [totalCashback, setTotalCashback] = useState("0");

  const defaultValues = {
    lmemcnt_1: 10,
    lmemcnt_2: 10,
    lmemcnt_3: 10,
    lmemcnt_4: 10,
    lmemcnt_5: 10,
    sales_volumn: "80",
    average_cashback: "10",
  };
  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    setError,
    trigger,
    reset,
    getValues,
    setValue,
    formState: { errors, touchedFields, isSubmitSuccessful },
  } = methods;

  const handleEntering = () => {};
  const handleClose = () => {
    onClose();
  };

  const onSubmit = async () => {
    trigger();
    if (Object.keys(errors).length === 0) {
    }
  };

  const handleOnBlurSalevolumn = async () => {
    const amount = Helper.getAmountValue(getValues("sales_volumn"));
    const data = { amount };
    const response = await callApi(
      "member",
      "get/commission_rate_by_amount",
      data
    );
    if (response.state === 1) {
      const newRate: string[] = [...response.data];
      setRateList([...response.data]);
      calculate(newRate);
    } else {
    }
  };

  const calculate = (newRate: string[] = []) => {
    let calcRatelist = rateList;
    if (newRate && newRate.length) {
      calcRatelist = newRate;
    }
    const average = Helper.getAmountValue(getValues("average_cashback"));
    const cashbackListClone: string[] = [];
    const comissionListClone: string[] = [];
    let toalNum: number = 0;
    let totalCashbackAmount: number = 0;
    let totalComissionAmount: number = 0;
    for (var i = 0; i < 5; i++) {
      //const id = `lmemcnt_${i}`;
      let num = 0;
      switch (i) {
        case 0:
          num = getValues("lmemcnt_1");
          break;
        case 1:
          num = getValues("lmemcnt_2");
          break;

        case 2:
          num = getValues("lmemcnt_3");
          break;

        case 3:
          num = getValues("lmemcnt_4");
          break;

        case 4:
          num = getValues("lmemcnt_5");
          break;
      }
      toalNum += parseInt(`${num}`);
      const cashbackAmount = average * num * 0.1;
      totalCashbackAmount += cashbackAmount;
      cashbackListClone.push(`${cashbackAmount}`);
      const comissonAmount =
        (cashbackAmount * parseFloat(calcRatelist[i])) / 100;
      totalComissionAmount += comissonAmount;
      comissionListClone.push(`${comissonAmount}`);
    }
    setCashbackList([...cashbackListClone]);
    setComissionList([...comissionListClone]);
    setTotalMember(toalNum);
    setTotalCashback(totalCashbackAmount.toFixed(2));
    setTotalComission(totalComissionAmount.toFixed(2));
  };

  const loadMemberTeamPerformanceData = async () => {
    const data = { member_id: memberId };
    const response = await callApi(
      "member",
      "get/team_level_perf_detail",
      data
    );
    if (response.state === 1) {
      const {
        rate,
        level,
        summary: { sales_amount },
        total_member,
        total_cashback,
        total_comission,
        average_cashback,
        level_comission,
        level_cashback,
      } = response.data;
      setRateList([...rate]);
      for (var i = 0; i < 5; i++) {
        switch (i) {
          case 0:
            setValue(`lmemcnt_1`, level[i]);
            break;
          case 1:
            setValue(`lmemcnt_2`, level[i]);
            break;

          case 2:
            setValue(`lmemcnt_3`, level[i]);
            break;

          case 3:
            setValue(`lmemcnt_4`, level[i]);
            break;

          case 4:
            setValue(`lmemcnt_5`, level[i]);
            break;
        }
      }
      setTotalCashback(total_cashback);
      setTotalComission(total_comission);
      setComissionList([...level_comission]);
      setCashbackList([...level_cashback]);
      setTotalMember(total_member);
      setValue("average_cashback", average_cashback);
      setValue("sales_volumn", sales_amount);
    } else {
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalMode((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    handleOnBlurSalevolumn();
  }, []);

  useMemo(() => {
    if (calMode == "1") {
      loadMemberTeamPerformanceData();
    } else {
      calculate();
    }
  }, [calMode]);

  const amount = Helper.getAmountValue(getValues("sales_volumn"));

  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="md"
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        {...other}
      >
        <DialogContent dividers>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row d-flex align-items-center justify-content-center member-dashboard member-report">
                <FormErrors />
                <div className="col-lg-12 col-md-12 col-sm-12 custom-form">
                  <div className="sub-header mt-4">
                    <span id="modal-title">
                      Monthly Team Work Bonus Calculator
                    </span>
                    <IconButton
                      id="modal-close"
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  {memberId && (
                    <div className="row report-title">
                      <div className="col-12 col-lg-6 col-md-6">
                        Team Work Bonus
                      </div>
                      <div className="col-12 col-lg-6 col-md-6">
                        Member ID:{" "}
                        <label>{Helper.showMember12digit(memberId)}</label>
                      </div>
                    </div>
                  )}

                  <div className="row gx-1 gy-2 text-center">
                    {memberId && (
                      <div className="col-12 bg-grayer-light">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={calMode}
                          onChange={handleChange}
                        >
                          <div className="col-12 col-lg-6">
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label={
                                <Button
                                  sx={{ minWidth: "250px" }}
                                  variant="contained"
                                  onClick={() => setCalMode("1")}
                                  color="success"
                                  className="w-100"
                                >
                                  Current Data
                                </Button>
                              }
                            />
                          </div>

                          <div className="col-12 col-lg-6">
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label={
                                <Button
                                  sx={{ minWidth: "250px" }}
                                  variant="contained"
                                  onClick={() => setCalMode("0")}
                                  color="error"
                                  className="w-100"
                                >
                                  Assumption
                                </Button>
                              }
                            />
                          </div>
                        </RadioGroup>
                      </div>
                    )}
                    <div className="col-12 col-lg-6">
                      <InputAmount
                        id="average_cashback"
                        classes={"bg-grayer-light p-3"}
                        title="Avg Spending/Member/Month"
                        infor="Average amount spent for each member monthly"
                        fontSize="fs18"
                        toolTipShow={true}
                        wideLabel={true}
                        disabled={calMode == "1" ? true : false}
                        onBlur={calculate}
                      />
                    </div>
                    <div className="col-12 col-lg-6">
                      <InputAmount
                        id="sales_volumn"
                        classes={"bg-grayer-light p-3"}
                        title="My Purchase Amount "
                        infor="Purchase amount per month"
                        fontSize="fs18"
                        toolTipShow={true}
                        wideLabel={true}
                        disabled={calMode == "1" ? true : false}
                        onBlur={handleOnBlurSalevolumn}
                      />
                    </div>
                    <div
                      className="col-12 text-start mt-1 p-2"
                      style={{ background: "#D9D9CE" }}
                    >
                      Assumption: The average commission rate charged to the
                      merchants is 10%.
                    </div>
                  </div>
                  <table className="table text-nowrap mt-1">
                    <thead>
                      <tr>
                        <th className="fw-bold" style={{ width: "20%" }}>
                          Levels
                        </th>
                        <th className="fw-bold" style={{ width: "20%" }}>
                          # of Members
                        </th>
                        <th className="fw-bold" style={{ width: "20%" }}>
                          Cashback
                        </th>
                        <th className="fw-bold" style={{ width: "20%" }}>
                          My Rate{" "}
                          <HelpOutlineIcon
                            onClick={() => setOpenChart(true)}
                            sx={{ color: "red" }}
                          />
                        </th>
                        <th className="fw-bold" style={{ width: "20%" }}>
                          Commission
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rateList.map((rate, index) => (
                        <tr key={`tbl-calc-${index}`}>
                          <td className="px-4 py-1">
                            <input
                              type="text"
                              disabled
                              className="form-control input-calculator"
                              value={`${index + 1}`}
                            />
                          </td>
                          <td className="px-4 py-1">
                            <InputNumber
                              onBlur={calculate}
                              id={`lmemcnt_${index + 1}`}
                              showLabel={false}
                              disabled={calMode == "1" ? true : false}
                            />{" "}
                          </td>
                          <td className="px-4 py-1">
                            <div className="form-control input-calculator">{`$${Helper.number_format(
                              cashbackList[index],
                              2
                            )}`}</div>
                          </td>
                          <td>{parseFloat(rateList[index]).toFixed(2)}%</td>
                          <td className="px-4 py-1">
                            <div className="form-control input-calculator">{`$${Helper.number_format(
                              comissionList[index],
                              2
                            )}`}</div>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td className="total">Totals</td>
                        <td className="fw600">{totalMember}</td>
                        <td className="fw600">{`$${Helper.number_format(
                          totalCashback,
                          2
                        )}`}</td>
                        <td className="fw600"></td>
                        <td className="fw600">{`$${Helper.number_format(
                          totalComssion,
                          2
                        )}`}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div
                    className="row p-2 m-2"
                    style={{ background: "#D9D9CE" }}
                  >
                    Please note that the calculation shown here is for
                    illustration purposes only. The results displayed are based
                    on assumed information and should not be considered as
                    indicative of actual income. Individual earnings may vary
                    depending on personal efforts, market conditions, and other
                    factors.
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mx-auto">
                      <button
                        onClick={() => calculate()}
                        className="btn btn-primary w-100 fs20 text-uppercase"
                      >
                        CALCULATE
                      </button>
                    </div>
                    <div className="col-12 col-lg-6 mx-auto">
                      <button
                        className="btn button btn-secondary w-100 text-uppercase mb-1"
                        onClick={() => handleClose()}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      <DialogChart
        id="comission-chart"
        keepMounted
        open={openChart}
        onClose={handleCloseChart}
        salesVolume={amount}
      />
    </>
  );
}
