import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { auth } from "common/config/firebase";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import InputPassword from "components/inputs/InputPassword";
import InputConfirmPassword from "components/inputs/InputConfirmPassword";
import FormErrors from "components/form/FormErrors";
import { callApi } from "adapter/ApiService";

export default function PageUserResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("oobCode");
  const [email, setEmail] = useState("");
  const [erroCode, setErrorCode] = useState("");
  const [role, setRole] = useState(1);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_BRAND_NAME}-Reset-password`;
    const checkVerifyCode = async () => {
      if (code && code.length > 0) {
        try {
          const res: any = verifyPasswordResetCode(auth, code);
          res
            .then(async (email) => {
              const response = await callApi("user", "get/email_exist", {
                email,
              });
              if (response.state == 1) {
                setRole(response.data["role"]);
                setEmail(email);
              } else {
                setErrorCode("400");
              }
              // Display a "new password" form with the user's email address
            })
            .catch(function (error) {
              setErrorCode(error.code);
              // Invalid code
            });
        } catch (error) {
          setErrorCode("500");
        }
      }
    };
    checkVerifyCode();
  }, [code]);

  const methods = useForm<{ password: string; confirm_password: string }>({
    defaultValues: { password: "", confirm_password: "" },
  });

  const {
    handleSubmit,
    setError,
    trigger,
    getValues,
    formState: { errors, touchedFields, isSubmitSuccessful },
  } = methods;

  const onSubmit = async () => {
    const newPassword = getValues("password");
    if (code && code.length && newPassword) {
      try {
        const res: any = await confirmPasswordReset(auth, code, newPassword);
        res
          .then(function () {
            // Success
            setErrorCode("200");
          })
          .catch((error) => {
            setErrorCode(error.code);
          });
      } catch (error: any) {
        setErrorCode(error.code);
      }
    }
  };

  return (
    <div className="member-signup-form forgot-form">
      <div className="container">
        <h3 className="mb-5">Reset password</h3>
        <div className="text-center">
          <strong>For email: {email}</strong>
        </div>
        <div className="forms">
          <div className="row justify-content-center">
            <div className={`col-lg-6 col-md-6 col-sm-12`}>
              {[
                "auth/expired-action-code",
                "auth/invalid-action-code",
              ].includes(erroCode) && (
                <div className="error-msg">
                  Sorry, your reset password time session has expired, please{" "}
                  <div className="signin-type">
                    <p className="text-center login-account">
                      Go back to{" "}
                      <Link to="/user/forgot-password" className="text-primary">
                        Reset
                      </Link>{" "}
                      again
                    </p>
                  </div>
                </div>
              )}
              {erroCode != "200" &&
                ![
                  "auth/expired-action-code",
                  "auth/invalid-action-code",
                ].includes(erroCode) && (
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <FormErrors />
                      <InputPassword />
                      <InputConfirmPassword />
                      <div className="d-grid mb-3 mt-2">
                        <button type="submit" className="btn btn-primary">
                          RESET PASSWORD
                        </button>
                      </div>
                    </form>
                  </FormProvider>
                )}
              {erroCode == "200" && (
                <div className="member-signup-form forgot-form-success member-signup-completed">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-8 text-center">
                        <label className="round-icon">
                          <img
                            src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/redicons/check-mark-green.png`}
                          />
                        </label>
                        <h3 className="mt-3">
                          You have reset password successfully
                        </h3>
                      </div>
                    </div>
                    <div className="signin-type">
                      <p className="text-center login-account">
                        Back to{" "}
                        <Link
                          to={`/${role == 1 ? "member" : "merchant"}/login`}
                          className="text-primary"
                        >
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
