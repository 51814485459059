import { hrtime } from "process";
import { useFormContext } from "react-hook-form";
export default function InputBusinessName({ name, classes }: { name?: string | null, classes?: string | null }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  //console.log(errors);

  return (
    <div className="business-profile-name">
      <div className={`input-group ${classes}`}>
      <span className="input-group-text fs20">
        Business Name <label>*</label>
      </span>
      <input
        type="text"
        autoComplete="given-name"
        className="form-control fs20"
        {...register(name ?? "group_name", {
          required: "Please enter a valid business name.",
        })}
      />
    </div>

    </div>
    
  );
}
