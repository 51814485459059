import { useState, useEffect } from "react";
import { auth } from "../../common/config/firebase";
import { useAppSelector, useAppDispatch } from "../../common/hooks";
import {
  getUserSignupInfoBySid,
  updateStep,
  updateStatus,
} from "../../common/features/UserSignupSlice";
import { useAuthState } from "react-firebase-hooks/auth";
import SignUpClickOnEmailLink from "components/form/signup/ClickOnEmailLink";
import SignUpReEditEmail from "components/form/signup/ReEditEmail";
import ConfirmModal from "components/common/ConfirmModal";
import { Show401 } from "widgets/Errors";

export default function PageUserVerifyEmail() {
  const [user] = useAuthState(auth);
  const userSignup = useAppSelector((state) => state.UserSignup);
  const dispatch = useAppDispatch();
  const { status, step } = userSignup;
  const [error, setError] = useState(false);
  const [showCloseDlg, setShowCloseDlg] = useState(false);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_BRAND_NAME}-confirm-email`;
    dispatch(updateStatus("pending"));
    const sid = localStorage.getItem(`${process.env.REACT_APP_SIGNUP_SID}`);
    if (sid) {
      dispatch(getUserSignupInfoBySid({ sid }));
      // dispatch(updateStep(2));
    } else {
      setError(true);
    }
    setTimeout(() => setShowCloseDlg(true), 30 * 1000); //30 seconds
  }, []);

  const handleNotCloseWindow = () => {
    setShowCloseDlg(false);
  };

  const handleCloseWindow = () => {
    //setShowCloseDlg(false);
    window.opener = null;
    window.open("about:blank", "_self");
    window.close();
  };

  useEffect(() => {
    document.title = `${process.env.REACT_APP_BRAND_NAME}-Reset-password`;
  }, []);

  return (
    <>
      <div id="step-2" className="container">
        {step === 2.1 ? (
          <SignUpReEditEmail />
        ) : error ? (
          <Show401 />
        ) : status !== "account_not_found" ? (
          <SignUpClickOnEmailLink />
        ) : (
          <></>
        )}
      </div>
      <ConfirmModal
        showModal={showCloseDlg}
        title={"Important Notice"}
        message={
          "Please check your email to complete your registration process and close current window"
        }
        handleYes={handleCloseWindow}
        handleNo={handleNotCloseWindow}
        handleClose={handleCloseWindow}
      />
    </>
  );
}
