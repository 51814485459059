import {
    Fragment,
    useEffect,
    useState,
} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useUserStatus, useAdminStatus, useAppDispatch, useAppSelector } from "common/hooks";
import { logout } from "common/features/UserLoginSlice";
import { adminLogout } from "common/features/AdminSlice";
import { cashierLogout } from "common/features/CashierSlice";
import { updateMemberData } from "common/features/MemberSlice";
import { updateMerchantData } from "common/features/MerchantSlice";
import { setCurPage } from "common/features/PageSetupSlice";
import { ModalLoader } from "widgets/ShowLoader";
import { appLog } from "common/libraries/Helper";

export default function PageUserLogout() {
    const { userRole, user, loading, error } = useUserStatus();
    const userResult = useAppSelector((state) => state.UserResult);
    const adminResult = useAppSelector((state) => state.AdminResult);
    const cashierResult = useAppSelector((state) => state.CashierResult);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [rdTimer, setRdTimer] = useState(null);


    const { isAuth: userAuth } = userResult;
    const { isAuth: adminAuth } = adminResult;
    const { isAuth: cashierAuth } = cashierResult;


    /*
    setTimeout(() => {
        if (userRole == 0) {
            navigate('/user/logout/result', { state: { role: 1 } });
        }
    }, 30000);
    */

    useEffect(() => {
        document.title = `${process.env.REACT_APP_BRAND_NAME}-logout`;
        dispatch(setCurPage(""));
    }, []);

    useEffect(() => {
        appLog("Logout, current role", userRole);
        if (!loading) {
            if (user) {
                dispatch(logout());
                dispatch(cashierLogout());
                dispatch(adminLogout());
                dispatch(updateMemberData({ memberUserData: null }));
                dispatch(updateMerchantData({ merchantUserData: null }));
            } else {
                navigate('/user/logout/result', { state: { role: userRole } });
            }
        }
    }, [loading]);


    useEffect(() => {
        if (userAuth === false && adminAuth === false && cashierAuth === false) {
            navigate('/user/logout/result', { state: { role: userRole } });
        }
    }, [userAuth, adminAuth, cashierAuth])

    return (
        <div className="member-signup-completed">
            <div className="container">
                <div className="row justify-content-center" style={{ height: '200px' }}>
                    <ModalLoader />
                    <p>Hold on, We are taking care of your sign out proccess right now ...</p>
                </div>
            </div>
        </div>
    );
}
