import { useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import InputEmail from "../../inputs/InputEmail";
import FormErrors from "../FormErrors";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { updatePhone, updateStatus, updateStep } from "common/features/UserSignupSlice";
import { callApi } from "adapter/ApiService";
import InputPhone from "components/inputs/InputPhone";

export default function ReEditPhone() {
    const dispatch = useAppDispatch();
    const userSignup = useAppSelector((state) => state.UserSignup);
    const { status, sid, uid, phone } = userSignup;
    const methods = useForm({ defaultValues: { phone } });
    const {
        handleSubmit,
        setError,
        trigger,
        getValues,
        formState: { errors, touchedFields, isSubmitSuccessful },
    } = methods;

    const curPhone = getValues("phone");


    const onSubmit = async () => {
        //when form is autofilled
        trigger();
        let errorCount = 0;
        if (!touchedFields.phone) {
            const response = await callApi("user", "get/phone_exist", {
                phone: curPhone,
                sid,
            });
            if (response.state == 1 && !errors['phone']) {
                errorCount++;
                setError("phone", {
                    message: "This phone number is already used by another account.",
                });
            }
        }

        if (curPhone && curPhone.length && Object.keys(errors).length === 0 && errorCount == 0) {

            // console.log(memberObject);
            // no need for password here
            const response = await callApi("user", "update/account_phone", { phone: curPhone, uid });
            if (response.state === 1) {
                dispatch(updatePhone(curPhone));
            } else {
                setError("phone", {
                    message: "Update phone number wrong",
                });
            }
            //console.log(values);
        }
    };
    const onClickBack = (e) => {
        e.preventDefault();
        dispatch(updateStep(3));
    }
    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(onSubmit)}>
                <div className="forms">
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <FormErrors />
                        </div>
                    </div>
                    <h5>Edit Phone</h5>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                            <InputPhone verify />
                            {(curPhone != phone) &&
                                <div className="member-type mt-4">
                                    <div className="d-grid mb-4">
                                        <button className={`btn btn-primary`} type="submit">
                                            Save Change
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <p><a href="#" onClick={(e) => onClickBack(e)}>Take me back to Phone confirmation</a></p>
                </div>
            </form>
        </FormProvider>

    );
}
