import { FormProvider, useForm, useWatch } from "react-hook-form";
import { HOC, IObjectKeys } from "@/interface/PropTypeCollection";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { callApi } from "adapter/ApiService";
import { useAppDispatch, useUserStatus } from "common/hooks";
import { updateStatus, updateMessage } from "common/features/UserSignupSlice";
import ConfirmModal from "components/common/ConfirmModal";
import { ModalLoader } from "widgets/ShowLoader";

export default function SignupFormWrapper({ children }: HOC) {
  const { userRole, user } = useUserStatus();
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const setCurrentUserAccount = async (user) => {
    const { uid } = user;
    const response: IObjectKeys = await callApi(
      "user",
      "get/account_infor_by_authid",
      { auth_id: uid }
    );
    if (response) {
      const { state, data } = response;
      if (state === 1) {
        const { email, phone } = data;
        const memberObject = {
          phone,
          email,
        };
        const addResponse = await callApi(
          `${userRole == 1 ? "merchant" : "member"}`,
          "add/account",
          memberObject
        );
        if (addResponse.state !== 1) {
          dispatch(updateStatus("add_account_failed"));
          dispatch(updateMessage(addResponse.message));
          setShowLoader(false);
        } else {
          window.localStorage.setItem(
            `${process.env.REACT_APP_USER_ROLE_KEY}`,
            "3"
          );
          navigate(`/${userRole == 1 ? "merchant" : "member"}/page/dashboard`);
        }
      } else {
      }
    }
  };

  const [showModal, setShowModal] = useState(false);
  const onCloseUpModal = () => {
    setShowModal(false);
  };

  const onClickYes = () => {
    setShowModal(false);
    setShowLoader(true);
    setCurrentUserAccount(user);
  };

  const onClickNo = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (user && (userRole == 1 || userRole == 2)) {
      setShowModal(true);
    }
  }, [userRole]);

  return (
    <>
      <ConfirmModal
        showModal={showModal}
        handleNo={onClickNo}
        handleYes={onClickYes}
        handleClose={onCloseUpModal}
        title={`Welcome aboard as our new ${
          userRole == 1 ? "merchant" : "member"
        } `}
        message={`Would you like to use the same login credentials as your membership?`}
      />
      {showLoader && <ModalLoader />}
      {children}
    </>
  );
}
