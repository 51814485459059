import { useEffect, useState } from "react";
import Drawer from '@mui/material/Drawer';
import { useAppDispatch, useAppSelector } from "common/hooks";
import { Link } from "react-router-dom";
import {
  setSortby,
  fetchProducts,
  removeCategories,
  removeAllFilter,
} from "common/features/ShopSlice";
import { callApi } from "adapter/ApiService";
import CategoryWrapper from "./CategoryWrapper";
import FilterBar from "./FilterBar";

export default function ShopHeader() {
  const dispatch = useAppDispatch();
  const { pagination } = useAppSelector((state) => state.ShopReducer);
  const { total_result } = pagination;
  const [showMobile, setShowMobile] = useState(false);

  const onHandleSortby = (e) => {
    const { value } = e.currentTarget;
    dispatch(setSortby(value));
    dispatch(fetchProducts(0));
  }

  const toggleDrawer =
    () =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
        setShowMobile(!showMobile);
      };

  return (
    <>
      <div className="row">
        <div className="col col-lg-10 col-md-10 col-sm-6">
          <div className="search-bar">
            <div className="search-result">
              <h6 className="fs24">SEARCH</h6>
              <p className="fs20">{total_result} Results</p>
            </div>
          </div>
        </div>
        <div className="col col-lg-2 col-md-2 col-sm-6">
          <div className="search-filter d-flex custom-form float-end">
            <select onChange={onHandleSortby} className="form-select fs20 desktop">
              <option>Sort by</option>
              <option value="name-asc">Store Name Asc</option>
              <option value="name-desc">Store Name Desc</option>
            </select>


            <Link
              onClick={toggleDrawer()}
              onKeyDown={toggleDrawer()}
              className="btn mobile" to="#" role="button">
              Sort & Filter
            </Link>


            <Drawer
              anchor={'right'}
              open={showMobile}
              onClose={toggleDrawer()}
            >
              <div className={`filter-container mt-1`}>
                <div className="row">
                  <div className="col-10 fs20"><strong>{total_result} Results</strong></div>
                  <div className="col-2"><Link onClick={toggleDrawer()} to="" className="mb-3">X</Link></div>
                </div>
                <select onChange={onHandleSortby} className="form-select fs20">
                  <option>Sort by</option>
                  <option value="name-asc">Store Name Asc</option>
                  <option value="name-desc">Store Name Desc</option>
                </select>

                <div className="mt-3">
                  <FilterBar />
                </div>

                <div className="categories custom-form mt-3">
                  <CategoryWrapper />
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 desktop">
            <FilterBar />
          </div>
        </div>
      </div>


    </>
  );
}
