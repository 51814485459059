import React, { useState, useEffect, useMemo } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { ConfirmationDialogRawProps } from "common/libraries/interface/PropTypeCollection";
import Helper from "common/libraries/Helper";
import { callApi } from "adapter/ApiService";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ComissionChart from "./ComissionChart";

export default function DialogComissionChart(
  props: ConfirmationDialogRawProps
) {
  const { onClose, memberId, salesVolume, open, ...other } = props;

  const handleEntering = () => {};

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
      maxWidth="md"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogContent dividers>
        <ComissionChart {...props} />
      </DialogContent>
    </Dialog>
  );
}
