import {
  useState,
  useEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch, useUserStatus } from "common/hooks";
import {
  setRole,
  updateStatus
} from "common/features/UserLoginSlice";
import ShowLoader from "../../widgets/ShowLoader";
import SigninEmail from 'components/form/signin/Email';
import SigninPhone from 'components/form/signin/Phone';
import { setCurPage } from "common/features/PageSetupSlice";

export default function PageUserLogin({ role }) {
  const userInfo = useAppSelector((state) => state.UserResult);
  const dispatch = useAppDispatch();

  const [signinMethod, setSigninMethod] = useState("email");
  const navigate = useNavigate();
  const { isAuth, status, message } = userInfo;


  const onClickLoginMethod = (method) => {
    setSigninMethod(method);
    dispatch(updateStatus({ status: 'idle', message: "" }));
  }

  useEffect(() => {
    document.title = `${process.env.REACT_APP_BRAND_NAME}-Signin`;
    dispatch(setRole(role));
    dispatch(updateStatus({ status: 'idle', message: "" }));
    dispatch(setCurPage("signin"));
  }, [role])

  useEffect(() => {
    if (isAuth) {
      if (role === 1) {
        navigate(`/member/page/dashboard`);
      }
      if (role === 2) {
        navigate(`/merchant/page/dashboard`);
      }
    }
  }, [userInfo]);


  return (
    <div className="member-signup-form signin-form">
      <div className="container">
        <h3 className="fs28">SIGN IN TO YOUR <span style={{ color: "var(--text-primary)" }}>{role === 1 ? 'MEMBER' : 'MERCHANT'}</span> ACCOUNT</h3>
        <div className="sign-type">
          <div className="row justify-content-center">
            <p className="text-center">
               <Link className="text-primary" to={`/login/${role === 1 ? 'merchant' : 'member'}`}>Switch to {`${role === 1 ? 'Merchant' : 'Member'} login`} </Link>
            </p>
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="tab">
                <div onClick={() => onClickLoginMethod('email')} className={signinMethod == 'email' ? "active" : ""} >Sign in with Email</div>
                <div onClick={() => onClickLoginMethod('phone')} className={signinMethod == 'phone' ? "active" : ""}>Sign in with phone number</div>
              </div>
            </div>
          </div>
        </div>
        {status === 'pending' && <ShowLoader />}
        <div className={status === 'pending' ? 'disabled-div' : 'fs20'}>
          {signinMethod === 'email' && <SigninEmail />}
          {signinMethod === 'phone' && <SigninPhone />}
        </div>
      </div>
    </div>
  );
}
