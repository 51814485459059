import { useState, useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  SignFormWrapperProps,
  MerchantDefaultValues,
  IObjectKeys,
} from "common/libraries/interface/PropTypeCollection";
import { useNavigate } from "react-router-dom";
import { callApi } from "adapter/ApiService";
import { useAppDispatch, useAppSelector, useUserStatus } from "common/hooks";
import {
  signupWithEmail,
  updateMessage,
  updateStatus,
} from "common/features/UserSignupSlice";
import Helper from "common/libraries/Helper";
import SignupFormWrapper from "./SignupFormWrapper";

export default function MerchantFormWrapper({
  children,
}: SignFormWrapperProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const merchantSignup = useAppSelector((state) => state.UserSignup);
  const { status } = merchantSignup;
  const [defaultValues] = useState<MerchantDefaultValues>({
    business_name: "",
    category_code: "",
    country: "CA",
    phone: "",
    email: "",
    password: "",
    sponsor_id: "",
    terms: false,
    newsletter: false,
  });

  const methods = useForm<MerchantDefaultValues>({ defaultValues });

  const {
    watch,
    handleSubmit,
    setError,
    trigger,
    getValues,
    setValue,
    formState: { errors, touchedFields, isSubmitSuccessful },
  } = methods;

  const allFields = watch();
  let formSubmitValues;
  const onSubmit = async (values: MerchantDefaultValues) => {
    dispatch(updateStatus("signup_form_submit"));
    //when form is autofilled
    const divFirst = document.getElementById("form-top");
    if (divFirst) {
      divFirst.scrollIntoView({ behavior: "smooth" });
    }
    //trigger();
    formSubmitValues = getValues();
    let errorCount = 0;
    const { phone, email } = values;
    /*
        if (!touchedFields.phone) {
            const response = await callApi("user", "get/phone_exist", {
                phone,
            });
            if (response.state == 1 && !errors['phone']) {
                setError("phone", {
                    message: "Phone number is already used by another account.",
                });
                errorCount++;
            }
        }
        if (!touchedFields.email) {
            const response = await callApi("user", "get/email_exist", {
                email,
            });
            if (response.state == 1 && !errors['email']) {
                errorCount++;
                setError("email", {
                    message: "Email is already used by another account.",
                });
            }
        }
*/
    if (Object.keys(errors).length === 0 && errorCount == 0) {
      // console.log(memberObject);
      const { password } = values;
      const encrptPwd = Helper.jsEncode(
        password,
        process.env.REACT_APP_ENCRYPT_KEY
      );
      values.password = encrptPwd;
      const response = await callApi("merchant", "add/account", values);
      if (response.state === 0) {
        dispatch(updateStatus("add_account_failed"));
        dispatch(updateMessage(response.message));
      } else {
        const name = getValues("business_name");
        const { sid, uid } = response.data;
        localStorage.setItem(`${process.env.REACT_APP_SIGNUP_SID}`, sid);
        dispatch(signupWithEmail({ name, email, role: 1, sid, uid }));
      }
      //console.log(values);
    } else {
      dispatch(updateStatus("pending"));
    }
  };

  return (
    <SignupFormWrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
      </FormProvider>
    </SignupFormWrapper>
  );
}
