import React, { ReactNode } from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import Carousel from "react-multi-carousel";
import classNames from "classnames";
import "react-multi-carousel/lib/styles.css";
import "common/assets/css/carousel.css";

import {
    ButtonGroupProps,
    ArrowProps,
    DotProps,
} from "react-multi-carousel/lib/types";
import { bool } from "aws-sdk/clients/signer";

interface CarouselProp {
    children: ReactNode;
    desktopNum?: number;
    tabletNum?: number;
    mobileNum?: number;
    infinite?: boolean;
    showDot?: boolean;
}

export default function CarouselSlider({ children, desktopNum, tabletNum, mobileNum, infinite, showDot }: CarouselProp) {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: desktopNum ?? 1,
            slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: tabletNum ?? 1,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: mobileNum ?? 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };
    return (
        <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={infinite ?? false}
            autoPlay={false}
            //   autoPlaySpeed={1000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["mobile"]}
            //   deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            arrows={true}
            renderDotsOutside={true}
        >
            {children}
        </Carousel>
    );
}
