import { SubCategoriesProps } from "common/libraries/interface/PropTypeCollection";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "common/hooks";
import {
  addCategories,
  removeCategories,
  fetchProducts,
} from "common/features/ShopSlice";
export default function ShopSubCategories({
  cat_name,
  cat_slug,
  children,
  parent_code,
  cat_code,
  onParentCheck
}) {
  const [showSubCat, setShowSubCat] = useState(false);
  const dispatch = useAppDispatch();
  const { sidebar } = useAppSelector((state) => state.ShopReducer);
  const { categories } = sidebar;

  const onCheckCat = (e) => {
    if (e.target.checked) {
      dispatch(addCategories({ l1: parent_code, l2: cat_code }));
      dispatch(fetchProducts(0));
    }
    if (!e.target.checked) {
      //dispatch(removeCategories({ l1: parent_code, l2: cat_code }));
      dispatch(removeCategories(cat_code));
      dispatch(fetchProducts(0));
    }
  }

  const onCheckSubCat = (e, el) => {
    if (e.target.checked) {
      dispatch(addCategories({ l1: parent_code, l2: cat_code, l3: el.cat_code }));
      dispatch(fetchProducts(0));
    }
    if (!e.target.checked) {
      //dispatch(removeCategories({ l1: parent_code, l2: cat_code, l3: el.cat_code }));
      dispatch(removeCategories(el.cat_code));
      dispatch(fetchProducts(0));
    }
  }

  useEffect(() => {
    if (onParentCheck) {

    }

  }, [onParentCheck])

  return (
    <div className={`subsublist show`}>
      <div className="sublist">
        <div className={`sublistmain ${showSubCat ? "show" : ""}`}>
          <div className="showmore show">
            <div className="form-check">
              <input
                className="form-check-input fs20"
                type="checkbox"
                onChange={(e) => onCheckCat(e)}
                id={cat_code}
                checked={categories.length > 0 && categories.includes(cat_code)}
              />
              <label className="form-check-label lh-base" htmlFor={cat_code}>
                {cat_name}
              </label>
            </div>
            {children && children.length > 0 && <div
              className="showmoreicon"
              onClick={() => setShowSubCat((prevState) => !prevState)}
            ></div>
            }
          </div>
        </div>
        {showSubCat &&
          children?.map((el) => (
            <div key={`${el.cat_code}`} style={{ display: "block" }} className="subsublist">
              <div className="form-check">
                <input
                  className="form-check-input fs20"
                  type="checkbox"
                  onChange={(e) => onCheckSubCat(e, el)}
                  id={`checkboxsublist-${el.cat_code}`}
                  checked={categories.length > 0 && categories.includes(el.cat_code)}
                />
                <label
                  className="form-check-label lh-base"
                  htmlFor={`checkboxsublist-${el.cat_code}`}
                >
                  {el.cat_name}
                </label>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
