import { useState } from "react";
import { Link } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "common/hooks";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ShowLoader from "widgets/ShowLoader";
import { Loader } from "react-feather";
export default function SignUpFormSubmit() {
  const {
    formState: { isValid, errors },
  } = useFormContext();
  const { role, status } = useAppSelector((state) => state.UserSignup);
  const [showHelp, setShowHelp] = useState(false);

  const onHandleClickSubmit = () => {
    const divFirst = document.getElementById("form-top");
    if (divFirst) {
      divFirst.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="row justify-content-center">
      <div className="member-type mt-4">
        <div className="d-grid mb-4">
          <button
            onClick={onHandleClickSubmit}
            className={`btn btn-primary`}
            type="submit"
          >
            SUBMIT
          </button>
          {status === "signup_form_submit" && <Loader />}
        </div>
        <p className="text-center fs20">
          {role == 1 && (
            <>
              How does the{" "}
              <Link onClick={() => setShowHelp(true)} to={"#"}>
                Referral System
              </Link>{" "}
              works?
              <br />
            </>
          )}
          Already a {`${role == 1 ? "member" : "merchant"}`}?{" "}
          <Link to={`/login/${role == 1 ? "member" : "merchant"}`}>Login</Link>
        </p>
      </div>
      <Dialog
        sx={{
          "& .MuiDialog-paper": { width: "600px" },
        }}
        maxWidth="md"
        open={showHelp}
      >
        <IconButton
          id="modal-close"
          aria-label="close"
          onClick={() => setShowHelp(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="row">
            Welcome to our Referral Program! Here's how it works:
            <div className="me-4 pt-2 pb-2">
              <strong>1. Invite Your Friends:</strong> Once you become a member,
              you'll receive a unique referral link. Share this link with your
              friends, family, and anyone you know who would love our platform.
              <br />
              <strong>2. Earn Referral Rewards:</strong> When someone signs up
              using your referral link and becomes a member, you'll start
              earning referral rewards. You'll receive rewards not only for your
              direct referrals but also for anyone they refer and so on, up to
              five levels deep! <br />
              <strong>3. Unlock Deeper Rewards:</strong> As you refer more and
              more people, your referral network grows, and so do your rewards.
              The more active members in your network, the higher your potential
              earnings <br />
              <strong>4. No Limits:</strong> There's no cap on how many people
              you can refer or how much you can earn. The sky's the limit! The
              more you share, the more you earn <br />
              <strong>5. Automatic Tracking:</strong> Our system automatically
              tracks referrals and rewards, ensuring you get credited for every
              successful referral.
              <br />
              <strong>6. Real-Time Reports:</strong> You can monitor your
              referral performance in real-time through your account dashboard.
              See how your network is growing and watch your rewards add up!{" "}
              <br />
              <strong>7. Use Your Rewards:</strong> Your referral rewards can be
              used for shopping, redeeming exclusive offers, or even converting
              them into cash. It's your reward, and you decide how to use it.{" "}
              <br />
              <strong>8. Help Others Discover:</strong> By referring others to
              our platform, you're not just earning rewards; you're helping them
              discover the benefits of our platform too. It's a win-win for
              everyone!
            </div>
            Remember, our Referral Program is all about sharing the love. Spread
            the word, invite your friends, and let's build a thriving community
            together. Start referring today and watch your rewards multiply!
            Happy referring.
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
