import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "common/config/firebase";
import { signOut, signInWithEmailAndPassword } from "firebase/auth";
import { callApi } from "adapter/ApiService";
import { IObjectKeys } from "@/interface/PropTypeCollection";

interface MerchantSliceProp {
  profileData: IObjectKeys | null;
  tabIndex: number;
  status: 100 | 200 | 401 | 404 | 500; // ok| not authorised | content error | server error
  message: string;
}

const initialState: MerchantSliceProp = {
  profileData: null,
  status: 100,
  tabIndex: 0,
  message: "",
};

export const loadMerchantDetail = createAsyncThunk(
  "MerchantResult/load_merchant_detail",
  async ({ uid }: { uid: string }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response: IObjectKeys = await callApi(
        "merchant",
        "get/summary_by_authid",
        { auth_id: uid }
      );
      if (response && response.state === 1) {
        const {
          data: {
            profile_detail: { role },
          },
        } = response;
        if (role == 3 || role == 2) {
          return fulfillWithValue(response.data);
        } else {
          return rejectWithValue({
            state: 401,
            error: { message: `Sorry, you have no authority to visit here` },
          });
        }
      } else {
        return rejectWithValue({
          state: 500,
          error: { message: `Sorry, there is wrong with the data` },
        });
      }
    } catch (error: any) {
      return rejectWithValue({ state: 500, error });
    }
  }
);

export const MerchantSlice = createSlice({
  name: "MerchantResult",
  initialState,
  reducers: {
    updateMerchantData: (state, { payload: { merchantUserData } }) => {
      state.profileData = merchantUserData;
    },
    updateBusinessProfile: (state, { payload }) => {
      if (state.profileData) {
        state.profileData.profile_detail = {
          ...state.profileData.profile_detail,
          ...payload,
        };
      }
    },
    updateMerchantSlug: (state, { payload }) => {
      if (state.profileData) {
        state.profileData.profile_detail.slug = payload;
      }
    },
    updateProfileTabIndex: (state, { payload }) => {
      state.tabIndex = payload;
    },
    updateMerchantProfileComplete: (state, { payload }) => {
      if (state.profileData) {
        state.profileData.profile_detail.profile_cmp_percentage = payload;
      }
    },

    updateStatus: (state, action) => {
      const { status, message } = action.payload;
      state.status = status;
      state.message = message;
    },
    updateMerchantEmail: (state, { payload }) => {
      if (state.profileData) {
        state.profileData.profile_detail.email = payload;
      }
    },
    updateMerchantPhone: (state, { payload }) => {
      if (state.profileData) {
        state.profileData.profile_detail.phone = payload;
      }
    },
    updateLogoPath: (state, action) => {
      if (state.profileData) {
        state.profileData.profile_detail.logo_uri = action.payload;
      }
    },
    updateCoverPath: (state, action) => {
      if (state.profileData) {
        state.profileData.profile_detail.cover_uri = action.payload;
      }
    },

    updateBusinessContact: (state, action) => {
      if (state.profileData) {
        state.profileData.profile_detail = {
          ...state.profileData.profile_detail,
          ...action.payload,
        };
      }
    },
    cleanMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMerchantDetail.rejected, (state, action) => {
        const resp: any = action.payload;
        const {
          error: { message },
        } = resp;
        state.status = resp.state;
        state.message = message;
      })
      .addCase(loadMerchantDetail.fulfilled, (state, { payload }) => {
        if (payload) {
          state.profileData = payload;
          state.status = 200;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  cleanMessage,
  updateMerchantData,
  updateBusinessProfile,
  updateBusinessContact,
  updateMerchantProfileComplete,
  updateMerchantSlug,
  updateStatus,
  updateLogoPath,
  updateCoverPath,
  updateMerchantEmail,
  updateMerchantPhone,
  updateProfileTabIndex,
} = MerchantSlice.actions;

export default MerchantSlice.reducer;
