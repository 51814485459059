import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "common/config/firebase";
import {
  signOut,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { callApi } from "adapter/ApiService";

import {
  UserState,
  EmailSigninData,
  PhoneSigninData,
  IObjectKeys,
} from "@/interface/PropTypeCollection";
import Helper from "common/libraries/Helper";

const initialState: UserState = {
  role: 1,
  avatar: null,
  authInfo: null,
  status: "idle",
  isAuth: null,
  message: "",
};

export const emailSignin = createAsyncThunk(
  "UserResult/email_login",
  async (
    signinCredentials: EmailSigninData,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { email, password, role } = signinCredentials;
    try {
      const response = await callApi("user", "get/email_login_allowed", {
        email,
        role,
      });
      //compare the role first
      const terms = role == 1 ? "member" : "merchant";
      if (response && response.state == 0) {
        return rejectWithValue({
          state: 0,
          error: {
            message: `Oops, email address not found, please verify and try again!`,
          },
        });
      }
      if (response && response.state == 1) {
        const roleReturn = response.data["role"];
        if (roleReturn != 3 && roleReturn != role) {
          return rejectWithValue({
            state: 0,
            error: {
              message: `Oops, email address not found, please verify and try again!`,
            },
          });
        }
      }

      const res: any = await signInWithEmailAndPassword(auth, email, password);
      const user = res?.user;
      if (user) {
        const { uid } = user;
        const loginResponse = await callApi("user", "update/login_timestamp", {
          auth_id: uid,
        });
        window.localStorage.setItem(
          `${process.env.REACT_APP_USER_ROLE_KEY}-${uid}`,
          `${loginResponse.data.role}`
        );

        return fulfillWithValue(user);
      } else {
        console.log("rejecting");
        return rejectWithValue({ error: { message: "incorrect role value" } });
      }
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const phoneSignin = createAsyncThunk(
  "UserResult/phone_login",
  async (
    { OTP, phone, role }: PhoneSigninData,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const terms = role == 1 ? "member" : "merchant";
      const response = await callApi("user", "get/phone_login_allowed", {
        phone,
        role,
      });
      //compare the role first
      if (response && response.state == 0) {
        return rejectWithValue({
          state: 0,
          error: {
            message: `Oops, phone number not found, please verify and try again!`,
          },
        });
      }

      if (response && response.state == 1) {
        const roleReturn = response.data["role"];
        if (roleReturn != 3 && roleReturn != role) {
          return rejectWithValue({
            state: 0,
            error: {
              message: `Oops, phone number not found, please verify and try again!`,
            },
          });
        }
      }

      const confirmationResult = (window as any).confirmationResult;
      const res: any = await confirmationResult.confirm(OTP);
      const user = res?.user ?? null;
      // const token = res?.user?.accessToken ?? null;
      if (user) {
        const { uid } = user;
        const loginResponse = await callApi("user", "update/login_timestamp", {
          auth_id: uid,
        });
        window.localStorage.setItem(
          `${process.env.REACT_APP_USER_ROLE_KEY}-${uid}`,
          `${loginResponse.data.role}`
        );
        return fulfillWithValue(user);
      } else {
        return rejectWithValue({
          state: 0,
          error: {
            message: "Sorry, your authentication failed, please check again!",
          },
        });
      }
    } catch (error: any) {
      return rejectWithValue({ state: 0, error });
    }
  }
);

export const sendUpdateEmailLink = createAsyncThunk(
  "UserResult/sendUpdateEmailLink",
  async (
    { sid, email }: { sid: string; email: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const forwardUrl = `${process.env.REACT_APP_UPDATE_EMAIL}?sid=${sid}&email=${email}`;
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: forwardUrl,
        // This must be true.
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      Helper.appLog("Send Update email", "link sent");
      return fulfillWithValue({ state: 1, data: { sid, email } });
    } catch (error: any) {
      Helper.appLog("UserResult/sendUpdateEmailLink", error);
      return rejectWithValue({ state: 0, data: { sid, email } });
    }
  }
);

export const logout = createAsyncThunk(
  "UserResult/logout",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      await signOut(auth);
      return fulfillWithValue({ state: 1 });
    } catch (error: any) {
      return rejectWithValue({ state: 0, error });
    }
  }
);

export const UserSlice = createSlice({
  name: "UserResult",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
    updateUser: (state, action) => {
      state.authInfo = action.payload;
      if (state.authInfo) {
        state.isAuth = true;
      }
    },
    updateStatus: (state, action) => {
      const { status, message } = action.payload;
      state.status = status;
      state.message = message;
    },
    cleanMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(emailSignin.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(emailSignin.fulfilled, (state, action) => {
        state.status = "done";
        state.isAuth = true;
        state.authInfo = action.payload;
      })
      .addCase(emailSignin.rejected, (state, action) => {
        const resp: any = action.payload;
        const {
          error: { code, message },
        } = resp;
        state.status = "failed";
        state.isAuth = false;
        let authErroMessage = "";
        if (code) {
          switch (code) {
            case "auth/user-not-found":
              authErroMessage =
                "Wrong email address, please check and try again";
              break;

            case "auth/wrong-password":
            case "auth/invalid-password":
            case "400":
              authErroMessage = "Wrong password, please check and try again";
              break;

            default:
              authErroMessage =
                "You can't login in with the current information";
              break;
          }
        }
        state.message =
          authErroMessage && authErroMessage.length > 0
            ? authErroMessage
            : message;
        // state.currentRequestId = undefined;
      })
      .addCase(phoneSignin.fulfilled, (state, action) => {
        state.status = "done";
        state.isAuth = true;
        state.authInfo = action.payload;
      })
      .addCase(phoneSignin.rejected, (state, action) => {
        const resp: any = action.payload;
        const {
          error: { message },
        } = resp;
        state.status = "failed";
        state.isAuth = false;
        state.message = message;
        // state.currentRequestId = undefined;
      })
      .addCase(logout.pending, (state, action) => {})
      .addCase(logout.fulfilled, (state, action) => {
        state.status = "idle";
        state.isAuth = false;
        state.authInfo = null;
      })
      .addCase(sendUpdateEmailLink.rejected, (state, { payload }) => {
        const resp: any = payload;
        // state.currentRequestId = undefined;
      })
      .addCase(sendUpdateEmailLink.fulfilled, (state, { payload }) => {});
  },
});

// Action creators are generated for each case reducer function
export const { setRole, cleanMessage, updateUser, updateStatus } =
  UserSlice.actions;

export default UserSlice.reducer;
