import React, { Component } from 'react';
import PropTypes from 'prop-types';

const ShowContentLoader = (props) => {
        return(
            <div className="row-fluid">
		       <div className="loader-background loader-component row-fluid">
		            <div className="loader-background-masker loader-text-top"></div>
		            <div className="loader-background-masker loader-text-line-1"></div>
		            <div className="loader-background-masker loader-text-line-2"></div>
		            <div className="loader-background-masker loader-text-line-3-short"></div>
		            <div className="loader-background-masker loader-image-left"></div>
		            <div className="loader-background-masker loader-image-right"></div>
		            <div className="loader-background-masker loader-image-bottom"></div>
		        </div>
		       <div className="loader-background next-paragraph loader-component row-fluid">
		            <div className="loader-background-masker loader-text-top"></div>
		            <div className="loader-background-masker loader-text-line-1"></div>
		            <div className="loader-background-masker loader-text-line-2"></div>
		            <div className="loader-background-masker loader-text-line-3-short"></div>
		            <div className="loader-background-masker loader-image-left"></div>
		            <div className="loader-background-masker loader-image-right"></div>
		            <div className="loader-background-masker loader-image-bottom"></div>
		        </div>
		   </div>
        );
   
};

export default ShowContentLoader;