import React, {
    useState,
    useEffect,
} from "react";
import {
    signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "common/config/firebase";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import {
    ConfirmationDialogRawProps,
} from "common/libraries/interface/PropTypeCollection";
import { Alert } from "widgets/ShowFormMsg";
import InputPassword from "components/inputs/InputPassword";
import FormErrors from "components/form/FormErrors";

export default function DialogConfirmaPermission(props: ConfirmationDialogRawProps) {
    const { onClose, cancelDisable, email, open, ...other } = props;
    const [value, setValue] = React.useState('');
    const passwordRef = React.useRef<HTMLInputElement>(null);
    const methods = useForm();
    const {
        handleSubmit,
        setError,
        trigger,
        reset,
        getValues,
        formState: { errors, touchedFields, isSubmitSuccessful },
    } = methods;


    React.useEffect(() => {
        if (open) {
            setValue('');
            reset();
        }
    }, [open]);

    const handleEntering = () => {
        if (passwordRef.current != null) {
            passwordRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = async () => {
        try {
            const password = getValues("password");
            if (email) {
                const res: any = await signInWithEmailAndPassword(auth, email, password);
                const user = res?.user;
                if (user) {
                    onClose('permission-ok');
                } else {
                    onClose('permission-failed');
                }
            }
        } catch (e) {
            onClose('permission-failed');
        }
    };


    const onSubmit = () => {
        trigger();
        if (Object.keys(errors).length === 0) {
            handleOk();
        }
    }

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 450 } }}
            maxWidth="sm"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle className='text-center'>Verify your identity</DialogTitle>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent dividers>
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-lg-12 col-md-12 col-sm-12 custom-form">
                                <FormErrors />
                                <InputPassword required={false} classes={"mt-1"} />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className='justify-content-center'>
                        {!cancelDisable && <button className='btn button btn-secondary text-white bg-danger text-uppercase me-3' onClick={handleCancel}>
                            Cancel
                        </button>}
                        <button type="submit" className='btn button btn-primary text-white bg-success text-uppercase me-3' autoFocus >Ok</button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog >
    );
}