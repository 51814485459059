import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { Link } from "react-router-dom";
import {
  fetchProducts,
  setPage,
} from "common/features/ShopSlice";
import { useNavigate } from "react-router-dom";
import {
  ProductProp,
} from "common/libraries/interface/PropTypeCollection";
import ShowLoader from "widgets/ShowLoader";
import noLogo from 'assets/images/caseno/merchant_logo.png';
import { Alert, ShowFormError } from "widgets/ShowFormMsg";
import { classNames } from "react-select/dist/declarations/src/utils";
import Deal from "widgets/Deal";



export default function ShopProducts() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { products, pagination, loading, page } = useAppSelector((state) => state.ShopReducer);
  const { total_page } = pagination;


  useEffect(() => {
    dispatch(fetchProducts(0));
  }, []);

  const onClickRedirect = (e, slug) => {
    navigate(`/shopping/${slug}`);
  }

  const onClickShowMore = (e) => {
    e.preventDefault();
    dispatch(setPage(page + 1));
    dispatch(fetchProducts(page + 1));
  }

  return (
    <div className="col-12 col-lg-9 col-md-9 col-sm-12">
      {page == 0 && loading && <ShowLoader />}
      <div className="row g-3">
        {!(page == 0 && loading) && products.length > 0 && products.map((item: ProductProp) => {
          const { id, logo_uri, company, slug, cat_name, cash_back } = item;
          return (
            <div onClick={(e) => onClickRedirect(e, slug)} key={id} className="col-12 col-md-4 col-lg-4">
              <Deal details={item} />
            </div>)
        })}
      </div>

      <div className="no-results">
        {page < (total_page - 1) && <div className="text-end mt-3 directory-showmore">
          <Link onClick={(e) => onClickShowMore(e)} to="#">
            Show More{" "}
            <img src={`${process.env.REACT_APP_IMG_HOME_LINK}homepage/redicons/next-icon-primary.svg`} />
          </Link>
        </div>
        }
        {page > 0 && loading && <ShowLoader />}
        {products.length == 0 && <><Alert severity="warning" >Sorry, We have no results for your search right now, please change your search criteria and try again!</Alert>
          <img src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/no-results.png`} alt="no-results-image" className="mx-auto d-block img-fluid" />
        </>}
      </div>

    </div>
  );
}
