import { Fragment } from "react";
import { Outlet, Link } from "react-router-dom";

export default function PageUser() {
  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
}
