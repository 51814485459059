import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { setCurPage } from "common/features/PageSetupSlice";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonLink from "widgets/ButtonLinks";
import DialogCalculator from "components/common/DialogCalculator";
import MemberInfoNavLinks from "components/member/MemberInfoNavLinks";
import HelpReourceLink from "components/common/HelpResourceLink";

export default function PageHowItWorks({ locate }: { locate?: string }) {
  const dispatch = useAppDispatch();
  const memberResult = useAppSelector((state) => state.MemberResult);
  const [openCalc, setOpenCalc] = useState(false);
  const handleOpenCalc = () => {
    setOpenCalc(true);
  };
  const handleCloseCalc = () => {
    setOpenCalc(false);
  };

  useEffect(() => {
    const title = `${process.env.REACT_APP_BRAND_NAME}-How it works`;
    document.title = title;
    dispatch(setCurPage("how-it-works"));
    if (locate) {
      const divFirst = document.getElementById(locate);
      if (divFirst) {
        divFirst.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  //console.log("home loaded")
  return (
    <Fragment>
      <div className="bg-dark-gray member-how-it-works">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="bg-image">
                <img
                  src={`${process.env.REACT_APP_IMG_HOME_LINK}how-it-works/how-it-works-member.jpg`}
                  className="w-100"
                  alt="member-how it works-banner"
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <h3 className="fs28">How it works as a Member</h3>
              <div className="description d-flex">
                <div className="flex-shrink-0">
                  <div className="icon">1</div>
                </div>
                <div className="flex-grow-1 ms-3 text text-white">
                  <div
                    className="fs20"
                    style={{ fontFamily: "GillSansLightC" }}
                  >
                    Join for free
                  </div>
                </div>
              </div>
              <div className="description d-flex">
                <div className="flex-shrink-0">
                  <div className="icon">2</div>
                </div>
                <div className="flex-grow-1 ms-3 text text-white">
                  <div
                    className="fs20"
                    style={{ fontFamily: "GillSansLightC" }}
                  >
                    Earn Cash-back on every-purchase made with our partner
                    merchants, enjoy membership privileges, special offers &
                    discounts.
                  </div>
                </div>
              </div>
              <div className="description d-flex">
                <div className="flex-shrink-0">
                  <div className="icon">3</div>
                </div>
                <div className="flex-grow-1 ms-3 text text-white">
                  <div
                    className="fs20"
                    style={{ fontFamily: "GillSansLightC" }}
                  >
                    Refer others to join and earn Team Bonus on their purchases.
                    as wells as the purchases made by their referrals up to five
                    levels deep.
                  </div>
                </div>
              </div>
              <div className="description d-flex">
                <div className="flex-shrink-0">
                  <div className="icon">4</div>
                </div>
                <div className="flex-grow-1 ms-3 text text-white">
                  <div
                    className="fs20"
                    style={{ fontFamily: "GillSansLightC" }}
                  >
                    Become a MerchRep and set up merchants to partner with our
                    platform. Earn commission on every purchase made by all
                    memebers of the platform
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <MemberInfoNavLinks
                    navIndex={99}
                    fsClass="fs14 bg-white text-danger fw400"
                  />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 align-items-center justify-content-center">
                  <HelpReourceLink />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
            <div className="bg-white referral-program">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3 className="fs28 text-center">Referral Program</h3>
                            <p className="fs20 text-center">There is another opportunity for you to earn even more with “EZGoodie”.
                                Refer a friend and when they sign up and shop, you earn a percentage on their purchases as well.
                            </p>
                            <h6 className="text-primary fs20 fw600 text-uppercase mb-5 text-center">Earn even more cash when your referred friends shop!</h6>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <ul>
                                <li className="fs20"><img src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/green-check.png`} alt="green-check" />Shop on EZGoodie for everyday products and receive cash back as a customer</li>
                                <li className="fs20"><img src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/green-check.png`} alt="green-check" />Become a member and receive commissions for sales made by referred friends and for sales at referred Merchants</li>
                                <li className="fs20"><img src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/green-check.png`} alt="green-check" /> Referred friends shop and enjoy savings</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 border-left">
                            <ul>
                                <li className="fs20"><img src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/green-check.png`} alt="green-check" />You get cash back from their purchases</li>
                                <li className="fs20"><img src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/green-check.png`} alt="green-check" />Your referred friends see the tremendous earnings gained from customer referral</li>
                                <li className="fs20"><img src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/green-check.png`} alt="green-check" />They refer others to become customers.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    */}

      <div id="merchant" className="bg-dark-gray merchant-how-it-works mt-4">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h3 className="fs28">How it works as a Merchant</h3>
              <div className="description d-flex">
                <div className="flex-grow-1 ms-3 text mb-3">
                  <h6 className="fs20">How do we help the merchants?</h6>
                  <ul className="check-mark text-white">
                    <li className="fs20">No Set up charges</li>
                    <li className="fs20">No monthly service fee charges</li>
                    <li className="fs20">No quotas to meet</li>
                    <li className="fs20">No long term contracts</li>
                    <li className="fs20">No more pay & pray advertising</li>
                    <li className="fs20">Pay only for the business done</li>
                  </ul>
                </div>
              </div>
              <div className="description d-flex">
                <div className="flex-grow-1 ms-3 text mb-3">
                  <h6 className="fs20">Cross-business Revenue</h6>
                  <div className="pt-2">
                    <Link to="/page/merchant">
                      <button className="btn bg-white text-danger me-3 ">
                        More Details
                      </button>
                    </Link>
                    <HelpReourceLink />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="bg-image">
                <img
                  src={`${process.env.REACT_APP_IMG_HOME_LINK}how-it-works/how-it-works-merchant.jpg`}
                  className="w-100"
                  alt="how it-works-merchant-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
      <div className="bg-white get-to-know">
        <div className="container">
          <h3 className="fs28 text-center">
            Get to know the various individuals who use EZGoodie
          </h3>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div>
                <h6 className="text-primary fs20 fw600 text-uppercase mb-5">
                  Visitors
                </h6>
                <p className="fs20">
                  Visitors can make purchases with Merchants, but won’t benefit
                  from any of the Membership commissions. Visitors can register
                  as Customers or Members, or can submit a Merchant application.
                </p>
              </div>

              <div>
                <h6 className="text-primary fs20 fw600 text-uppercase mb-5">
                  Customers (Non-Members)
                </h6>
                <p className="fs20">
                  Customers can shop for products from Merchant sites and
                  receive cashback on their purchases. Customers can convert to
                  Members and can submit a Merchant application.
                </p>
              </div>

              <div>
                <h6 className="text-primary fs20 fw600 text-uppercase mb-5">
                  Members
                </h6>
                <p className="fs20">
                  Members can shop for products from Merchant sites and receive
                  cashback on their purchases. Member can also receive
                  commissions for sales made by others that they have referred
                  to the site and for sales at Merchants that the Member has
                  referred to register with the site. Members can submit a
                  Merchant application.
                </p>
              </div>

              <div>
                <h6 className="text-primary fs20 fw600 text-uppercase mb-5">
                  Merchants
                </h6>
                <p className="fs20">
                  Merchants provide a link to their website and submit cashback
                  amounts for online purchases at their website (or for physical
                  purchase at their stores) by visitors, customers and members
                  of the EZGoodie application.
                </p>
              </div>

              <div>
                <h6 className="text-primary fs20 fw600 text-uppercase mb-5">
                  Merchant – Cashiers
                </h6>
                <p className="fs20">
                  Cashiers can log in to the site and record cashback-related
                  transactions for their Merchant.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    */}
      {/*
      <div className="bg-grayer faq mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 id="faq" className="fs28 text-center">
                FAQ
              </h3>
              <div className="accordion" id="faq">
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqOne">
                        1. When do I receive my cash after I make a purchase?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          The content is coming out soon, please check next
                          time!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqTwo">
                        2. How do I receive my cash after I make a purchase?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          The content is coming out soon, please check next
                          time!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqThree">
                        3. Can I return an item after it is purchased?
                      </span>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          The content is coming out soon, please check next
                          time!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqFour">
                        4. Are there shipping costs?
                      </span>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          The content is coming out soon, please check next
                          time!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="accordion-item">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <span className="accordion-header fs20" id="faqFive">
                        5. How can I pay for my purchase?
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <p className="fs20">
                          The content is coming out soon, please check next
                          time!
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  */}
      <div className="bg-primary our-links sticky-bottom nav-how-it-works">
        <ul className="nav align-items-center justify-content-center">
          <li className="nav-item fs20">
            Calculate how much income you could make with EzGoodie!
          </li>
          <li className="nav-item text-uppercase btn btn-secondary m-2 ms-3">
            <ButtonLink onClick={handleOpenCalc} to="#">
              calculator
            </ButtonLink>
          </li>
        </ul>
      </div>

      <DialogCalculator
        id="member-calc"
        keepMounted
        open={openCalc}
        onClose={handleCloseCalc}
        memberId={memberResult?.profileData?.profile_detail?.member_id}
      />
    </Fragment>
  );
}
