import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "common/hooks";
import {
    cashierIdSignin,
    updateCashierStatus
} from "common/features/CashierSlice";
import InputId from "components/inputs/InputId";
import InputMerchantId from "components/inputs/InputMemberId";
import InputPassword from "components/inputs/InputPassword";
import { Alert, ShowFormError } from "widgets/ShowFormMsg";
import FormErrors from "components/form/FormErrors";

export default function CashierId({ ownerType }: { ownerType?: string }) {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector((state) => state.CashierResult);
    const [rememberMe, setRememberme] = useState(false);
    const { message, status } = userInfo;

    const methods = useForm();
    const {
        handleSubmit,
        setError,
        trigger,
        reset,
        getValues,
        setValue,
        formState: { errors, touchedFields, isSubmitSuccessful },
    } = methods;

    const key = `ezgoodie-cashier-password`;
    const onClickRememberMe = (e) => {
        const checked = e.currentTarget.checked;
        setRememberme(checked);
        if (checked) {
            window.localStorage.setItem(key, JSON.stringify({ val1: getValues("merchant_id"), val2: getValues("cashier_id"), val3: getValues("password") }));
        } else {
            window.localStorage.setItem(key, JSON.stringify({ val1: "", val2: "", val3: "" }));
        }
    }

    useEffect(() => {
        const accessToken = window.localStorage.getItem(key);
        if (accessToken) {
            const { val1, val2, val3 } = JSON.parse(accessToken);
            setValue('merchant_id', val1);
            setValue('cashier_id', val2);
            setValue('password', val3)
        }
    }, []);

    const onHandleFormChange = () => {
        if (status === 'failed') {
            dispatch(updateCashierStatus({ status: "idle", message: null }));
        }
    }

    const onSubmit = () => {
        trigger();
        if (Object.keys(errors).length === 0) {
            const merchantId = getValues("merchant_id");
            const cashierId = getValues("cashier_id");
            const password = getValues("password");
            if (rememberMe) {
                window.localStorage.setItem(key, JSON.stringify({ val1: merchantId, val2: cashierId, val3: password }));
            } else {
                window.localStorage.setItem(key, JSON.stringify({ val1: "", val2: "", val3: "" }));
            }
            dispatch(updateCashierStatus({ status: 'pending', message: '' }));
            dispatch(cashierIdSignin({ merchantId, cashierId, password }));
        }
    }


    return (
        <div id="with_cashier_id" className="forms">
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    onChange={() => onHandleFormChange()}
                >
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            {status == 'failed' && <Alert severity="error">{message}</Alert>}
                            <FormErrors />
                            <InputMerchantId title="Merchant Id" id="merchant_id" classes={"mt-1"} />
                            <InputId title="Cashier Id" id="cashier_id" classes={"mt-1"} />
                            <InputPassword classes={"mt-1"} />
                            <div className="d-flex justify-content-between">
                                <div className="form-check">
                                    <input onChange={(e) => onClickRememberMe(e)} className="form-check-input fs20" type="checkbox" checked={rememberMe} id="tips" />
                                    <label className="form-check-labe fs20" htmlFor="tips">Remember me</label>
                                </div>
                            </div>

                            <div className="d-grid mb-3 mt-5">
                                <button type="submit" className='btn  btn-primary' autoFocus >SIGN IN</button>
                            </div>
                        </div>

                    </div>
                </form>
            </FormProvider>
        </div>
    );
}
