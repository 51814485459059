import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

import { getRecentlyJoined } from "common/features/HomeSlice";
import { useAppSelector, useAppDispatch } from "common/hooks";
import { RecentlyJoined } from "common/libraries/interface/PropTypeCollection";
import noLogo from "common/assets/images/caseno/merchant_logo.png";
import CarouselSlider from "components/common/CarouselSlider";

export default function RecentlyJoinedBanner() {
  const { data } = useAppSelector(
    (state) => state.HomepageReducer.recentlyJoined
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data.length == 0) {
      dispatch(getRecentlyJoined());
    }
  }, [data]);

  return (
    <>
      <div className="brand-div mt-4">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <CarouselSlider
              desktopNum={4}
              tabletNum={2}
              mobileNum={1}
              infinite={true}
            >
              {data?.map((el: RecentlyJoined) => {
                const { cash_back, company, logo_uri, id, slug, cat_name } = el;
                return (
                  <Card key={`recent-${id}`}>
                    <Link to={`/shopping/${slug}`}>
                      <CardMedia
                        component="img"
                        height="150"
                        image={
                          logo_uri
                            ? `${process.env.REACT_APP_S3_URL}${logo_uri}`
                            : noLogo
                        }
                        alt={company}
                      />
                    </Link>
                    <CardHeader
                      title={company}
                      subheader={cat_name}
                      className="text-center"
                    />
                    <CardContent className="text-center p-0">
                      <p className="cashback text-primary fs20">
                        {cash_back
                          ? `${(cash_back * 100).toFixed(2)}% Cash back`
                          : ""}{" "}
                      </p>
                    </CardContent>
                  </Card>
                );
              })}
            </CarouselSlider>
          </div>
        </div>
      </div>
    </>
  );
}
