import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { callApi } from "adapter/ApiService";
import {
  CashierState,
  CashierSigninData,
} from "@/interface/PropTypeCollection";

const initialState: CashierState = {
  profileData: null,
  role: 4,
  authInfo: null,
  status: "idle",
  inforStatus: 100,
  isAuth: null,
  message: "",
  avatar: null,
};

export const cashierIdSignin = createAsyncThunk(
  "CashierResult/id_login",
  async (
    signinCredentials: CashierSigninData,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { merchantId, cashierId, password } = signinCredentials;
    try {
      const response = await callApi("cashier", "get/login", {
        merchant_id: merchantId,
        cashier_id: cashierId,
        password,
      });
      if (response && response.state === 1) {
        window.localStorage.setItem(
          `${process.env.REACT_APP_CASHIER_LOGIN_KEY}`,
          JSON.stringify(response.data)
        );
        return fulfillWithValue(response.data);
      } else {
        return rejectWithValue({
          state: 0,
          error: {
            message: `Oops, login failed. Please verify that you have entered the correct information.`,
          },
        });
      }
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const loadCashierDetail = createAsyncThunk(
  "CashierResult/load_cashier_detail",
  async (
    { profileId }: { profileId: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await callApi("cashier", "get/summary", {
        profile_id: profileId,
      });
      if (response && response.state === 1) {
        return fulfillWithValue(response.data);
      } else {
        return rejectWithValue({
          state: 500,
          error: { message: `Sorry, there is wrong with the data` },
        });
      }
    } catch (error: any) {
      return rejectWithValue({ state: 500, error });
    }
  }
);

export const CashierSlice = createSlice({
  name: "CashierResult",
  initialState,
  reducers: {
    updateCashier: (state, action) => {
      state.authInfo = action.payload;
      if (state.authInfo) {
        state.isAuth = true;
      }
    },
    updateCashierStatus: (state, action) => {
      const { status, message } = action.payload;
      state.status = status;
      state.message = message;
    },

    cleanCashierMessage: (state) => {
      state.message = "";
    },
    cashierLogout: (state) => {
      state.isAuth = false;
      state.authInfo = null;
      state.profileData = null;
      window.localStorage.removeItem(
        `${process.env.REACT_APP_CASHIER_LOGIN_KEY}`
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cashierIdSignin.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(cashierIdSignin.fulfilled, (state, action) => {
        state.status = "done";
        state.isAuth = true;
        state.authInfo = action.payload;
      })
      .addCase(cashierIdSignin.rejected, (state, action) => {
        const resp: any = action.payload;
        const {
          error: { message },
        } = resp;
        state.status = "failed";
        state.isAuth = false;
        state.message = message;
        // state.currentRequestId = undefined;
      })
      .addCase(loadCashierDetail.rejected, (state, action) => {
        const resp: any = action.payload;
        const {
          error: { message },
        } = resp;
        state.inforStatus = resp.state;
        state.message = message;
      })
      .addCase(loadCashierDetail.fulfilled, (state, { payload }) => {
        if (payload) {
          state.profileData = payload;
          state.inforStatus = 200;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  cleanCashierMessage,
  updateCashier,
  updateCashierStatus,
  cashierLogout,
} = CashierSlice.actions;

export default CashierSlice.reducer;
