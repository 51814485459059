import { createSlice } from "@reduxjs/toolkit";
import {
    SidebarProp,
} from "@/interface/PropTypeCollection";

const initialState: SidebarProp = {
    menuSimpleMode: false,
};

const SidebarSlice = createSlice({
    name: "Sidebar",
    initialState,
    reducers: {
        setSimpleMode: (state, action) => {
            state.menuSimpleMode = action.payload;
        },
    },

});

export default SidebarSlice.reducer;
export const { setSimpleMode } = SidebarSlice.actions;
