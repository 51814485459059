import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ButtonLink from "widgets/ButtonLinks";

export default function SignUpBanner({ currentStep }) {
  const [showIndex, setShowIndex] = useState(1);

  return (
    <div className="bg-white member-signup">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 left-space">
            <h3 className="signup-header fs28">
              Get ready to save
              <br />
              and make money!
            </h3>
            <ul data-toggle="buttons">
              <li
                onClick={() => setShowIndex(1)}
                className={`signup-image-toggler fs20 ${
                  showIndex === 1 ? "active" : ""
                }`}
              >
                <LazyLoadImage
                  alt="image1"
                  src={
                    process.env.REACT_APP_IMG_HOME_LINK +
                    "homepage/redicons/pointbullet.png"
                  }
                />
                {""}
                Earn cash back
              </li>
              <li
                onClick={() => setShowIndex(2)}
                className={`signup-image-toggler fs20 ${
                  showIndex === 2 ? "active" : ""
                }`}
              >
                <LazyLoadImage
                  alt="image2"
                  src={
                    process.env.REACT_APP_IMG_HOME_LINK +
                    "homepage/redicons/pointbullet.png"
                  }
                />{" "}
                Free membership and startup
              </li>
              <li
                onClick={() => setShowIndex(3)}
                className={`signup-image-toggler fs20 ${
                  showIndex === 3 ? "active" : ""
                }`}
              >
                <LazyLoadImage
                  alt="image3"
                  src={
                    process.env.REACT_APP_IMG_HOME_LINK +
                    "homepage/redicons/pointbullet.png"
                  }
                />{" "}
                Discounts on essential products and services
              </li>
              <li
                onClick={() => setShowIndex(4)}
                className={`signup-image-toggler fs20 ${
                  showIndex === 4 ? "active" : ""
                }`}
              >
                <LazyLoadImage
                  alt="image4"
                  src={
                    process.env.REACT_APP_IMG_HOME_LINK +
                    "homepage/redicons/pointbullet.png"
                  }
                />{" "}
                Earn income on referral
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-sm-12">
            <LazyLoadImage
              style={
                showIndex === 1 ? { display: "block" } : { display: "none" }
              }
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "signuppage/images/young-african-american-girl-teen-student-denim-clothes-backpack-hold-pc-fan-cash-money-isolated-blue-wall.jpg"
              }
              title="Earn Cash Back"
              alt="Earn Cash Back"
              className="w-100 image-toggle"
            />

            <LazyLoadImage
              style={
                showIndex === 2 ? { display: "block" } : { display: "none" }
              }
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "signuppage/images/free-membership.jpg"
              }
              title="Free Membership And Startup"
              alt="Free Membership And Startup"
              className="w-100 image-toggle"
            />

            <LazyLoadImage
              style={
                showIndex === 3 ? { display: "block" } : { display: "none" }
              }
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "signuppage/images/discount.jpg"
              }
              title="Discounts On Essential Products And Services"
              alt="Discounts On Essential Products And Services"
              className="w-100 image-toggle"
            />

            <LazyLoadImage
              style={
                showIndex === 4 ? { display: "block" } : { display: "none" }
              }
              src={
                process.env.REACT_APP_IMG_HOME_LINK +
                "signuppage/images/referral.jpg"
              }
              title="Earn Income On Referral"
              alt="Earn Income On Referral"
              className="w-100 image-toggle"
            />
          </div>
        </div>
        {currentStep == "1" && (
          <ButtonLink
            className="btn text-white bg-success mt-3"
            to="/"
            target="_blank"
          >
            Want to learn more anout us?
          </ButtonLink>
        )}
      </div>
    </div>
  );
}
