import { SignUpType } from "common/libraries/interface/PropTypeCollection";
import {
  IndividualDefaultValues,
  GroupDefaultValues,
} from "common/libraries/interface/PropTypeCollection";
export default function BuildFormValues(
  type: SignUpType
): IndividualDefaultValues | GroupDefaultValues {
  if (type === "individual")
    return {
      member_type: 0,
      title: "mr",
      full_name: "",
      country: "CA",
      phone: "",
      email: "",
      password: "",
      sponsor_id: "",
      terms: false,
      newsletter: false,
    };
  if (type === "group")
    return {
      member_type: 1,
      group_name: "",
      country: "CA",
      phone: "",
      email: "",
      password: "",
      sponsor_id: "",
      terms: false,
      newsletter: false,
    };

  return {
    member_type: 1,
    group_name: "",
    country: "CA",
    phone: "",
    email: "",
    password: "",
    sponsor_id: "",
    terms: false,
    newsletter: false,
  };
}
