import {
    useState,
    useEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "common/hooks";
import {
    updateAdminStatus,
    updateAdmin
} from "common/features/AdminSlice";
import ShowLoader from "../../widgets/ShowLoader";
import SigninEmail from 'components/form/signin/Email';
import SigninPhone from 'components/form/signin/Phone';
import { setCurPage } from "common/features/PageSetupSlice";

export default function PageAdminLogin() {
    const adminInfo = useAppSelector((state) => state.AdminResult);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const { isAuth, status, message } = adminInfo;

    useEffect(() => {
        const adminLoginStorage = window.localStorage.getItem(`${process.env.REACT_APP_ADMIN_LOGIN_KEY}`);
        if (adminLoginStorage && adminLoginStorage.length > 0) {
            const authInfo = JSON.parse(adminLoginStorage);
            dispatch(updateAdmin(authInfo));
        } else {
            dispatch(updateAdminStatus({ status: 'idle', message: "" }));
            dispatch(setCurPage("signin"));
        }

    }, [])

    useEffect(() => {
        document.title = `${process.env.REACT_APP_BRAND_NAME}-Admin-Signin`;
        if (isAuth) {
            navigate(`/admin/page/dashboard`);
        }
    }, [adminInfo]);

    return (
        <div className="member-signup-form signin-form">
            <div className="container">
                <h3 className="">Admin Log in</h3>
                <div className={status === 'pending' ? 'disabled-div' : ''}>
                    <SigninEmail ownerType="admin" />
                </div>
            </div>
        </div>
    );
}
