import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderMenu from "components/navigation/DashboardHeader";
import { MenuProp } from "common/libraries/interface/PropTypeCollection";
import { useUserStatus, useAppSelector, useAppDispatch } from "common/hooks";

export default function MemberNav() {

    return (
        <>
            {/*
            <HeaderMenu />
    */}
            {/*
            <div className="subnavbar desktop">
                <div className="container">
                    <ul className="navbar-nav member-dashboard-navbarmenu">
                        <li className="nav-item">
                            <Link onClick={() => onHandleGoToTab('home')} className={`${memberNav === 'home' ? 'nav-link active' : 'nav-link'}`} to="#">Dashboard</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => onHandleGoToTab('account')} className={`${memberNav === 'account' ? 'nav-link active' : 'nav-link'}`} to="#">Account</Link>
                        </li>
                        <li className="nav-item d-none d-lg-block">
                            <Link onClick={() => onHandleGoToTab('refer-friend')} className={`${memberNav === 'refer-friend' ? 'nav-link active' : 'nav-link'}`} to="#">Refer to a friend</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/shopping">Shop Now</Link>
                        </li>
                    </ul>
                </div>
            </div>
    */}
        </>
    );
}
