import React, {
    useState,
    useEffect,
} from "react";
import { useSearchParams, Link } from "react-router-dom";
import { auth } from "common/config/firebase";
import { useAppDispatch } from "common/hooks";
import { isSignInWithEmailLink, signInWithEmailAndPassword, updateEmail } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { updateMemberEmail } from "common/features/MemberSlice";
import { updateMerchantEmail } from "common/features/MerchantSlice";
import { callApi } from "adapter/ApiService";
import { Show403 } from "widgets/Errors";
import { ModalLoader } from "widgets/ShowLoader";
import { Alert } from "widgets/ShowFormMsg";
import { appLog } from "common/libraries/Helper";
import ConfirmationDialogPermission from 'components/user/DialogConfirmPermission';
import BorderBox from 'widgets/BorderBox';

export default function ConfirmUpdateEmail() {
    const [user, loading] = useAuthState(auth);
    const [loader, setLoader] = useState(true);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const sid = searchParams.get('sid');
    const emailInLink = searchParams.get('email');
    const tmpRole = searchParams.get('role');
    const role = tmpRole ? parseInt(tmpRole) : 0;
    const [status, setStatus] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [userId, setUserId] = useState<number | null>(null);
    const [openPassword, setOpenPassword] = useState(false);


    const checkWillbeEmail = async () => {
        const response = await callApi("user", "get/account_infor_by_sid", { sid });
        if (response && response.state == 1) {
            if (emailInLink) {
                const { uid, email, will_be_email } = response.data;
                setLoader(false);
                if (user && user.email !== email) {
                    setStatus("email-not-valid");
                    return;
                }
                if (email === emailInLink) {
                    setStatus('update-ok');
                } else {
                    if (emailInLink !== will_be_email) {
                        setStatus("email-not-valid");
                    } else {
                        setUserId(uid)
                        setEmail(email);
                        setOpenPassword(true);
                    }
                }
            }
        } else {
            setStatus("email-not-valid");
        }
    }

    useEffect(() => {
        document.title = `${process.env.REACT_APP_BRAND_NAME}-Update-Email`;
    }, []);


    useEffect(() => {
        if (sid && emailInLink) {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                checkWillbeEmail();
            } else {
                setStatus("email-link-failed");
                appLog("Update email ", 'failed')
            }
        }
    }, [sid, emailInLink]);


    const handleClosePassword = async (permissonStatus?: string) => {
        setOpenPassword(false);
        if (typeof permissonStatus !== 'undefined') {
            try {
                if (email && permissonStatus === 'permission-ok' && user && emailInLink && userId) {
                    const updateRet = await updateEmail(user, emailInLink);
                    const apiRes = await callApi("user", "update/will_be_email_ok", { uid: userId, email: emailInLink });
                    if (apiRes && apiRes.state === 1) {
                        setStatus("update-ok");
                        if (role === 1) {
                            dispatch(updateMemberEmail(emailInLink));
                        } else if (role === 2) {
                            dispatch(updateMerchantEmail(emailInLink));
                        }
                    }
                } else {
                    setStatus("permission-failed");
                }

            } catch (e) {
                setStatus("permission-failed");
            }
        }
    };

    return (
        <div className="member-signup-form forgot-form">
            <div className="container">

                {loader && <ModalLoader />}
                <ConfirmationDialogPermission
                    id="password"
                    keepMounted
                    open={openPassword}
                    onClose={handleClosePassword}
                    cancelDisable={true}
                    email={email}
                />

                {status && status !== "update-ok" &&
                    <>
                        <div className="row business-profile-tab d-flex align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <img className="img-fluid" alt="not-found" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/email-changed-fail.png`} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <h3 className="fs28">UPDATE EMAIL</h3>
                                <Alert severity="error" className="mb-4">Update account email failed!</Alert>
                                <p className='fs20'>Sorry, update account email failed, please try again later!</p>
                                <div className="buttons d-flex">
                                    <Link to="/" className="btn btn-secondary align-items-center w-100">BACK TO &nbsp; <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span></Link>&nbsp;&nbsp;
                                    <Link to={`/${role === 1 ? 'member' : 'merchant'}/dashboard`} className="btn btn-primary w-100" >Dashboard</Link>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {status === "update-ok" &&
                    <>
                        <div className="row business-profile-tab d-flex align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <img className="img-fluid" alt="not-found" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/email-changed-success.png`} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <h3 className="fs28">UPDATE EMAIL</h3>
                                <Alert severity="success" className="mb-4">Email changed successful!</Alert>
                                <p className='fs20'>Congrats, You have successfully updated your account's email to {emailInLink}!</p>
                                <div className="buttons d-flex">
                                    <Link to="/" className="btn btn-secondary align-items-center w-100">BACK TO &nbsp; <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span></Link>&nbsp;&nbsp;
                                    <Link to={`/${role === 1 ? 'member' : 'merchant'}/dashboard`} className="btn btn-primary w-100" >Dashboard</Link>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}
