import React, { useState } from "react";
import { useAppSelector } from "common/hooks";
import ButtonLink from "widgets/ButtonLinks";
import DialogCalculator from "components/common/DialogCalculator";
import { useUserStatus } from "common/hooks";

export default function WhyMerchant() {
  const memberResult = useAppSelector((state) => state.MemberResult);
  const { user } = useUserStatus();
  const [openCalc, setOpenCalc] = useState(false);
  const handleOpenCalc = () => {
    setOpenCalc(true);
  };
  const handleCloseCalc = () => {
    setOpenCalc(false);
  };

  return (
    <div className="container pt-4">
      <div className="row">
        <img
          style={{ width: "100%" }}
          src={
            process.env.REACT_APP_IMG_HOME_LINK +
            "homepage/images/boosting-business.jpg"
          }
          className="mt10"
        />
        <div style={{ marginTop: "-60px" }} className="desktop text-end pe-5">
          <div className="col-sm-12">
            {!user && (
              <ButtonLink
                to={"/signup/merchant"}
                className="btn text-green bg-white btn-primary mt-3"
              >
                JOIN FOR FREE
              </ButtonLink>
            )}
            <ButtonLink
              className="btn bg-white text-primary btn-secondary mt-3 ms-3"
              to="/page/merchant"
            >
              Explore More
            </ButtonLink>
          </div>
        </div>
        <div className="mobile">
          <div className="col-sm-12">
            {!user && (
              <ButtonLink
                to={"/signup/merchant"}
                className="btn text-green bg-white btn-primary mt-3"
              >
                JOIN FOR FREE
              </ButtonLink>
            )}
            <ButtonLink
              className="btn bg-white text-primary btn-secondary mt-3 ms-3"
              to="/page/merchant"
            >
              Explore More
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );

  /*
  return (
    <>
      <div className="bg-white why-us">
        <div className="container pt-2" style={{ backgroundColor: "#f9fafe" }}>
          <h3 className="">
            Boosting <span className="text-primary">Business Growth</span> Made{" "}
            <span className="text-primary">Simple</span>
          </h3>
          <div className="row gy-4">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <img
                style={{ width: "100%", height: "310px" }}
                src={
                  process.env.REACT_APP_IMG_HOME_LINK +
                  "homepage/Images/merchant-why.png"
                }
                className="mt10"
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 text-green">
              <ul>
                <li>
                  <img
                    src={
                      process.env.REACT_APP_IMG_HOME_LINK +
                      "icons/green-check.png"
                    }
                  />{" "}
                  No set up charges
                </li>
                <li>
                  <img
                    src={
                      process.env.REACT_APP_IMG_HOME_LINK +
                      "icons/green-check.png"
                    }
                  />{" "}
                  No monthly charges
                </li>
                <li>
                  <img
                    src={
                      process.env.REACT_APP_IMG_HOME_LINK +
                      "icons/green-check.png"
                    }
                  />{" "}
                  No quotas to meet
                </li>
                <li>
                  <img
                    src={
                      process.env.REACT_APP_IMG_HOME_LINK +
                      "icons/green-check.png"
                    }
                  />{" "}
                  No long terms contract
                </li>
                <li>
                  <img
                    src={
                      process.env.REACT_APP_IMG_HOME_LINK +
                      "icons/green-check.png"
                    }
                  />{" "}
                  No More pay & pray advertising
                </li>
                <li>
                  <img
                    src={
                      process.env.REACT_APP_IMG_HOME_LINK +
                      "icons/green-check.png"
                    }
                  />{" "}
                  Pay only for the bussiness done
                </li>
              </ul>
            </div>
          </div>
          <div
            className="row"
            style={{ backgroundColor: "#02a650", padding: "5px" }}
          >
            <div className="col-lg-7 col-md-7 col-sm-12 row d-flex align-items-center justify-content-center">
              <h4 className="text-white">
                Seize the chance for growth and success!
              </h4>
            </div>
            <div className="buttons col-lg-5 col-md-5 col-sm-12">
              <ButtonLink
                to={"/signup/merchant"}
                className="btn text-green bg-white btn-primary mt-3"
              >
                JOIN FOR FREE
              </ButtonLink>
              <ButtonLink
                className="btn bg-white text-primary btn-secondary mt-3 ms-3"
                to="/page/merchant"
              >
                Explore More
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
      <DialogCalculator
        id="member-calc"
        keepMounted
        open={openCalc}
        onClose={handleCloseCalc}
        memberId={memberResult?.profileData?.profile_detail?.member_id}
      />
    </>
  );
  */
}
