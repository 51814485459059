import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

export function Show401({ title, message }:
    { title?: string, message?: string | undefined | null }) {
    const navigate = useNavigate();
    return (
        <div className="not-authorized">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid" alt="not authorized" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/401-unauthorized-yellow.png`} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                        <h1 className="fs28">{`${title ?? '401'}`}</h1>
                        <h3 className="fs28">UNAUTHORIZED</h3>
                        <p className="fs20">{`${message ?? 'You are not authorized to access this page'}`}</p>
                        <div className="buttons">
                            <button onClick={() => navigate("/")} className="btn btn-primary mt-4">BACK TO &nbsp; <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export function Show403({ title, message }:
    { title?: string, message?: string | undefined | null }) {
    const navigate = useNavigate();
    return (
        <div className="page-forbidden">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid" alt="forbidden" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/403-error-forbidden-yellow.png`} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                        <h1 className="fs28">{`${title ?? '403'}`}</h1>
                        <h3 className="fs28">FORBIDDEN</h3>
                        <p className="fs20">{`${message ?? 'Access to this resource on the server is denied!'}`}</p>
                        <div className="buttons">
                            <button onClick={() => navigate("/")} className="btn btn-primary mt-4">BACK TO &nbsp; <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export function Show404({ title, message }:
    { title?: string, message?: string | undefined | null }) {
    const navigate = useNavigate();
    return (
        <div className="page-not-found">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid" alt="not-found" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/404-not-found-yellow.png`} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                        <h1 className='fs28'>404</h1>
                        <h3 className="fs28">PAGE NOT FOUND</h3>
                        <p className='fs20'>{`${message ?? 'We are sorry, the page you requested could not be found'}`}</p>
                        <div className="buttons">
                            <button onClick={() => navigate("/")} className="btn btn-primary mt-4">BACK TO &nbsp; <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export function Show500({ title, message }:
    { title?: string, message?: string | undefined | null }) {
    const navigate = useNavigate();
    return (
        <div className="server-error">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid" alt="server-error" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/500-server-error-yellow.png`} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                        <h1 className="fs28">500</h1>
                        <h3 className="fs28">SERVER ERROR</h3>
                        <p className="fs20">The server encounted an internal error and was unable to complete your request</p>
                        <div className="buttons">
                            <button onClick={() => navigate("/")} className="btn btn-primary mt-4">BACK TO &nbsp; <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}



export function Show501({ title, message }:
    { title?: string, message?: string | undefined | null }) {
    const navigate = useNavigate();
    return (
        <div className="page-not-implemented">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid" alt="not implemented" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/501-not-implemented-yellow.png`} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                        <h1 className="fs28">501</h1>
                        <h3 className="fs28">NOT IMPLEMENTED</h3>
                        <p className="fs20">We are sorry, the page you requested is still on contruction, please check back later</p>
                        <div className="buttons">
                            <button onClick={() => navigate("/")} className="btn btn-primary mt-4">BACK TO &nbsp; <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export function Show502({ title, message }:
    { title?: string, message?: string | undefined | null }) {
    const navigate = useNavigate();
    return (
        <div className="bad-getaway">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid" alt="bad-getaway" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/502-bad-getaway-yellow.png`} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                        <h1 className="fs28">502</h1>
                        <h3 className="fs28">BAD GETAWAY</h3>
                        <p className="fs20">The server encounted a temporary error and could not complete your request</p>
                        <div className="buttons">
                            <button onClick={() => navigate("/")} className="btn btn-primary mt-4">BACK TO &nbsp; <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export function Show503({ title, message }:
    { title?: string, message?: string | undefined | null }) {
    const navigate = useNavigate();
    return (
        <div className="service-unavailable">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid" alt="service-unavailable" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/503-service-unavailable-yellow.png`} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                        <h1 className="fs28">503</h1>
                        <h3 className="fs28">SERVICE UNAVAILABLE</h3>
                        <p className="fs20">The server is temporarily busy. Try again later!</p>
                        <div className="buttons">
                            <button onClick={() => navigate("/")} className="btn btn-primary mt-4">BACK TO &nbsp; <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export function Show504({ title, message }:
    { title?: string, message?: string | undefined | null }) {
    const navigate = useNavigate();
    return (
        <div className="getaway-timeout">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="img-fluid" alt="getaway-timeout" src={`${process.env.REACT_APP_IMG_HOME_LINK}common-images/504-getaway-timeout-yellow.png`} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                        <h1 className="fs28">504</h1>
                        <h3 className="fs28">GETAWAY TIMEOUT</h3>
                        <p className="fs20">A getaway timeout occured. The server is unreachable. Retry the request</p>
                        <div className="buttons">
                            <button onClick={() => navigate("/")} className="btn btn-primary mt-4">BACK TO &nbsp; <span><img className="img-fluid" src={`${process.env.REACT_APP_IMG_HOME_LINK}icons/home-icon.png`} alt="home-icon" /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}


