import CarouselSlider from "components/common/CarouselSlider";
export default function ShopSlider() {
  return (
    <section>
      <div className="container">
        <div className="row g-2">
          <CarouselSlider showDot={false} desktopNum={1} tabletNum={1} mobileNum={1} infinite={true}>
            <div className="">
              <img
                alt="adv"
                src={
                  process.env.REACT_APP_IMG_HOME_LINK + "shopping/shopping-banner.jpg"
                }
                className="img-fluid"
              />
            </div>
            <div className="">
              <img
                alt="adv"
                src={
                  process.env.REACT_APP_IMG_HOME_LINK + "shopping/shopping-banner.jpg"
                }
                className="img-fluid"
              />
            </div>
            <div className="">
              <img
                alt="adv"
                src={
                  process.env.REACT_APP_IMG_HOME_LINK + "shopping/shopping-banner.jpg"
                }
                className="img-fluid"
              />
            </div>
          </CarouselSlider>
        </div>
      </div>
    </section>
  );
}
