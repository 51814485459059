import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { callApi } from "adapter/ApiService";
import { InputProps } from "@/interface/PropTypeCollection";
import { Settings } from "react-feather";
import { Button } from "@mui/material";

export default function InputBusinessCategory({
  classes,
  title = null,
  value = "",
  enableSetup = false,
  categoryItems,
}: InputProps) {
  const [category, setCategory] = useState([]);

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [editActive, setEditActive] = useState(enableSetup ? false : true);
  const handleEditActive = () => {
    setEditActive(!editActive);
  };

  useEffect(() => {
    const loadCategory = async () => {
      const response = await callApi("category", "get/main", {});
      if (response.state === 1) {
        setCategory(response.data);
        if (value) {
          setTimeout(() => {
            setValue("category_code", value);
          }, 300);
        }
      }
    };
    if (!categoryItems) {
      loadCategory();
    }
  }, []);

  useEffect(() => {
    if (value) {
      setValue("category_code", value);
    }
  }, [value]);

  const categoryDisplay = categoryItems ?? category;

  // const { businessName, businessWebsite, businessCategory } = useAppSelector((state) => state.UserSignup);
  return (
    <div className="business-profile-category">
      <div className={`input-group ${classes}`}>
        <span className="input-group-text fs20">
          {title ?? "Business Category"} <label>*</label>{" "}
          {enableSetup && (
            <Button onClick={handleEditActive}>
              <Settings
                className="text-end"
                color={editActive ? "blue" : "gray"}
              />
            </Button>
          )}
        </span>
        <select
          disabled={!editActive}
          {...register("category_code", {
            required: "Please select business category",
          })}
          className="form-select fs20"
        >
          <option value="">--</option>
          {categoryDisplay &&
            categoryDisplay.length > 0 &&
            categoryDisplay.map((item) => {
              return (
                <option key={`k-${item["cat_code"]}`} value={item["cat_code"]}>
                  {item["cat_name"]}
                </option>
              );
            })}
        </select>
      </div>
    </div>
  );
}
